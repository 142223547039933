export const Global_var = {
  BASEURL: "https://services.finxpert.org/thor-gateway-finx",// PROD
 // BASEURL: "https://uatservices.finxpert.org/thor-gateway-finx",// UAT
  // BASEURL: "http://localhost:8000/thor-gateway-finx", // localhost
  // BASEURL: "http://192.168.71.183:8000/thor-gateway-finx",// ip
  // BASEURL: "https://finxmicro.njtechdesk.com/thor-gateway-finx", // dev
  // TEST_BASEURL: "https://finxmicro.njtechdesk.com/thor-gateway-finx",// dev

  SECURITY_CHANGE: "true",  //  true value for encrypt and false value for not encrypt
  EXCEL_LIMIT: 20,

  //Download Documents
  URL_STUDENT_DOCUMENT: "/finx-thor-lookupmanagement/api/v1/Document/getStudentDocuments",
  URL_DOWNLOAD_DOCUMENT: "/finx-thor-lookupmanagement/api/v1/Document/download",

  // Deployment
  URL_DEPLOYMENT: "/finx-fc-admin/api/v1/deploymentFlag",


  // for social media btns url
  SOCIAL_MEDIA_SHARE_URL: `${window.location.origin}/?`,
  SOCIAL_MEDIA_SHARE_TEXT: "Finx | BFSI focused courses and jobs. Join for Free",
  // SOCIAL_MEDIA_SHARE_URL: "http://localhost:3000/?",   // for localhost 3000
  // SOCIAL_MEDIA_SHARE_URL: "https://finx.njtechdesk.com/?",   // for finx test
  // SOCIAL_MEDIA_SHARE_URL: "https://core.finxpert.org/?",   // for prod test
  URL_JWT_TRUST: "/thor-token-management/api/v1/jwt-token",
  URL_LOGIN: "/finx-thor-authentication/api/v1/login",
  URL_LOGIN_MOBILE: "/finx-thor-authentication/api/v1/loginWithMobile",
  URL_GETSTATE_WITH_JOBCOUNT: "/finx-thor-partyservices/api/v1/party/preference/state",

  URL_Session_DETAILS: "/finx-thor-authentication/api/v1/party/getbyEmail", //?email=sk9@gmail.com
  URL_LOGOUT: "/finx-thor-authentication/api/v1/logout",
  URL_GET_USER_PROGRESS: "/finx-fc-student/api/v1/studentcfxreport/getstudentprogress",
  URL_VALIDATE_DOMAIN_DURING_REGISTER: "/finx-fc-admin/api/v1/admin/email",
  URL_FORGOTPASSWORD: "/finx-thor-authentication/api/v1/user/forgotPassword",
  URL_CHANGEPASSWORD: "/finx-thor-authentication/api/v1/user/changePassword",
  URL_CHANGE_G_PASSWORD:"/finx-thor-authentication/api/v1/user/changePassword",
  URL_GET_USER_REGISTER_TYPE:"/finx-thor-authentication/api/v1/user/checkCurrentPwdFlag",
  URL_CHANGE_ADMIN_PASSWORD:
    "/finx-thor-authentication/api/v1/user/changeAdminPassword",
  URL_UPDATEFORGOTPASSWORD:
    "/finx-thor-authentication/api/v1/user/updateForgotPassword",

  // ProductList
  URL_PRODUCT_ALL: "/finx-fc-product/api/v1/product/allproduct",
  URL_PRODUCT_BYNAME: "/finx-fc-product/api/v1/product/productName", //?productName=CFX
  URL_PRODUCT_BYID: "/finx-fc-product/api/v1/product", //?productName=CFX
  URL_PRODUCT_BY_PARTYID:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/getStudAssessmentWithProduct", //?studentPartyId = 10
  URL_ADD_PRODUCT: "/finx-fc-product/api/v1/product",
  URL_GETPARTNER: "/finx-fc-product/api/v1/patner",
  URL_UPDATEPRODUCT: "/finx-fc-product/api/v1/product",
  URL_UPDATEISACTIVEINACTIVE: "/finx-fc-product/api/v1/product",
  URL_CHECK_PRODUCT_SEQUENCE_DUPLICATE:
    "/finx-fc-product/api/v1/product/checkProductSequence",
  URL_ALL_COURSE: "/finx-fc-product/api/v1/product/courses",

  URL_ADD_PRODUCT_THUMBNAIL_VIDEO: "/thor-documentmanagement/api/v1/document",

  URL_PRODUCT_DETAIL_BY_ID: "/finx-fc-product/api/v1/product/productdetails",
  URL_PRODUCT_VALIDITY_LIST: "/finx-fc-product/api/v1/product/validity/list",
  URL_PRODUCT_VALIDITY_DETAILS:
    "/finx-fc-product/api/v1/product/validity/product",
  URL_PRODUCT_VALIDITY: "/finx-fc-product/api/v1/product/validity",
  URL_PRODUCT_IAM_LOOKING_FOR: "/finx-fc-product/api/v1/product/lookingfor",
  URL_PRODUCT_LEVEL: "/finx-fc-product/api/v1/product/productlevellist",
  URL_GET_SHORT_COURSES: "/finx-fc-product/api/v1/product/subjectArea",
  URL_UPDATE_SHORT_COURSES: "/finx-fc-product/api/v1/product/updateLookingforMap",

  // Modules
  URL_ADD_MODULES: "/finx-fc-product/api/v1/module",
  URL_GETMODULES: "/finx-fc-product/api/v1/module/allmodule",
  URL_GETMODULESBYID: "/finx-fc-product/api/v1/module",
  URL_UPDATEMODULES: "/finx-fc-product/api/v1/module",
  URL_UPDATEISACTIVEINACTIVEMODULE: "/finx-fc-product/api/v1/module",

  // LearningPath
  URL_GETLEARNINGPATHBYID:
    "/finx-fc-product/api/v1/prerequisite/getProductPreRequisite",
  //URL_GETLEARNINGPATH: "/finx-fc-product/api/v1/prerequisite/allpath",
  URL_GETLEARNINGPATH: "/finx-fc-product/api/v1/prerequisite/count",
  URL_GETLEARNINGAllPRODUCT: "/finx-fc-product/api/v1/prerequisite",
  URL_ADD_LEARNINGPATH: "/finx-fc-product/api/v1/prerequisite",
  URL_UPDATELEARNINGPATH: "/finx-fc-product/api/v1/prerequisite",
  URL_PARENTPRODUCTID:
    "/finx-fc-product/api/v1/prerequisite/getProductPreRequisite",
  URL_PARENTPRODUCTIDASSIGNUSER: "/finx-fc-student/api/v1/studentassessment",

  //OTP Service
  URL_SENDOTP: "/finx-thor-otp/api/v1/otp",
  //'/api/v1/otp',//?mobileNo=9307933259&countryCode=91
  URL_OTP_VALIDATE: "/finx-thor-otp/api/v1/otp/validate",
  //?mobileNo=9307933259&countryCode=91&otp=508769
  URL_SAVE_MOBILE_POPUP: "/finx-fc-student/api/v1/student/saveSocialUserMobile",
  URL_MOBILE_POPUP_FLAG: "/finx-fc-student/api/v1/student/getUserMobileExistsFlag",
  URL_LEAD_REGISTER_CLICK_SAVE: "/finx-fc-admin/api/v1/leadRef/saveLeadRefDetails",
  URL_FC_REGISTER_USER: "/finx-fc-registration/api/v1/register/finx",
  URL_HIRE_FROM_US: "/finx-fc-admin/api/v1/corporateInquiry",
  URL_GET_TYPE_OF_COMPANY_LIST: "/finx-fc-admin/api/v1/corporateInquiry/getCompanyTypes",
  URL_GET_SECTOR_LIST: "/finx-fc-admin/api/v1/corporateInquiry/getAllSectors",
  URL_GET_TYPE_OF_CORPORATE_LIST: "/finx-fc-admin/api/v1/cielops/getCorporateType",
  URL_OTHER_PURPOSE_LIST: "/finx-fc-admin/api/v1/corporateInquiry/getAllPurpose",
  URL_NUMBER_OF_EMPLOYEES_LIST: "/finx-fc-admin/api/v1/corporateInquiry/getEmployeeRange",
  URL_GET_COURSE_CERTIFICATE:"/finx-fc-admin/api/v1/corporateInquiry/getAllCoursesAndCertifications",
  URL_GET_INTERNSHIP_PLACEMENTS:"/finx-fc-admin/api/v1/corporateInquiry/getAllInternshipPlacements",
  URL_GET_COLLEGE_UNIVERSITY:"/finx-fc-admin/api/v1/corporateInquiry/getCollegeUniversityType",
  URL_AH_REGISTER_USER: "/finx-fc-registration/api/v1/register/assesshub",
  URL_AH_REGISTER_USER_NEW: "/finx-fc-registration/api/v1/register/saveAssesment",
  URL_AH_REGISTER_USER_BULK:
    "/finx-fc-registration/api/v1/register/assessment/bulk",
  URL_AH_REGISTER_USER_BULK_NEW:
   "/finx-fc-registration/api/v1/register/saveAssessmentBulk",
  URL_AH_REGISTER_USER_GCUBE:"/finx-fc-registration/api/v1/register/createGcube",
  URL_AH_CREDENTIAL_USER: "/finx-fc-student/api/v1/studentprovidercredetails",
  URL_AH_CREDENTIAL_GET_USER: "/finx-fc-student/api/v1/studentprovidercredetails/getCredentials",
  URL_USER_ASSESSHUB: "/finx-fc-registration/hub/",
  URL_AH_CREDENTIAL_LOGIN:
    "https://www.assesshub.com/user/finxlogin.html?uname=", // r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
  //URL_GCUBE_CREDENTIAL_LOGIN: "http://180.151.87.237/FinX/Default.aspx?usertoken=",// r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
  URL_GCUBE_CREDENTIAL_LOGIN:
    "https://lms.finxpert.org/Default.aspx?usertoken=", // r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
  URL_LSM: "https://lms.finxpert.org/Default.aspx?usertoken=",

  URL_MOBILE_URL: "/finx-fc-student/api/v1/student/validatemobile",
  URL_ORDER_INSERT: "/finx-fc-payment/api/v1/order",
  URL_DO_Payment: "/finx-fc-payment/Payment/doPayment",
  URL_OFFLINE_PAYMENT: "/finx-fc-payment/Payment/offlinePayment",
  URL_UPDATE_EDUCATION_LOAN: "/finx-fc-student/api/v1/student/_updateEduLoan",
  URL_ORDER_DETAILS: "/finx-fc-payment/api/v1/order",
  URL_LEAD_REG_DATA: "/finx-fc-student/api/v1/student/getLeadRegisterDetails",
  URL_USERDATA: "/finx-fc-student/api/v1/student",
  URL_USERADDRESS: "/finx-thor-partyservices/api/v1/party/address",
  URL_UPDATEUSERPROFILE: "/finx-fc-admin/api/v1/admin/updateuser",
  URL_UPDATE_PARENT_DETAILS: "/finx-fc-student-dashboard/api/v1/studentDashboard/updateParentDetails",
  // ! Tab Apis
  URL_UPDATEUSERPREFERENCE: "/finx-fc-admin/api/v1/admin/updatepreference",
  URL_UPDATEUSERRESUME: "/finx-fc-admin/api/v1/admin/updateresume",
  URL_UPDATEUSEREDUCATION: "/finx-fc-admin/api/v1/admin/updateeducation",
  URL_UPDATEUSEREMPLOYMENT: "/finx-fc-admin/api/v1/admin/updateemployment",

  URL_UPLOADFILES: "/api/v1/document",
  URL_GETCOUNTRY: "/finx-thor-regionmanagement/api/v1/country",
  URL_GETSTATE: "/finx-thor-regionmanagement/api/v1/state",
  URL_GETCITY: "/finx-thor-regionmanagement/api/v1/city",
  URL_GETCITIES: "/finx-thor-regionmanagement/api/v1/city/fromstates", // ! List of cities from states
  URL_GETSUBURB: "/finx-thor-regionmanagement/api/v1/suburb",
  URL_GETSUBURBS: "/finx-thor-regionmanagement/api/v1/suburb/fromcities", // ! List of suburbs from cities
  URL_GETLOOKUP_BY_CD_TYPE_NAME:
    "/finx-thor-lookupmanagement/api/v1/codeValue/byName",
  URL_GETLOOKUP_BY_CD_TYPE_ID: "/finx-thor-lookupmanagement/api/v1/codeValue",
  URL_USERLIST_BY_PRODUCT_ID: "/finx-fc-student/api/v1/student/unassignedusers",

  // ! Email Authenticate : OTP / OTP Verification
  URL_VALIDATE_EMAIL: "/finx-fc-student/api/v1/student/emailValidate", // called when click here to authenticate your email in welcome email
  URL_USER_EMAIL_FLAG: "/finx-fc-student/api/v1/student/getUserEmailFlag", // called for email auth popup in student dashboard
  URL_OTP_EMAIL: "/finx-fc-student/api/v1/student/_sendOTPMail", // send otp
  URL_VALIDATE_OTP_EMAIL: "/finx-fc-student/api/v1/student/validateEmailOtp", // to validate email and entered otp
  URL_AUTHENTICATE_EMAIL:
    "/finx-fc-student/api/v1/student/Validate/email/patterns", // checks email pattern on blur of email
  // ! Party Preference & Employment
  URL_USERPREFERENCE: "/finx-thor-partyservices/api/v1/party/preference",
  URL_OTHERPREFERENCE:
    "/finx-thor-partyservices/api/v1/party/preference/otherpref",
  URL_PREFERENCEJOBROLE:
    "/finx-thor-partyservices/api/v1/party/preference/role",
  URL_PREFERENCEJOBFUNCTION:
    "/finx-thor-partyservices/api/v1/party/preference/jobFunction",
  URL_PREFERENCEJOBKEYSKILL:
    "/finx-thor-partyservices/api/v1/party/preference/keyskill",
  URL_PREFERENCEJOBINTERESTAREA:
    "/finx-thor-partyservices/api/v1/party/preference/interestarea",
  URL_PREFERENCENOTIFICATION:
    "/finx-thor-partyservices/api/v1/party/preference/notification",
   URL_PREFERREDDOMAIN:"/finx-thor-partyservices/api/v1/party/preference/preferreddomain",
  URL_USEREMPLOYMENT: "/finx-thor-partyservices/api/v1/party/employment",

  //GDPI
  URL_GDPI_STUDENT: "/finx-fc-student/api/v1/gdpi",
  URL_GDPI_BULKUPLOAD: "/finx-fc-student/api/v1/gdpi/gdpibulkupdate",

  //Admin Management
  URL_ADMIN_CREATE_USER: "/finx-fc-admin/api/v1/admin/adduser",
  URL_FETCH_ADMIN_USER: "/finx-thor-usermanagement/api/v1/user",
  URL_ADMIN_EDIT_USER: "/finx-fc-admin/api/v1/admin/updateuser",
  URL_INACTIVE_ADMIN_USER: "/finx-fc-admin/api/v1/admin/inactiveuser",
  URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID: "/finx-fc-admin/api/v1/admin/getuser",

  URL_BULK_USER_STATUS_CHANGE: "/finx-fc-admin/api/v1/admin/inactiveuser",

  //Role Management
  URL_ROLES: "/finx-thor-rolemanagement/api/v1/role",

  //Student Dashboard
  URL_INTERESTED_PRODUCT_KOTAK : "/finx-fc-student-dashboard/api/v1/studentDashboard/interestedProduct",
  URL_CALENDER_EVENTS:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/CalenderAndEvents",
  URL_ALERTS_NOTIFICATION_EVENTS:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/AlertsAndNotification",
  URL_PRODUCT_PRE_REQUISITE:
    "/finx-fc-product/api/v1/prerequisite/getProductPreRequisite",
  URL_PRODUCT_ALL_EXCEPT_MAPPED: "/finx-fc-product/api/v1/product/productlist",
  URL_NOTIFICATION_EVENTS: "/finx-thor-notification/api/v1/queue/notification",
  URL_ASSESSMENT_UPDATION:
    "/finx-fc-student/api/v1/studentassessment/scoreupdate",
  // ! Videos
  URL_VIDEOS: "/finx-fc-product/api/v1/video",
  URL_CATEGORY: "/finx-fc-product/api/v1/category",
  URL_UPDATE_CATEGORY_MAP: "/finx-fc-product/api/v1/product/updateCategoryMap",
  URL_SUBSCRIBE: "/finx-fc-product/api/v1/subscribe",
  URL_WISHLIST: "/finx-fc-product/api/v1/wishlist",
  URL_CART: "/finx-fc-product/api/v1/cart",
  URL_WISHLIST_COUNT: "/finx-fc-product/api/v1/wishlist/count",
  URL_CART_COUNT: "/finx-fc-product/api/v1/cart/count",
  URL_DISCOUNT: "/finx-fc-product/api/v1/discount",
  URL_DISCOUNT_CODE: "/finx-fc-product/api/v1/discount/coupon",
  URL_ALL_DISCOUNT_CODE: "/finx-fc-product/api/v1/discount/coupon/all",
  // URL_JOBS_FROM_KEYSKILL: "/finx-fc-product/api/v1/product/jobs", // Moved to partyservices
  URL_JOBS_FROM_KEYSKILL:
    "/finx-thor-partyservices/api/v1/party/preference/jobsfromskills",
  URL_JOBS: "/finx-thor-partyservices/api/v1/party/preference/jobs",
  URL_APPLY_JOB: "/finx-thor-partyservices/api/v1/party/preference/jobs/apply",
  URL_APPLIED_JOBS:
    "/finx-thor-partyservices/api/v1/party/preference/jobs/applied",
  URL_JOBS_QUESTIONS:
    "/finx-thor-partyservices/api/v1/party/preference/job/questions",
  URL_JOBS_FEEDBACK:
    "/finx-thor-partyservices/api/v1/party/preference/job/feedbacks",
  URL_JOBS_LOCATION:
    "/finx-thor-partyservices/api/v1/party/preference/job/locations",
  URL_SIMILAR_JOBS:
    "/finx-thor-partyservices/api/v1/party/preference/jobs/similar",
  URL_RECOMMENDED_COURSE:
    "/finx-fc-product/api/v1/product/courses",
  URL_REJECTION_REASONS:
    "/finx-thor-partyservices/api/v1/party/preference/jobs/rejection/reasons",
  URL_CTA_RESPONSE: "/finx-thor-partyservices/api/v1/party/preference/job/cta",
  URL_SAVE_JOB_APPLY_CLICK: "/finx-thor-partyservices/api/v1/party/preference/jobs/jobApplyClick",

  //Institute Name
  URL_INSTITUTE_LIST: "/finx-fc-admin/api/v1/institute",

  //Generalinquiry
  URL_GENERALENQUIRY: "/finx-fc-admin/api/v1/StudentEnquiry",
  //get Progress Overview Details
  URL_GET_PROGRESS_OVERVIEW:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/getProgressOverviewDetails",

  URL_BULK_UPLOAD: "/finx-fc-student/api/v1/student/bulkupload",

  URL_MODULE_COURSE_PRD_PARTY:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/getModuleAgainstCourse",
  //?productID=20849&studentPartyID=73671
  URL_MODULE_BY_PRODUCT: "/finx-fc-product/api/v1/module",
  //? productID = 20849
  URL_STUDENT_ASSESSMENT_PREQUISITE:
    "/finx-fc-student/api/v1/studentassessment/prerequisite",
  URL_STUDENT_ASSESSMENT_GET_PREQUISITE:
    "/finx-fc-student/api/v1/studentassessment/getPrerequisite",
  URL_DOCUMENT_DOWNLOAD: "/thor-documentmanagement/api/v1/document/publicurl",

  //get payment history for student
  URL_STUDENT_PAYMENT_HISTORY: "/finx-fc-payment/api/v1/order",
  // get student enrollment
  URL_STUDENT_ENROLLMENT_HISTORY: "/finx-fc-student/api/v1/student",

  // Chart service
  URL_REPORT_COURSE: "/finx-fc-student/api/v1/studentreport/course",
  URL_REPORT_ENROLLMENT: "/finx-fc-student/api/v1/studentreport/Enrollment",
  URL_REPORT_SESSION: "/finx-thor-authentication/api/v1/sessionReport",

  // S3 BUCKET PATH
  URL_DOC_SAVE_PATH: "http://finxqa.s3.ap-south-1.amazonaws.com/",

  //UNBLOCKUSER
  URL_UNBLOCK_USER: "/finx-thor-authentication/api/v1/user/updateblockeduser",

  //Check PAN Duplicate
  URL_Check_PAN: "/finx-fc-student/api/v1/student/validateupdateuser",

  //validater user email mobile pan aadhar while adding

  URL_Validate_New_User: "/finx-fc-student/api/v1/student/validateuser",

  URL_USER_MANAGEMENT: "/finx-thor-usermanagement/api/v1/user/activity/report",

  URL_ASSESSMENT_REPORT: "/finx-fc-student/api/v1/studentassessment",
  URL_CFX_ASSESSMENT_REPORT:
    "/finx-fc-student/api/v1/studentcfxreport/downloadcfxreport",

  URL_CREATED_BY: "/finx-thor-usermanagement/api/v1/user",
  URL_UNASSIGNED_USERS_REPORT:
    "/finx-fc-student/api/v1/student/unassignedusers",
  URL_APPLY_LOAN: "/finx-fc-student/api/v1/student/_applyEduLoan",
  URL_GET_APPLY_LOAN: "/finx-fc-student/api/v1/student/_getEduLoan",

  URL_BULK_PAYMENT_UPLOAD:
    "/finx-fc-student/api/v1/student/bulkupload/offline/payment",
  URL_GET_JOB_DETAILS: "/finx-fc-student/api/v1/job",
  URL_GET_JOB: "/finx-fc-student/api/v1/job/getJobDetails",
  URL_GET_JOB_BY_ID: "/finx-fc-student/api/v1/job/getAllJobDetailsById",
  URL_UPDATE_JOB_RECOM_COURSE: "/finx-fc-student/api/v1/job/updateJobRecommendedCourses",
  URL_GET_STAGE_COUNT: "/finx-fc-student/api/v1/job/stage_count",
  URL_GET_CANDIDATE_JOB_DETAILS:
    "/finx-fc-student/api/v1/job/candidateDetailsReport",
  // URL_SAVE_STAGE_UPDATE: "/finx-fc-student/api/v1/job/stageUpdate",
  URL_SAVE_STAGE_UPDATE_JOB:"/finx-fc-student/api/v1/job/jobStage",
  URL_SAVE_STAGE_UPDATE_COURSE:"/finx-fc-student/api/v1/job/courseStage",
  // URL_GET_JOB_HISTORY: "/finx-fc-student/api/v1/job/getJobHistory",
  URL_GET_JOB_HISTORY: "/finx-fc-student/api/v1/job/applicationsHistory",
  URL_GET_JOB_STATUS_HISTORY: "/finx-fc-student/api/v1/job/statusHistory",
  URL_JOB_LOCATION_LIST: "/finx-fc-student/api/v1/job/getJobLocations",
  URL_UPDATE_ACTUAL_JOINING_DATE: "/finx-fc-student/api/v1/job/updateActualJoiningDate",
  URL_UPDATE_STATUS: "/finx-fc-student/api/v1/job/updateStatus",
  URL_UPDATE_PLACEMENT_BY: "/finx-fc-student/api/v1/job/savePlacementBy",
  URL_PLACEMENT_BY_LIST:"/finx-fc-student/api/v1/job/getAllPlacementByDetails",
  URL_MORNING_STAR_EMAIL:
    "/finx-fc-registration/api/v1/register/sendMailToAdmin",
  URL_REJECT_APPLICATION: "/finx-fc-student/api/v1/job/updateStatus",
  URL_NEXT_STAGE_APPLICATION:
    "/finx-fc-student/api/v1/job/nextStageupdateStatus",
  URL_SMS_EMAIL_CANDIDATE:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/sendMailSmsToUser",
  URL_UPDATE_JOB_DETAILS: "/finx-fc-student/api/v1/job/updateJobDetails",
  URL_BULK_JOB_UPLOAD: "/finx-fc-student/api/v1/job/createBulk",
  URL_BULK_GCUBE_UPLOAD: "/finx-fc-registration/api/v1/utility/gcube/bulk",
  URL_ENTER_GCUBE_PASSWORD: "/finx-fc-registration/api/v1/utility/login",
  URL_CREATE_JOB: "/finx-fc-student/api/v1/job/create",
  URL_CREATE_QUESTIONNAIRE: "/finx-fc-student/api/v1/job/createJobQuestions",
  URL_UPDATE_QUESTIONNAIRE: "/finx-fc-student/api/v1/job/updateJobQuestion",
  URL_GET_QUESTIONNAIRE: "/finx-fc-student/api/v1/job/getJobQuestion",
  URL_GET_JOB_ROLE: "/finx-fc-student/api/v1/job/getJobRoleDetails",
  URL_REFER_APPLICATION:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/refferalMail",
  URL_UPLOAD_PROFILE_SCORE:
    "/finx-fc-student/api/v1/student/upload/profileScore",
  URL_ANSWER_TYPE_LIST: "/finx-fc-student/api/v1/job/getAnswerType",

  // URL_GET_SKILL: "/finx-fc-student/api/v1/job/getKeySkill",
  URL_GET_SKILL: "/finx-fc-student/api/v1/job/getJobKeySkill",
  // URL_GET_CORPORATE_TYPE: "/finx-fc-admin/api/v1/cielops/getCorporateType",
  URL_GET_CORPORATE_TYPE: "/finx-fc-admin/api/v1/cielops/getCorporatedetails",
  URL_GET_JOB_CATEGORY: "/finx-fc-student/api/v1/job/getJobCategories",
  URL_GET_SUBURB: "/finx-fc-student/api/v1/job/getSuburbByCityId",
  URL_GET_COROPRATE_DETAILS:
    "/finx-fc-admin/api/v1/cielops/getCorporatedetails",
  URL_GET_INTERVIEWSTAGES: "/finx-fc-student/api/v1/job/getInterviewStages",
  URL_GET_MULTICITY: "/finx-fc-student/api/v1/job/getCityByMultipleStateId",
  URL_GET_MULTISUBURB: "/finx-fc-student/api/v1/job/getSuburbByMultipleCityId",
  CONST_BUCKET_NAME: "finx-bucket-tag",
  URL_DOWNLOAD_ZIP: "/finx-fc-student/api/v1/student/resumeDownload",

  // // ! Google and Facebook Client Id
  // GOOGLE_CLIENT:
  //   "461964815340-cerrjf3cad5jcm1803kvvovdrihn7pq6.apps.googleusercontent.com",
  // FACEBOOK_CLIENT: "569729907816313",
  // ! Google and Facebook Client Id
  GOOGLE_CLIENT:"170085013636-jk42ieaoncnucthm1jribvi8inkf2kjd.apps.googleusercontent.com",
  FACEBOOK_CLIENT: "193862983495901",
  // ! GOOGLE Calander
  API_KEY: `AIzaSyC1b-PMzYovZpLp-BnDqXngqJaAsx8yhSk`,
  DISCOVERY_DOC:
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  CLIENT_ID: `564292017445-2138id3qvqb4q41nl60bskdq1hm8snpb.apps.googleusercontent.com`,
  SCOPES: "https://www.googleapis.com/auth/calendar.readonly",
  OPERATION_EMAIL: "tarang.patel@njgroup.in",
  DEFAULT_OPERATION_EMAIL: "snehal.c@njgroup.in",

  // Social Register And Login
  URI_LOGIN_SOCIAL: "/finx-thor-authentication/api/v1/login/social",
  URL_FC_REGISTER_SOCIAL_USER:
    "/finx-fc-registration/api/v1/register/finx/social",
  URL_USER_EXIST: "/finx-thor-usermanagement/api/v1/user/validate/email",
  URL_USER_SOCIAL_DETAILS_UPDATE:
    "/finx-thor-usermanagement/api/v1/user/social",

  FEAT_PRODUCT_ID: 20849,
  CFX_PRODUCT_ID: 1083,
  // ! According to Database record's generated id : currrently on test : 108
  EMAIL_VERIFICATION_MAIL_TEMPLATE: 108,
  VALIDATE_EMAIL_OTP_MAIL_TEMPLATE: "7005",

  // ! Discout Master API's

  // GET API
  URL_GET_ALL_DISCOUNTS: "/finx-fc-product/api/v1/discount/allDiscounts",
  URL_GET_DISCOUNT_TYPE_LIST:
    "/finx-fc-product/api/v1/discount/discounttypelist",
  URL_GET_ALL_ACTIVE_VIDEOS: "/finx-fc-product/api/v1/discount/allActiveVideos",
  URL_GET_ALL_ACTIVE_COURSES:
    "/finx-fc-product/api/v1/discount/allActiveCourses",
  // URL_GET_ALL_ACTIVE_VIDEOS_AND_COURSES:
  //   "/finx-fc-product/api/v1/discount/allVideosandCourses",

  // GET API WITH PARAMS
  URL_GET_ASSOCIATED_PRODUCTS:
    "/finx-fc-product/api/v1/discount/associatedProducts",
  URL_FETCH_DISCOUNT_DETAILS_ON_ID:
    "/finx-fc-product/api/v1/discount/getDetailsOnId",

  // POST API
  // On Save
  URL_SAVE_DISCOUNT_DETAILS: "/finx-fc-product/api/v1/discount",

  // PUT API
  // On Update
  URL_UPDATE_DISCOUNT_DETAILS: "/finx-fc-product/api/v1/discount",

  //feteching product validaity details
  URL_PRODUCT_VALIDITY_ALL_DETAILS:
    "/finx-fc-product/api/v1/product/validity/AllProducts",

  //VIDEO API'S
  URL_VIDEO_REPORT_LIST: "/finx-fc-product/api/v1/video/reportlist",
  URL_GET_ALL_CATEGORIES: "/finx-fc-product/api/v1/category",
  URL_GET_SUB_CATEGORIES_ON_SELECTED_CATEGORIES:
    "/finx-fc-product/api/v1/category",
  URL_SAVE_VIDEO: "/finx-fc-product/api/v1/video/save",
  URL_GET_VIDEO_DETAILS_BY_ID: "/finx-fc-product/api/v1/video/getdetailsonid",
  URL_UPDATE_VIDEO_DETAILS: "/finx-fc-product/api/v1/video",

  //EVENT MASTER API'S
  URL_GET_ALL_EVENT_TYPES:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/type",
  URL_GET_FILTERED_CITY:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/filteredCity",
  URL_GET_SUBURB_LIST_ON_CITY_ID:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/suburb",
  URL_GET_FILTERED_STUDENT:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/filteredStudent",
  URL_ADD_EVENT: "/finx-fc-student-dashboard/api/v1/studentDashboard/event/add",
  URL_EXCEL_FILE_VALIDATE:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/bulkupload",
  URL_EVENT_REPORT:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/report",
  URL_GET_EVENT_DETAILS_BY_ID:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/detailsOnEventID",
  URL_UPDATE_EVENT_DETAILS:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/update",
  URL_SEND_EVENT_REMAINDER_EMAIL:
  "/finx-fc-student-dashboard/api/v1/studentDashboard/event/sendEventRemainderMail",
  //CORPORATE MASTER APIS
  URL_CORPORATE_REPORT_LIST:"/finx-fc-admin/api/v1/cielops/getCorporateReport",
  URL_UPDATE_CORPORATE_DETAILS:"/finx-fc-admin/api/v1/cielops/updateCorporate",
  URL_SAVE_CORPORATE_DOCUMENT:"/thor-documentmanagement/api/v1/document",
  URL_GET_CORPORATE_DETAILS_BY_ID:"/finx-fc-admin/api/v1/cielops/getCorporateReport",
  URL_SAVE_CORPORATE:"/finx-fc-admin/api/v1/cielops/saveCorporate",
  URL_GET_IND_SUBIND_FROM_CORPID: "/finx-fc-admin/api/v1/cielops/getIndSubIndsFromCorpId",
 //feedback
 URL_POSTFEEDBACK: "/finx-fc-student/api/v1/student/savefeedback",
 //concernType List
 URL_CONCERN_TYPE_LIST:"/finx-fc-student/api/v1/student/feedback/getAllConcerns",
  //GOOGLE CALENDER API

  URL_API_KEY: "AIzaSyBp2LlOhYF0g-O9w8B8oCed8Ef97cI0X6k",
  URL_DISCOVERY_DOC:
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  URL_CLIENT_ID: `944339217905-vc95h22e6ughmlsnrg9pd9c92oh4d13q.apps.googleusercontent.com`,
  URL_SCOPES: "https://www.googleapis.com/auth/calendar",

  // RSVP API
  URL_RSVP_RESPONSE:
    "/finx-fc-student-dashboard/api/v1/studentDashboard/event/rsvp",
  // FINX DATA DUMP REPORT API's
  URL_FINX_DATA_DUMP_1 : "/finx-thor-lookupmanagement/api/v1/CRM/getCrmDataDumpReport1",
  URL_FINX_DATA_DUMP_2 : "/finx-thor-lookupmanagement/api/v1/CRM/getCrmDataDumpReport2",
  URL_FINX_DATA_DUMP_3 : "/finx-thor-lookupmanagement/api/v1/CRM/getCrmDataDumpReport3",
  URL_FINX_DATA_DUMP_4 : "/finx-thor-lookupmanagement/api/v1/CRM/getCrmDataDumpReport4",
  URL_FINX_DATA_DUMP_5 : "/finx-thor-lookupmanagement/api/v1/CRM/getCrmDataDumpReport5",

//WATERMARKED VIDEO API
URL_GET_WATERMARKED_VIDEO:"/finx-fc-admin/api/v1/admin/watermarked-video",
//Total Experience List API
URL_GET_TOTAL_EXPERIENCE_LIST:"/finx-fc-student/api/v1/job/getAllExperience",
//Annual Salary List API
URL_GET_ANNUAL_SALARY_LIST:"/finx-fc-admin/api/v1/profile/annualSalaryList",
//Price List API
URL_GET_PRICE_LIST_VIDEO:"/finx-fc-product/api/v1/video/videoprice",
URL_GET_PRICE_LIST_COURSE:"/finx-fc-product/api/v1/product/productprice",
URL_GET_CERTIFICATE_QUALIFICATION_LIST:"/finx-fc-admin/api/v1/admin/getallqualificationcertificate",
URL_GET_TYPE_OF_COURSE_LIST:"/finx-fc-product/api/v1/product/getCourseType",
URL_GET_ADD_ON_COURSE_LIST:"/finx-fc-product/api/v1/product/getAllAddOnCourses",
URL_GET_SELECTED_ADD_ON_COURSE_LIST:"/finx-fc-product/api/v1/product/getSelectedAddOnCourses",
URL_CHECK_PARENT_CHILD_EXISTS:"/finx-fc-product/api/v1/product/checkIfChildOrParentExists",
URL_GET_PARENT_MAX_PRICE:"/finx-fc-product/api/v1/product/getMaxPriceOfParentCourses",
URL_UPDATE_ADD_ON_COURSE:"/finx-fc-product/api/v1/product/updateAddOnCourse",
URL_COURSE_QUESTIONS:"/finx-thor-partyservices/api/v1/party/preference/job/questions",
URL_APPLY_COURSE:"/finx-thor-partyservices/api/v1/party/preference/course/apply",
URL_NON_PAYABLE_PAYMENT:"/finx-fc-payment/Payment/nonPayableProductPayment",
URL_PRODUCT_ID_FROM_SOURCE_PARAM:"/finx-fc-student/api/v1/student/getProductIdFromSourceParam",
URL_GET_TYPE_OF_ANNUAL_FAMILY_LIST:"/finx-fc-admin/api/v1/admin/getAllAnnualFamilyIncome",
URL_GET_TYPE_OF_INCOME_PROOF_LIST:"/finx-fc-admin/api/v1/admin/getFamilyIncomeProofDetails",
URL_JOBS_APPLIED_LOCATION:"/finx-thor-partyservices/api/v1/party/preference/job/appliedLocations",
URL_LATEST_THREE_ALERTS : "/finx-fc-student-dashboard/api/v1/studentDashboard/getLatestAlerts",
URL_LATEST_TREE_MSG : "/finx-fc-student-dashboard/api/v1/studentDashboard/getLatestMessages",
URL_ALERT_LIST_BY_DATE : "/finx-fc-student-dashboard/api/v1/studentDashboard/getAlertList",
URL_ALERT_COUNTS : "/finx-fc-student-dashboard/api/v1/studentDashboard/getAlertCount",
URL_MSG_COUNT : "/finx-fc-student-dashboard/api/v1/studentDashboard/getMessageCount",
URL_MSG_LIST_BY_DATE : "/finx-fc-student-dashboard/api/v1/studentDashboard/getMessageList",
URL_UPDATE_ALERT_COUNT : "/finx-fc-student-dashboard/api/v1/studentDashboard/updateAlertCount",
URL_UPDATE_MSG_COUNT : "/finx-fc-student-dashboard/api/v1/studentDashboard/updateMessageCount",
URL_NOTIFICATION_DOCUMENT_DOWNLOAD:"/finx-fc-student-dashboard/api/v1/studentDashboard/getDocumentUrl",
URL_SAVE_CPBFI_DETAILS:"/finx-fc-student/api/v1/student/saveCPBFIPopuupDetails",
URL_GET_CPBFI_DETAILS:"/finx-fc-student/api/v1/student/getCPBFIPopuupFlag",
URL_ACCEPT_ADMISSION_LETTER:"/finx-fc-student-dashboard/api/v1/studentDashboard/saveAdmissionLetterAccept",
URL_RATING_REPORT_DASHBOARD:"/finx-fc-admin/api/v1/education/ratingReport",
URL_RATING_FEEDBACK_LIST:"/finx-fc-admin/api/v1/education/getFeedbackList",
URL_RATING_EXTERNAL_COMMENTS_LIST:"/finx-fc-admin/api/v1/education/getExternalCommentList",
URL_RATING_SAVE_FEEDBACK:"/finx-fc-student/api/v1/job/saveRatingFeedback",
URL_GET_EDUCATION_QUALIFICATION_BY_GROUP_NAME:"/finx-fc-admin/api/v1/education/qualification",
URL_GET_RATING_HISTORY:"/finx-fc-student/api/v1/job/candidateDetailsReport",
URL_VIDEO_RESUME_RATING_LINK_FROM_DOCID:"/finx-fc-student/api/v1/job/downloadVideoResume",
URL_SAVE_DOB_DETAILS:"/finx-fc-admin/api/v1/admin/saveDateOfBirth",
URL_GET_VOCATIONAL_COURSES:"/finx-fc-admin/api/v1/education/getVocationalCourses",
URL_GET_APPLICANT_COUNT_HISTORY:"/finx-fc-student/api/v1/job/getApplicantCountDetails",
URL_GET_ACTIVE_EMPLOYEE_LIST:"/finx-fc-student/api/v1/job/getAllEmployeeDetails",
URL_UNSUBSCRIBE_REASONS_LIST:"/finx-thor-notification/api/v1/unsubscribe/unsubscribeReasons",
URL_SAVE_UNSUBSCRIBE:"/finx-thor-notification/api/v1/unsubscribe",
URL_BULK_UNSUBSCRIBE_BOUNCED_UPLOAD:"/finx-fc-admin/api/v1/admin/unsubscribe-bounced-upload",
URL_GET_UNSUBSCRIBE_RESPONSE:"/finx-thor-notification/api/v1/unsubscribe/getUnsubscribeResponse",
URL_GET_FILTERED_USER:"/finx-thor-usermanagement/api/v1/user/getFilteredUser"
};

