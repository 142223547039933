import React, { Component } from "react";
import Faq from "./faq";
import Finxheader from "../finxheader/finxheader";
import AdminHeader from "../../components/dashboardheader/adminheader";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";

import Finxfooter from "./finxfooter";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";

import { bindActionCreators } from "redux";
import { fetchAlertNotificationEvents, fetchNotificationEvents,/* fetchProductDetailsByPartyId */ } from "../../services/fetchStudentDashboard.service";
// import { LoginService } from "../../services/login.service";
import { connect } from "react-redux";
import { getRoleID } from "../../components/getRoleId";

const mapStateToProps = state => ({
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const finxfaq = connectFunction(
  class extends Component {


    //export default class finxfaq extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        roleId: getRoleID(),
      }
    }
    componentDidMount() {
      let partyID = localStorage.getItem("userid-Token");
      // LoginService.TokenUser(res => {
      //   localStorage.setItem("jwt-token", res.responseObject);
      // });
      const {
        // fetchAlertNotificationEvents,
        fetchNotificationEvents
      } = this.props;
      // fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);
    }
    render() {
      // const {
        // alertNotificationEvents,
        // pendingAlertNotificationEvents,
        // errorAlertNotigicationEvents,
        // notificationEvents,
        // pendingNotificationEvents,
        // errorNotificationEvents,
      // } = this.props;

      //console.log("alertNotificationEvents",this.props.alertNotificationEvents)
      //console.log("notificationEvents",this.props.notificationEvents)
      localStorage.setItem("alertdata", JSON.stringify(this.props.alertNotificationEvents))
      localStorage.setItem("notealertdata", JSON.stringify(this.props.notificationEvents))


      let header = "";
      if (localStorage.getItem('userid-Token')) {
        if ([5,'5'].includes(this.state.roleId)) {
          header = <DashboardHeader
            //alertNotificationEvents={localStorage.getItem('alertdata')}
            //notificationEvents={localStorage.getItem('notealertdata')}
            {...this.props}
          />
        }
        else {
          header = <AdminHeader
            //alertNotificationEvents={localStorage.getItem('alertdata')}
            //notificationEvents={localStorage.getItem('notealertdata')}
            {...this.props}
          />
        }
      }
      else {
        header = <Finxheader {...this.props} />
      }
      return (
        <React.Fragment>
          {header}
          <Faq />
          <Finxfooter />
        </React.Fragment>
      );
    }
  });
export default finxfaq;
