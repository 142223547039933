const crypto = require("crypto");
export const EncryptionService = {
  HandleEncrypt,
};
function HandleEncrypt(data1) {
  try {
    const SECRET_KEY = "ThisIsASecretKey1234567890123456";
    const IV = "ThisIsAnIV123456";
    let data;
    // data = data1;
    // Convert integer to string if data1 is an integer
    if (typeof data1 === 'number') {
      data = data1.toString();
    } else {
      data = data1;
    }
    console.log('encryptedData data',data1,data,typeof data1,typeof data);
    const cipher = crypto.createCipheriv(
      "aes-256-cbc",
      Buffer.from(SECRET_KEY),
      Buffer.from(IV, "utf8")
    );
    let encryptedData = cipher.update(data, "utf8", "base64");
    encryptedData += cipher.final("base64");
console.log('encryptedData 123',encryptedData);
    return encryptedData;
  } catch (error) {
    console.error("Exception during encryption: ", error.message);
    return null;
  }
}
