/*jshint esversion: 6 */

import React, { Component } from 'react';
import Routes from '../components/routes';
import { LoginService } from '../services/login.service';
import { Global_var } from '../global/global_var';
import ErrorBoundary from '../components/errorboundary/errorboundary';
import axios from 'axios';
// import IdleTimer from 'react-idle-timer';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Redirect,
//   Link
// } from "react-router-dom";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: '',
      reasonCode: '',
      reasonText: '',
      loading: 'false',
      token: '',
      jtitoken: '',
      timeout: 1000 * 1200 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    }


    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);

    if(Global_var.SECURITY_CHANGE==="false"){
    axios.defaults.headers.common['applicationid'] = '11101';
    axios.defaults.headers.common['buid'] = '2';
    axios.defaults.headers.common['subbuid'] = '1';
    axios.defaults.headers.common['environment'] = 'dev';
    axios.defaults.headers.common['issuer'] = 'finx';
    }
    axios.defaults.headers.common['tokentype'] = 'jti';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // if (localStorage.getItem('login') !== '' && localStorage.getItem('login') !== null) {
    //   axios.defaults.headers.common['userId'] = JSON.parse(localStorage.getItem('login'))["userId"];
    // } else {
    //   axios.defaults.headers.common['userId'] = '9211420';
    // }

    // if (localStorage.getItem('login') !== '' && localStorage.getItem('login') !== null) {

    //   axios.defaults.headers.common['userlogin'] = JSON.parse(localStorage.getItem('login'))["userName"];
    // } else {
    axios.defaults.headers.common['userlogin'] = 'pramila@gmail.com';
    // }
    if(Global_var.SECURITY_CHANGE==="true"){
    console.log("Vamsi APP JS jti-token')",localStorage.getItem('jti-token'))
    console.log("Vamsi APP JS 'jwt-token')",localStorage.getItem('jwt-token'))

    const jtiToken = localStorage.getItem('jti-token');
    const jwtToken = localStorage.getItem('jwt-token');
    console.log("Vamsi APP JS if ",!jtiToken , !jwtToken,["",null,undefined,"null","undefined"].includes(jtiToken) , ["",null,undefined,"null","undefined"].includes(jwtToken))
    
    if (["",null,undefined,"null","undefined"].includes(jtiToken) || ["",null,undefined,"null","undefined"].includes(jwtToken)) {
      const currentPath = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      const sessionTimeout = params.get('sessiontimeout');
    
      console.log("Vamsi APP Params", sessionTimeout, params, window.location.pathname,currentPath);
          if (currentPath !== '/') {
            setTimeout(() => {
              if(currentPath!=="/unsubscribe-page" && currentPath !== "/register"){
            localStorage.clear();
            window.location.href = "/?sessiontimeout=true";
              }
          }, 200);
        }
        else if(currentPath === '/'){
          LoginService.TokenUser(res => {
            localStorage.setItem('jwt-token', res.responseObject);
            localStorage.setItem('jti-token', res.responseObject);
          })
        }
    }
  }
    if (localStorage.getItem('jti-token') !== '' && localStorage.getItem('jti-token') !== null&&localStorage.getItem('jti-token') !== undefined) {
      axios.defaults.headers.common['jtitoken'] = localStorage.getItem('jti-token');
    }
    if (localStorage.getItem('jwt-token') !== '' && localStorage.getItem('jwt-token') !== null&&localStorage.getItem('jwt-token') !== undefined) {
      axios.defaults.headers.common['token'] = localStorage.getItem('jwt-token');
    }
  }


  _onAction(e) {
    //console.log('user did something', e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    //console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      //alert("redirecting to login");
      window.location.href = "/?sessiontimeout=true"

    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }

  }


  render() {
    // const { match } = this.props
    return (
      /* <>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce ={250}
          caoture = { false }
                timeout={this.state.timeout} /> */

      <ErrorBoundary>
        <React.Fragment>
          <Routes {...this.state} />

        </React.Fragment>
      </ErrorBoundary>

      /* </>  */
    );
  }
}

export default App;
