import React from 'react';
import $ from "jquery";
import './ForcedPopup.css';
import moment from 'moment';
// import loadable from '@loadable/component';
class ForcedPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshOccurred: false,
            // timeRemaining1:this.props.timeRemaining,
            isRetry: false
        };
        this.timerID = null;
    }
    handleTimer = () => {
        const { timeRemaining, isMaintenance } = this.props;
        // const { timeRemaining1 } = this.state;
        // Handling maintenance mode
        if (isMaintenance === 0) {
            $('.modal-backdrop').hide();
            window.$('#maintainance-modal').modal('hide');
            document.querySelector("#loader").style.display = "block";
            document.querySelector("#loader").style.visibility = "visible";
        }
        // Determine if timeRemaining is a full datetime or just a time
        let toDate;
        if (moment(timeRemaining, 'DD/MM/YYYY HH:mm:ss', true).isValid()) {
            // Parse full datetime string
            toDate = moment(timeRemaining, 'DD/MM/YYYY HH:mm:ss');
        } else if (moment(timeRemaining, 'HH:mm:ss', true).isValid()) {
            // Parse time-only string assuming today’s date
            const now = moment();
            toDate = now.clone().set({
                hour: moment(timeRemaining, 'HH:mm:ss').hour(),
                minute: moment(timeRemaining, 'HH:mm:ss').minute(),
                second: moment(timeRemaining, 'HH:mm:ss').second(),
            });
        } else {
            console.log("Unsupported timeRemaining format:", timeRemaining);
        }

        const now = moment();
        if (toDate.isBefore(now)) {
            this.setState({ timeRemaining1: '00:00:00' });
            clearInterval(this.timerID);
        } else {
            const updateTimer = () => {
                const now = moment();
                // Ensure `toDate` is a Moment object and calculate the difference in milliseconds
                const differenceInMs = moment(toDate).diff(now);
                if (differenceInMs <= 0) {
                    // Timer has reached zero or past
                    this.setState({ timeRemaining1: '00:00:00' });
                    clearInterval(this.timerID);
                } else {
                    // Calculate duration from milliseconds
                    const duration = moment.duration(differenceInMs);
                    const hours = String(duration.hours()).padStart(2, '0');
                    const minutes = String(duration.minutes()).padStart(2, '0');
                    const seconds = String(duration.seconds()).padStart(2, '0');
                    const formattedTime = `${hours}:${minutes}:${seconds}`;
                    this.setState({ timeRemaining1: formattedTime });
                }
            };

            updateTimer();
                this.timerID = setInterval(updateTimer, 1000);
        }
    };

    componentWillUnmount() {
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    }
    componentDidMount() {
        this.handleTimer();
    }

    componentDidUpdate(prevState,prevProps) {
        if (
            prevState.timeRemaining1 !== this.state.timeRemaining1 &&
            this.state.timeRemaining1 === '00:00:00' &&
            !this.state.refreshOccurred) {
            this.setState({ refreshOccurred: true });
        }
        else {
            window.$('#maintainance-modal').modal('show');
            document.querySelector("#loader").style.display = "none";
            document.querySelector("#loader").style.visibility = "hidden";
        }
    }

    render() {
        return (
            <>
                <div
                    className="modal fade maintainance-modal"
                    id="maintainance-modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            </div>
                            <div className="modal-body">
                                <div className="popup-content">
                                    <div className="maintenance-icon"><span role="img" aria-label="Hammer Tools">🛠️</span></div>
                                    <h1>Under Maintenance</h1>
                                    <p>We are currently performing scheduled maintenance. Please check back soon!</p>
                                    <p>
                                        Time Remaining:
                                        <span style={{ color: 'red', fontWeight: 'bold', marginLeft: '4px' }}>
                                            {this.state.timeRemaining1}
                                        </span>
                                    </p>
                                    <button onClick={() => {
                                            this.props.fetchDeploymentFlag();
                                    }
                                    }>Retry</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ForcedPopup;
