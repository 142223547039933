import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Paper from '@material-ui/core/Paper';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import { RegisterUserService } from "../../services/registration.service";
import studentIcon from "../../assets/images/students.png";
import { errorNotification, error } from "../notification/notifications";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));
const steps = ["Payment", "Thankyou"];

const Student = (props) => {
  const classes = useStyles();
  debugger;
  const [activeStep] = React.useState(2);
  localStorage.setItem("Thankyouprops", JSON.stringify(props));
  const handleBack = (props) => {
    if (
      JSON.parse(localStorage.getItem("PaymentDet")).productName ===
        "productName=CFX" ||
      JSON.parse(localStorage.getItem("PaymentDet")).productID === "1" ||
      JSON.parse(localStorage.getItem("PaymentDet")).productID === 1
    ) {
      var loan = [
        {
          studentPartyID: JSON.parse(localStorage.getItem("PaymentDet"))
            .studentPartyID,
          productID: JSON.parse(localStorage.getItem("PaymentDet")).productID,
          loanStatus: "3",
          comment: "User had made the payment",
          paymentID: 0,
        },
      ];
      console.log("loan obj:", loan);
      RegisterUserService.updateEduLoan(loan, (res) => {});
    } else {
    }
    if (
      localStorage.getItem("login") !== null ||
      localStorage.getItem("login1") !== null
    ) {
      if (
        JSON.parse(localStorage.getItem("login"))["role"][0].roleId === 5 ||
        JSON.parse(localStorage.getItem("login1"))["role"][0].roleId === 5
      ) {
        localStorage.setItem("reload", "true");
        setTimeout(() => {
          window.location.href = "/studentDashboard";
        }, 500);
      }
      // else {
      //   window.location.href = "/";
      // }
    } else {
      window.location.href = "/";
    }
  };

  return (
    <React.Fragment>
      {/* {console.log(props)} */}

      <CssBaseline />
      {/* <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        {props.name}
                    </Typography>
                </Toolbar>
            </AppBar> */}
      {/* <main className={classes.layout}> */}
      {/* <Paper className={classes.paper}> */}

      <React.Fragment>
        {
          <div className="container">
            <div className="col-md-7 col-sm-7 col-md-offset-3 col-sm-offset-3 mt-30">
              <div className="reset">
                <h3 align="center">Thank You </h3>
              </div>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className="modal-content">
                <section className="registration resetpass-modal">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="modal-head"></div>
                        <img
                          src={studentIcon}
                          className="img-responsive"
                          alt="img"
                        />
                      </div>
                      <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12">
                        <div className="reset">
                          {/* <h3>Congratulations! You have completed {JSON.parse(localStorage.getItem('PaymentDet')).productName}
                                                        {JSON.parse(localStorage.getItem('login')) === null ? " registration " : " subscription "} successfully .
                                                  </h3> */}
                          {/* {JSON.parse(localStorage.getItem('login')) === null ?
                                                        <p>Credentials to access will be shared shortly over your registered email id.</p> : null} */}
                          <h3>
                            {/* Congratulations! Your payment is successfully . */}
                            Congratulations! Your payment was successful. Kindly
                            navigate to "My Learning" on your dashboard to
                            access the course.
                          </h3>
                          <h3>Order ID : {props.orderID}</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-10 col-sm-10 col-xs-12 mt-50">
                            <button
                              type="submit"
                              onClick={handleBack}
                              className="btn-4 pull-right"
                              name="Submit"
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
      {/* </Paper> */}
      <Finxfooter />
      {/* </main> */}
    </React.Fragment>
  );
};

export default class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
      },
      AHCredentials: "",
      laoding: false,
      flag: false,
      orderID: null,
    };
    //this.dataSource = new RestDataSource("http://192.168.10.103:8080/finx-usermanagment/student/");
  }
  componentWillMount() {
    this.setState((state) => (state.loading = true));
    // var AHobj = JSON.parse(localStorage.getItem("PaymentDet"));
    var AHobj = JSON.parse(localStorage.getItem("PaymentDet1"));

    var orderID = localStorage.getItem("order");

    this.setState({ orderID });
    // debugger;
    //this puts cousres in following order - CFX,Feat, NISM VA;
    AHobj.unshift(AHobj[AHobj.length - 1]);
    AHobj.pop();
    // console.log("OBJECT::::::", typeof AHobj);
    // console.log("OBJECT::::::", AHobj);
    // RegisterUserService.AHRegisterUser(AHobj, res => {
    //     if (res.status === 'success') {
    //         this.setState(
    //             state =>
    //                 (state.loading = false)
    //         );
    //     } else {
    //         this.setState(
    //             state =>
    //                 (state.loading = false)
    //         ); error(res.reasonText, errorNotification);
    //     }
    // }, error => {
    //     console.log(error);
    // })

    RegisterUserService.AHRegisterUserBulkNew(
      AHobj,
      (res) => {
        if (res.status === "success") {
          this.setState((state) => (state.loading = false));
        } else {
          this.setState((state) => (state.loading = false));
          error(res.reasonText, errorNotification);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  render() {
    return (
      <React.Fragment>
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        <React.Fragment>
          <Student
            key={this.state.student.id}
            name={this.props.name}
            id={this.props.id}
            {...this.props}
            {...this.state}
          />
        </React.Fragment>
      </React.Fragment>
    );
  }
}
