/* global google */
  // Library Imports
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import CryptoJS from 'crypto-js';
import crypto from 'crypto-browserify';
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import $ from "jquery";
// Service Imports
import { RegisterUserService } from "../../../services/registration.service";
import { MobileValidationService } from "../../../services/mobile.validation.service";
import { PopUpPortal } from "../../../containers/popups/PopUpPortal";
import { LoginService } from "../../../services/login.service";
import { OrderService } from "../../../services/order.service";
import { FeedbackService } from "../../../services/feedbackservice.service";
// Other Imports
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../../notification/notifications";
import { Global_var } from "../../../global/global_var";
import { getRoleID } from "../../../components/getRoleId";
// Asset Imports
// const TC = require('../../../assets/pdf/Terms and Conditions.pdf');
const TC = require('../../../assets/pdf/TermsandConditions.pdf');
const featmodal = require('../../../assets/images/feat-modal.png');
const FaceBookLogo = require('../../../assets/images/facebook-login.svg');

//! Unused Imports
// import TC from "../../../assets/pdf/Terms and Conditions.pdf";
// import featmodal from "../../../assets/images/feat-modal.png";
// import FaceBookLogo from "../../../assets/images/facebook-login.svg"
// import { commonService } from "../../../services/common.service";
// import { ProductService } from "../../../services/product.service";
// import TextField from "@material-ui/core/TextField";
// import TC from "../../../assets/pdf/T-C_Finx.pdf";

class finxregistration extends React.Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();

    this.state = {
      countryCode: "91",
      mobileNumber: "",
      OTP: "",
      firstName: "",
      lastName: "",
      instituteName: "",
      educationQualification: "",
      emailId: "",
      passedOutYear: "",
      listOfYear: [],
      pursuingYear: "",
      dateOfBirth: "",
      today: moment(currentDate).format("YYYY-MM-DD"),
      popupVisibility: false,
      productId: "",
      productdetails: [],
      checkout: [],
      showGenerateOtp: false,
      showResendOtp: false,
      MobileVerified: false,
      isMobileUnique: false,
      showOTPBox: false,
      showTimer: false,
      isOTPGenerated: false,
      loading: false,
      loading1: "none",
      flag: "false",
      isPaymentPending: false,
      isRegistered: false,
      lookingFor: [],
      lookingForisReg: "",
      // instituteName: [],
      instituteNameisReg: "",
      purpose1: "",
      lookingFor1: "",
      instituteName1: "",
      user_obj: [],
      OTPTokenCount: 0,
      time: {},
      seconds: 180,
      disableopt: false,
      disabledbtn: true,
      listOfMonth: [],
      listOfYear1: [],
      listOfDay: [],
      roleId: getRoleID() ,
      // ! Register Tab
      isRegisterTab: true,
      // ! Login Form
      userLoginName: "", // LOGIN FORM STATES
      userPassword: "",
      userMobileNo: "",
      companyid: "",
      isMobile: false,
      isEmail: false,
      isPasswordfocused: false,
      isLogined: false,
      isMaintainenceActivated: false,
      warning: false,
      // pathname: this.props.location?.state?.pathname,
      // searchParams: this.props.location?.state?.searchParams,
      pathname: this.props.location?.state?.pathname ? this.props.location?.state?.pathname : this.props.location?.pathname,
      searchParams: this.props.location?.state?.searchParams ? this.props.location?.state?.searchParams : this.props.location?.search,
      paramsObj:{},
      decryptedSourceUrl: localStorage.getItem("decryptedSourceUrl"),
      encryptedUrl: localStorage.getItem("encryptedUrl"),
      whatsapp: true,
      isTermsAccepted: false,
      isParamsEncryptrd: false,
      emailid: "",
      partyid : "",
      rank:0,
      feedbackMsg:"",
      feedbackBtnDisabled: false,
      typeOfConcernList1:[  {
        concernID: 1,
        concernName: "Unable to generate OTP"
        },
        {
            concernID: 2,
            concernName: "Site is very slow"
        },
        {
            concernID: 3,
            concernName: "Site is not User Friendly"
        },
        {
            concernID: 4,
            concernName: "Unable to make the payment"
        },
        {
            concernID: 5,
            concernName: "Could not find the Right Job"
        },
        {
            concernID: 6,
            concernName: "Unable to Apply for Jobs"
        },
        {
            concernID: 7,
            concernName: "Could not find the Right Course"
        },
        {
          concernID: 8,
          concernName: "Others"
      }
      ],
    };

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  MobileNoValidation = (value) => {
    this.setState({
      showGenerateOtp: true,
      mobileNumber: value,
    });
  };
  isEncrypted = (data) => {
    // Check if the data is base64 encoded
    return /^[a-zA-Z0-9+/]+[=]{0,2}$/.test(data);
  };
  decryptData = (encryptedString) => {
    const decryptionKey = 'YourSecretKey';
    const decipher = crypto.createDecipher('aes-256-cbc', decryptionKey);
    let decryptedString = decipher.update(encryptedString, 'hex', 'utf-8');
    decryptedString += decipher.final('utf-8');
    return decryptedString;
  };
  decryptPassword = (encryptedPassword) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedPassword, 'secretKey').toString(CryptoJS.enc.Utf8);
    return decrypted;
  };
  componentDidMount() {

    let showFeedback = localStorage.getItem("showFeedback");
    if (["true",true].includes(showFeedback)) {
        window.$('#feat-Reg').modal('hide')
        window.$('#feedback-modal').modal('show')
        localStorage.setItem("showFeedback",false)
    }else{
      window.$('#feat-Reg').modal('show')
      window.$("#feedback-modal").modal("hide");
    }
    
    var header1 = document.getElementById("myDIV");
      var btns1 = header1.getElementsByClassName("emoji");

      for (var i = 0; i < btns1.length; i++) {
        btns1[i].addEventListener("click", function() {
          var current = header1.getElementsByClassName("active");
          
          for (var j = 0; j < current.length; j++) {
            current[j].className = current[j].className.replace(" active", "");
          }
          
          this.className += " active";
        });
      }
      if(this.props?.history?.location?.state !== undefined){
        this.setState({
            email: this.props?.history?.location?.state?.email === undefined ? ""  : this.props.history.location.state.email,
            partyid: this.props?.history?.location?.state?.partyid === undefined ? "":this.props.history.location.state.partyid
        })
    }
    //! url params from kotak starts
    // let paramsCheck = this.state.searchParams?this.state.searchParams.slice(0,4):"";
    let s11,s2;
    let s1 = this.state.searchParams?this.state.searchParams.split("?"):""
    let encryptedString = s1 && s1[1];
    let isDataEncrypted;
    // const isEncrypted = encryptedString && encryptedString.endsWith('=');
    // const isEncrypted1 = encryptedString && encryptedString.length % 16 === 0; // Check if length is a multiple of 16 (for AES encryption)
    // this.setState({ isParamsEncryptrd : isEncrypted || isEncrypted1})
    // if(paramsCheck === "?cli"){
    //   s2 = s1 && s1[1].split("&");
    // }else if(isEncrypted || isEncrypted1){
    //   s11 = this.decryptPassword(s1 && s1[1]);
    //   s2 = s11 && s11.split("&")
    // }
    if(encryptedString){
      isDataEncrypted = this.isEncrypted(encryptedString);
    }
    const urlParams = new URLSearchParams(encryptedString);
    const fields = ['cli', 'col', 'pro', 'loc', 'ref', 'source', 'medium', 'term', 'camp', 'protype', 'proId'];
    const anyFieldFound = fields.some(field => urlParams.has(field));
    // if(paramsCheck === "?cli"){
    if(anyFieldFound) {
      s2 = encryptedString.includes("&") ? encryptedString.split("&") : [encryptedString];
    }else if(isDataEncrypted){
      s11 = this.decryptData(encryptedString);
      s2 = s11 && s11.split("&")
    }
    console.log('searchParams',s1);
    // let s2 = s1 && s1[1].split("&")
    console.log('searchParams1',s2);
    let s3 = {}
    let obj = {};
    for(let i=0;i<s2?.length;i++){      
      s3 =s2[i].split("=")
      console.log('sss3',s3);
      obj[s3[0]] = s3[1] ? s3[1] : null
    }    
    console.log('obj',obj);
    this.setState({ paramsObj: obj });
    console.log('paramsObj',this.state.paramsObj);
    //! url params from kotak ends
    // ! If Route === /login OR /register the open the register modal
    if (this.props.throughRegister || this.props.throughLogin) {
      window.$("#feat-Reg").modal("show");
    }
    // ! Handling Active Form Tab
    if (this.props.throughRegister && !this.props.throughLogin) {
      this.setState({ isRegisterTab: true });
    }
    if (!this.props.throughRegister && this.props.throughLogin) {
      this.setState({ isRegisterTab: false });
    }

    //year dropdown bind start  here
    var year = new Date().getFullYear();
    var birthYear = year - 18;
    var listOfYear = [];

    for (var j = 0; j < 100; j++) {
      var current = year - j;
      listOfYear.push(current);
    }

    var listOfBirthYear = [];
    for (var bi = 0; bi < 100; bi++) {
      var birthcurrent = birthYear - bi;
      listOfBirthYear.push(birthcurrent);
    }

    var monthlist = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    var dayList = [];
    for (var ld = 1; ld <= 31; ld++) {
      //    var current = year - i;
      dayList.push(ld);
    }

    this.setState({ listOfYear: listOfYear }); //.sort()
    this.setState({ listOfYear1: listOfBirthYear }); //.sort()
    this.setState({ listOfMonth: monthlist }); //.sort()
    this.setState({ listOfDay: dayList });

   //!OLD
    // LoginService.TokenUser(
    //   (res) => {
    //     localStorage.setItem("jti-token", res.responseObject);
    //     if (res.status === "success") {
    //       localStorage.setItem("TokenName", "jwt");
    //       let productName = "productName=FEAT (Including CFX Registration)";
    //       ProductService.ProductByName(productName, (res) => {
    //         if (res.status === "success") {
    //           if (res.reasonCode !== "SM-20") {
    //             this.setState({ productdetails: res.responseObject });
    //             LoginService.TokenUser((res) => {
    //               if (res.status === "success") {
    //                 localStorage.setItem("jwt-token", res.responseObject);
    //                 //Institute Details
    //                 commonService.fetchInstitute(
    //                   (res) => {
    //                     this.setState({
    //                       instituteName: res.responseListObject,
    //                     });
    //                   },
    //                   (err) => {
    //                     console.log(err);
    //                     error(
    //                       "Sorry for the Inconvenience! Error has been raised",
    //                       errorNotification
    //                     );
    //                   }
    //                 );

    //                 // Looking For
    //                 commonService.fetchLookingFor(
    //                   (res) => {
    //                     this.setState({
    //                       lookingFor: res.responseListObject,
    //                     });
    //                   },
    //                   (err) => {
    //                     console.log(err);
    //                     error(
    //                       "Sorry for the Inconvenience! Error has been raised",
    //                       errorNotification
    //                     );
    //                   }
    //                 );

    //                 // Purpose
    //                 commonService.fetchPurpose(
    //                   (res) => {
    //                     this.setState({
    //                       purpose: res.responseListObject,
    //                     });
    //                   },
    //                   (err) => {
    //                     console.log(err);
    //                     error(
    //                       "Sorry for the Inconvenience! Error has been raised",
    //                       errorNotification
    //                     );
    //                   }
    //                 );
    //               }
    //             });
    //           } else {
    //             warning(
    //               "Product Details " + res.reasonText,
    //               warningNotification
    //             );
    //           }
    //         } else if (res.status === "fail") {
    //           error(
    //             "Sorry for the Inconvenience! Error has been raised",
    //             errorNotification
    //           );
    //         }
    //       });
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //     error(
    //       "Sorry for the Inconvenience! Error has been raised",
    //       errorNotification
    //     );
    //   }
    // );
// Retrieve the stored values from localStorage
if(Global_var.SECURITY_CHANGE==="true"){
const leadSourceUrl = localStorage.getItem("leadSourceUrl");
const decryptedSourceUrl = localStorage.getItem("decryptedSourceUrl");
const encryptedUrl = localStorage.getItem("encryptedUrl");
const leadObj = localStorage.getItem("leadObj");
// Log the retrieved values (optional, to check the values)
console.log("vamsi leadSourceUrl: before", leadSourceUrl);
console.log("vamsi decryptedSourceUrl: before", decryptedSourceUrl);
console.log("vamsi encryptedUrl: before", encryptedUrl);
console.log("vamsi leadObj: before", leadObj);
// alert("before"+JSON.stringify(localStorage))
localStorage.clear()
// alert("after"+JSON.stringify(localStorage))
// Reset the values back to localStorage
localStorage.setItem("leadSourceUrl", leadSourceUrl);
localStorage.setItem("decryptedSourceUrl", decryptedSourceUrl);
localStorage.setItem("encryptedUrl", encryptedUrl);
localStorage.setItem("leadObj", leadObj);
// alert("after 1"+JSON.stringify(localStorage))

const leadSourceUrl1 = localStorage.getItem("leadSourceUrl");
const decryptedSourceUrl1 = localStorage.getItem("decryptedSourceUrl");
const encryptedUrl1 = localStorage.getItem("encryptedUrl");
const leadObj1 = localStorage.getItem("leadObj");

console.log("vamsi leadSourceUrl: after", leadSourceUrl1);
console.log("vamsi decryptedSourceUrl: after", decryptedSourceUrl1);
console.log("vamsi encryptedUrl: after", encryptedUrl1);
console.log("vamsi leadObj: after", leadObj1);
}
 //!NEW
    LoginService.TokenUser(
      (res) => {
        localStorage.setItem("jti-token", res.responseObject);
        localStorage.setItem("jwt-token", res.responseObject);
        if (res.status === "success") {
          localStorage.setItem("TokenName", "jwt");

                LoginService.TokenUser((res) => {
                  if (res.status === "success") {
                    localStorage.setItem("jti-token", res.responseObject);
                    localStorage.setItem("jwt-token", res.responseObject);
            } else if (res.status === "fail") {
              error(
                "Sorry for the Inconvenience! Error has been raised",
                errorNotification
              );
            }
          });
        }
      },
      (err) => {
        console.log(err);
        error(
          "Sorry for the Inconvenience! Error has been raised",
          errorNotification
        );
      }
    );

    $(document).ready(function () {
      $("#educationQualification").change(function () {
        if (this.value === 0) {
          localStorage.setItem("flag", "true");
        } else {
          localStorage.setItem("flag", "false");
        }
      });
    });

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    // ! From Login : featheader.js
    $(window).blur(function () {
      window.addEventListener("storage", (e) => {
        if (
          localStorage.getItem("userid-Token") === null &&
          localStorage.getItem("login") === null
        ) {
          if (localStorage.getItem("windowloggedout") === false) {
            //localStorage.removeItem("windowloggedout");
            //window.location.href = '/';
          } else {
            window.location.href = "/";
          }
        }
        e.currentTarget.removeEventListener("storage", e);
      });
    });
    if (
      localStorage.getItem("userid-Token") !== null &&
      localStorage.getItem("login") !== null
    ) {
      //alert("checktab" + localStorage.getItem('checkedtab'))
      if ([5,"5"].includes(this.state.roleId)) {
        //alert("checktab 2" + localStorage.getItem('checkedtab'))
        //window.location.href = '/studentDashboard';
        if (localStorage.getItem("checkedtab") === "false") {
          //alert("checktab 3" + localStorage.getItem('checkedtab'))
          localStorage.setItem("checkedtab", true);
          window.location.href = "/studentDashboard";
        }
      } else if ([6,"6"].includes(this.state.roleId)) {
        if (localStorage.getItem("checkedtab") === "false") {
          localStorage.setItem("checkedtab", true);
          window.location.href = "/ops-dashboard";
        }
      } else {
        //window.location.href = '/admin-dashboard';
        //alert("checktab 4" + localStorage.getItem('checkedtab'))
        if (localStorage.getItem("checkedtab") === "false") {
          localStorage.setItem("checkedtab", true);
          window.location.href = "/admin-dashboard";
        }
      }
    } else {
      if (localStorage.getItem("checkedtab") === "false") {
        localStorage.setItem("checkedtab", true);
        window.location.href = "/";
      }
    }
    // Check session timeout URL
    // const queryString = window.location.search;
    $("#return-to-top").click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        500
      );
    });
    // ! Google Btn Render

    setTimeout(() => {
      window.google.accounts.id.initialize({
        // ! Google Client ID
        client_id: Global_var.GOOGLE_CLIENT,
        callback: this.signInWithGoogle,
      });
      let btnStyle = {
        type: "standard",
        shape: "rectangular",
        theme: "outline",
        text: "continue_with",
        size: "large",
        logo_alignment: "left",
        width: "250",
      };
      // ! Register Btn
      window.google.accounts.id.renderButton(
        document.getElementById("google-auth-btn-register"),
        btnStyle
      );
      // ! Login Btn
      window.google.accounts.id.renderButton(
        document.getElementById("google-auth-btn-login"),
        btnStyle
      );
    }, 500);
    // ! End Google Btn Render
    document.querySelector("#loader").style.display = "none";
  }
  // ! Handle Social Login / Register
  HandleSocailRegisterLogin = (user) => {
    // ! Login Obj
    const LoginDetails = {
      userLoginName: user.emailID,
      emailId: user.emailID,
      googleId: user.googleId,
      facebookId: user.facebookId,
      sendWhatsapp:user.sendWhatsapp,
      leadRegister: 
      {
        client: user.leadRegister.client ? user.leadRegister.client : null,
        college: user.leadRegister.college ? user.leadRegister.college : null,
        product: user.leadRegister.product ? user.leadRegister.product : null,
        location: user.leadRegister.location ? user.leadRegister.location : null,
        reference: user.leadRegister.reference ? user.leadRegister.reference : null,
        source: user.leadRegister.source ? user.leadRegister.source : null,
        medium: user.leadRegister.medium ? user.leadRegister.medium : null,
        term: user.leadRegister.term ? user.leadRegister.term : null,
        camp: user.leadRegister.camp ? user.leadRegister.camp : null,
        sourceUrl: user.leadRegister.sourceUrl ? user.leadRegister.sourceUrl : null,
        productType: user.leadRegister.productType ? user.leadRegister.productType : null,
        productID: user.leadRegister.productID ? user.leadRegister.productID : null,
        encryptedSourceUrl: user.leadRegister.encryptedSourceUrl ? user.leadRegister.encryptedSourceUrl : null,
      },
    };
    console.log('LoginDetails',LoginDetails);
    // ? If User Exist
    LoginService.isUserExist("emailId=" + user.emailID, (response) => {
      // ! Fail Means user Exist
      if (response.status === "fail") {
        // ! User Exist
        const socialUser = {
          userLoginName: user.emailID,
          googleId: user.googleId,
          facebookId: user.facebookId,
        };
        // ! Update Social ID
        LoginService.updateUserSocialDetails(socialUser, (res) => {
          if (res.status === "success") {
            // ! TODO Login
            var logincon;

            this.setState({ loading: true });
            this.setState({ loading1: "block" });

            // ! Login Handle Logic
            LoginService.sendUserSocial(LoginDetails, (res) => {
              if (res.status === "success") {
                logincon = res;
                this.setState({ credentials: logincon });
                localStorage.setItem("eMail", logincon.responseObject.emailId);
                localStorage.setItem(
                  "userid-Token",
                  logincon.responseObject.partyId
                );
                localStorage.setItem("userid", logincon.responseObject.userId);
                localStorage.setItem(
                  "roleId",
                  logincon.responseObject.role[0].roleId
                );
                localStorage.setItem(
                  "roleName",
                  logincon.responseObject.role[0].roleName
                );
                localStorage.setItem("checkedtab", false);
                localStorage.setItem("TokenName", "jti");
                localStorage.setItem("jwt-token",logincon.responseObject.jwttoken)
                localStorage.setItem('jti-token',logincon.responseObject.jwttoken)
                LoginService.TokenUser((res) => {
                  this.setState((state) => {
                    let oldstate = Object.assign({}, state);
                    oldstate.credentials.responseObject.token =
                      res.responseObject;
                    this.state = oldstate; // setState({...oldstate})
                    localStorage.setItem("jti-token", res.responseObject);
                    localStorage.setItem(
                      "login",
                      JSON.stringify(oldstate.credentials.responseObject)
                    );
                    localStorage.setItem(
                      "login1",
                      JSON.stringify(oldstate.credentials.responseObject)
                    );
                  });
                }).then(()=>{
                localStorage.setItem("TokenName", "jwt");
                LoginService.TokenUser((res) => {
                  localStorage.setItem("jwt-token", res.responseObject);
                  this.setState({ loading: false });

                  if (
                    this.state.credentials.responseObject[
                      "forcePasswordChangeOnNextLoginFlag"
                    ] === "1"
                  ) {
                    localStorage.setItem("reload", "true");
                    this.props.history.push("/changepassword");
                  } else {
                    if (
                      this.state.credentials.responseObject.role[0].roleId === 5
                    ) {
                      //! Lead Register Storing Starts
                      // let leadRegDataObj =
                      // {
                      //   partyID: res.responseObject.studentPartyID ? res.responseObject.studentPartyID : null,
                      //   client: user.leadRegister.client ? user.leadRegister.client : null,
                      //   college: user.leadRegister.college ? user.leadRegister.college : null,
                      //   product: user.leadRegister.product ? user.leadRegister.product : null,
                      //   location: user.leadRegister.location ? user.leadRegister.location : null,
                      //   reference: user.leadRegister.reference ? user.leadRegister.reference : null,
                      //   source: user.leadRegister.source ? user.leadRegister.source : null,
                      //   medium: user.leadRegister.medium ? user.leadRegister.medium : null,
                      //   term: user.leadRegister.term ? user.leadRegister.term : null,
                      //   camp: user.leadRegister.camp ? user.leadRegister.camp : null,
                      //   sourceUrl: user.leadRegister.sourceUrl ? user.leadRegister.sourceUrl : null,
                      //   productType: user.leadRegister.productType ? user.leadRegister.productType : null,
                      // }
                      // this.state.isParamsEncryptrd && RegisterUserService.LeadRegisterClickSave(leadRegDataObj, (res) => {
                      //   if (res.status === "success") {
                      //     this.setState({ loading: false });
                      //   } else {
                      //     this.setState({ loading: false });
                      //     warning(res.reasonText, warningNotification);
                      //   }
                      // },
                      // (err) => console.log("Error", err)
                      // );
                      if(logincon.responseObject?.emailId){
                        sessionStorage.setItem("eMail",logincon.responseObject.emailId)
                      }
                      //! Lead Register Storing Ends
                      $(".modal-backdrop").hide();
                      this.setState({ loading: true });
                      setTimeout(() => {  
                      this.setState({ loading: false });
                      this.props.history.push("/studentDashboard");
                      localStorage.setItem("reload", "true");
                      }, 3000);
                    } else if (
                      this.state.credentials.responseObject.role[0].roleId === 6
                    ) {
                      if (localStorage.getItem("checkedtab") === "false") {
                        localStorage.setItem("checkedtab", true);
                        window.location.href = "/ops-dashboard";
                      }
                    } else {
                      $(".modal-backdrop").hide();
                      this.setState({ loading: true });
                      setTimeout(() => {  
                      this.setState({ loading: false });
                      this.props.history.push("/admin-dashboard");
                      localStorage.setItem("reload", "true");
                      }, 3000);
                    }
                  }
                });
              });
              }
            });
            // ! End of Login Handle Logic
          } else {
            // ! TODO : Error Updating
            console.error("Error");
            error(res.reasonText, errorNotification);
            this.setState({ loading: false });
            this.setState({ loading1: "none" });
          }
        });
      } else {
        // ! User Not Exist
        // ! Register
        RegisterUserService.RegisterSocialUser(
          user,
          (res) => {
            if (res.status === "success") {
              // var personaldetails = {
              //   partyId: res.responseObject.studentPartyID,
              //   mobileNo: res.responseObject.mobileNo,
              //   firstName: res.responseObject.firstName,
              //   lastName: res.responseObject.lastName,
              //   emailID: res.responseObject.emailID,
              //   entryMode: res.responseObject.entryMode
              // };

              // var logincon;
              //! Lead Register Storing Starts
              // let leadRegDataObj =
              // {
              //   partyID: res.responseObject.studentPartyID ? res.responseObject.studentPartyID : null,
              //   client: user.leadRegister.client ? user.leadRegister.client : null,
              //   college: user.leadRegister.college ? user.leadRegister.college : null,
              //   product: user.leadRegister.product ? user.leadRegister.product : null,
              //   location: user.leadRegister.location ? user.leadRegister.location : null,
              //   reference: user.leadRegister.reference ? user.leadRegister.reference : null,
              //   source: user.leadRegister.source ? user.leadRegister.source : null,
              //   medium: user.leadRegister.medium ? user.leadRegister.medium : null,
              //   term: user.leadRegister.term ? user.leadRegister.term : null,
              //   camp: user.leadRegister.camp ? user.leadRegister.camp : null,
              //   sourceUrl: user.leadRegister.sourceUrl ? user.leadRegister.sourceUrl : null,
              //   productType: user.leadRegister.productType ? user.leadRegister.productType : null,
              // }
              // this.state.isParamsEncryptrd && RegisterUserService.LeadRegisterClickSave(leadRegDataObj, (res) => {
              //   if (res.status === "success") {
              //     this.setState({ loading: false });
              //   } else {
              //     this.setState({ loading: false });
              //     warning(res.reasonText, warningNotification);
              //   }
              // },
              // (err) => console.log("Error", err)
              // );
              //! Lead Register Storing Ends
              this.setState({ loading: true });
              this.setState({ loading1: "block" });

              // ! Login Handle Logic
              // localStorage.setItem("loginData", JSON.stringify(LoginDetails))
              // localStorage.setItem("loginFrom", "Social")
              // setTimeout(() => {
              //   window.location.replace('/registerSuccess');
              // }, 1500);
             if(res.responseObject?.emailId){
                sessionStorage.setItem("eMail",res.responseObject.emailId)
              }
              localStorage.setItem(
                "loginData",
                JSON.stringify(res.responseObject)
              );
              localStorage.setItem("loginFrom", "Social");
              setTimeout(() => {
                window.location.replace("/registerSuccess");
              }, 1500);
              localStorage.setItem("leadSourceUrl", "");
              localStorage.setItem("decryptedSourceUrl", "");
              localStorage.setItem("encryptedUrl", "");
              // ! End of Login Handle Logic
            } else {
              error(res.reasonText, errorNotification);
              this.setState({ loading: false });
              this.setState({ loading1: "none" });
            }
          },
          (error) => {
            console.error(error);
            this.setState({ loading: false });
            this.setState({ loading1: "none" });
          }
        );
      }
    });
  };
  // ! END Handle Social Login / Register

  // ! Google Login / Register Handler
  signInWithGoogle = (response) => {
    if(this.state.isRegisterTab === true && this.state.isTermsAccepted === false){
        warning("Please select Terms and Conditions",warningNotification);
        return;
      }
    // ? Decode for Data
    const decodedData = jwt_decode(response.credential);
    // ? FirstName & lastName from FullName
    const [firstName, ...lastName] = decodedData.name.split(" ");
    // ? Preparing User Object
    const user = {
      googleId: decodedData.sub,
      emailID: decodedData.email,
      firstName: firstName,
      lastName: lastName.join(" "),
      entryMode: 4,
      // isTermsAccepted: 1,
      isTermsAccepted: this.state.isTermsAccepted === true ? 1 : this.state.isTermsAccepted === false ? 0 : 0,
      sendWhatsapp: this.state.whatsapp === true ? "Y" :this.state.whatsapp === false ?"N" :"N",
      leadRegister:
      {
        sourceUrl: this.state.encryptedUrl === "true" 
        ? (this.state.decryptedSourceUrl ? this.state.decryptedSourceUrl : null) 
        : this.state.encryptedUrl === "false"
        ? ((this.state.pathname+this.state.searchParams) ? (this.state.pathname+this.state.searchParams) : null )
        : null,
        encryptedSourceUrl: this.state.encryptedUrl === "true" ? ((this.state.pathname+this.state.searchParams) ? (this.state.pathname+this.state.searchParams) : null ) : null,
        productID: this.state.paramsObj.proId ? this.state.paramsObj.proId : null,
        client:this.state.paramsObj.cli ? this.state.paramsObj.cli : null,
        college:this.state.paramsObj.col ? this.state.paramsObj.col : null,
        product:this.state.paramsObj.pro ? this.state.paramsObj.pro : null,
        location:this.state.paramsObj.loc ? this.state.paramsObj.loc : null,
        reference:this.state.paramsObj.ref ? this.state.paramsObj.ref : null,
        source:this.state.paramsObj.source ? this.state.paramsObj.source : null,
        medium:this.state.paramsObj.medium ? this.state.paramsObj.medium : null,
        term:this.state.paramsObj.term ? this.state.paramsObj.term : null,
        camp:this.state.paramsObj.camp ? this.state.paramsObj.camp : null,
        productType: this.state.paramsObj.protype ? this.state.paramsObj.protype : null,
      }
    };
    console.log('googleuser',user);
    this.HandleSocailRegisterLogin(user);
  };

  // ! END Google Login / Register Handler

  // ! Facebook Login

  signInWithFacebook = () => {
    if(this.state.isRegisterTab && this.state.isTermsAccepted === false){
      warning("Please select Terms and Conditions",warningNotification);
      return;
    }
    const HandleSocailRegisterLogin = this.HandleSocailRegisterLogin;

    window.FB.init({
      // ! Facebook Client ID
      appId: Global_var.FACEBOOK_CLIENT,
      cookie: false,
      xfbml: true,
      version: "v16.0",
    });

    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          window.FB.api("/me?fields=id,name,email", function (response) {
            setTimeout(()=>{            
            const [firstName, ...lastName] = response.name.split(" ");
            // ? Preparing User Object
            const user = {
              facebookId: response.id,
              emailID: response.email,
              firstName: firstName,
              lastName: lastName.join(" "),
              entryMode: 4,
              // isTermsAccepted: 1,
              isTermsAccepted: this.state.isTermsAccepted === true ? 1 : this.state.isTermsAccepted === false ? 0 : 0,
              sendWhatsapp: this.state.whatsapp === true ? "Y" :this.state.whatsapp === false ?"N" :"N",
              leadRegister:
              {
                sourceUrl: this.state.encryptedUrl === "true" 
                ? (this.state.decryptedSourceUrl ? this.state.decryptedSourceUrl : null) 
                : this.state.encryptedUrl === "false"
                ? ((this.state.pathname+this.state.searchParams) ? (this.state.pathname+this.state.searchParams) : null )
                : null,
                encryptedSourceUrl: this.state.encryptedUrl === "true" ? ((this.state.pathname+this.state.searchParams) ? (this.state.pathname+this.state.searchParams) : null ) : null,
                productID: this.state.paramsObj.proId ? this.state.paramsObj.proId : null,
                client:this.state.paramsObj.cli ? this.state.paramsObj.cli : null,
                college:this.state.paramsObj.col ? this.state.paramsObj.col : null,
                product:this.state.paramsObj.pro ? this.state.paramsObj.pro : null,
                location:this.state.paramsObj.loc ? this.state.paramsObj.loc : null,
                reference:this.state.paramsObj.ref ? this.state.paramsObj.ref : null,
                source:this.state.paramsObj.source ? this.state.paramsObj.source : null,
                medium:this.state.paramsObj.medium ? this.state.paramsObj.medium : null,
                term:this.state.paramsObj.term ? this.state.paramsObj.term : null,
                camp:this.state.paramsObj.camp ? this.state.paramsObj.camp : null,
                productType: this.state.paramsObj.protype ? this.state.paramsObj.protype : null,
              }
            };
            console.log('fbuser',user);            
            HandleSocailRegisterLogin(user);
          },1500)
          });
        }
      },
      { scope: "public_profile,email" }
    );
  };

  // ! End Facebook Login

  startTimer() {
    this.setState({loading:true})
    // $(".notification-container-top-center").hide();
    if (this.state.disableopt === false) {
      this.handleGenerateOtp();
      this.setState({ time: {}, seconds: 180, disableopt: true });
    } else {
      this.setState({ time: {}, seconds: 180, disableopt: false });
      console.log("click multiple times");
      this.setState({loading:false})
    }

    if (this.timer === 0 && this.state.seconds === 180) {
      this.timer = setInterval(this.countDown, 1000);
    } else {
      // if (this.state.seconds !== 0) {
      //   clearInterval(this.timer);
      // } else {
      clearInterval(this.timer);
      this.timer = setInterval(this.countDown, 1000);
      //}
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;
    this.setState({ time: this.secondsToTime(seconds), seconds: seconds });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      // this.setState({ disableopt : false })
    }
    if (seconds === 5) {
      this.setState({ showResendOtp: true });
    }
  }

  isNumber(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      return true;
    }
  }
  showPopup = () => {
    this.setState({ popupVisibility: true });
  };

  hidePopup = () => {
    this.setState({ popupVisibility: false });
  };

  validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  GenerateOTP = () => {
    this.setState({ isMobileUnique: true, isOTPGenerated: true });

    if (this.state.isMobileUnique) {
      MobileValidationService.MobileOTP(
        this.state.mobileNumber,
        this.state.countryCode,
        (res) => {
          if (res.status === "success") {
            success(
              "OTP will be generated in a few seconds...",
              successNotification
            );
            this.setState({loading:false})
          }else{
            this.setState({loading:false})
          }
          return true;
        }
      );
      this.setState({
        showGenerateOtp: false,
        showResendOtp: false,
        showOTPBox: true,
        showTimer: true,
      });
    }
  };

  handleGenerateOtp = () => {
    let mobile = this.state.mobileNumber;
   let productID=this.state.productdetails?.productID?this.state.productdetails?.productID:"";
    if (mobile.toString().length === 10) {
      mobile =
        "mobileNo=" +
        mobile +
        "&productID=" +
        productID;
      MobileValidationService.MobileValidation(
        mobile,
        (res) => {
          if (res.status === "success") {
            if (res.reasonCode === "FC-01") {
              this.GenerateOTP();
            } else if (res.reasonCode === "FC-02") {
              // warning('Mobile number already registered.Please use to log into FinX Core platform to proceed. Incase of login troubles, please reach us at support@finxpert.org or 9999999999.', warningNotification);
              warning(res.reasonText, {
                ...warningNotification,
                container: "top-center",
              });
              this.setState({loading:false})
            } else if (res.reasonCode === "FC-03") {
              warning(
                "Mobile number has already been registered. Please log into the platform to proceed. Incase of login troubles, please reach out to us at support@finxpert.org",
                {
                  ...warningNotification,
                  dismiss: {
                    duration: 0,
                    onScreen: false,
                    showIcon: true,
                  },
                }
              );
              this.setState({
                isPaymentPending: false,
                isRegistered: true,
                user_obj: res.responseObject,
                MobileVerified: false,
              });
              setTimeout(() => {
                this.setState({ disableopt: false, isRegistered: false });
              }, 5000);
              // setTimeout(() => {
              //   this.GenerateOTP();
              // }, 5000);
              this.setState({loading:false})
            } else if (res.reasonCode === "FC-04") {
              warning(
                "Mobile number has already been registered. Please log into the FinX Core platform to proceed. Incase of login troubles, please reach out to us at support@finxpert.org",
                {
                  ...warningNotification,
                  dismiss: {
                    duration: 0,
                    onScreen: false,
                    showIcon: true,
                  },
                }
              );
              this.setState({
                isPaymentPending: true,
                isRegistered: true,
                user_obj: res.responseObject,
              });
              // this.GenerateOTP();
              this.setState({loading:false})
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  handleValidateOtp = (e) => {
    let mobile = this.state.mobileNumber;
    let countrycd = this.state.countryCode;
    let validateOtp = e.target.value;
    if (validateOtp !== "" && !this.state.MobileVerified) {
      MobileValidationService.MobileOTPValidation(
        mobile,
        countrycd,
        validateOtp,
        (res) => {
          //console.log(res.status);
          if (res.status === "success") {
            this.setState({ time: {}, seconds: 0, showTimer: false });
            // success("Mobile Number Verified successfully", successNotification);

            this.setState({
              showGenerateOtp: false,
              showResendOtp: false,
              MobileVerified: true,
              showOTPBox: true,
            });

            if (this.state.isRegistered) {
              // let Trd = moment(this.state.user_obj.dob, "DD/MM/YYYY");
              success(
                "You are already registered with FINX. To start BFSI journey, please login to FINX.",
                warningNotification
              );

              $("#feat-Reg").modal("hide");
            }
            if (this.state.educationQualification === "under Graduate") {
              localStorage.setItem("flag", "true");
            } else {
              localStorage.setItem("flag", "false");
            }
          } else {
            this.setState({ loading: false });

            if (res.reasonCode === "DM_02") {
              let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
              this.setState({ OTPTokenCount: otpcounter });
              if (this.state.OTPTokenCount >= 4) {
                //warning("Click on Resend OTP", warningNotification);
                this.setState({
                  loading: false,
                  isLogined: false,
                  showTimer: false,
                  showResendOtp: false,
                  OTP: "",
                });
                // warning(
                //   "You have made 3 unsuccessful attempts to Login to FinX.",
                //   warningNotification
                // );
                warning(
                  "Invalid OTP count " + this.state.OTPTokenCount,
                  warningNotification
                );
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
              } else {
                warning(
                  res.reasonText + " count " + this.state.OTPTokenCount,
                  warningNotification
                );
              }
            } else if (res.reasonCode === "SM_11") {
              //warning("Token is getting refreshed", warningNotification);
              window.location.href = "/";
            } else if (res.reasonCode === "SM-03") {
              warning(
                "Invalid Login Credentials. Please try again",
                warningNotification
              );
              this.setState({
                loading: false,
                isLogined: false,
                showTimer: false,
                showResendOtp: false,
              });
            } else if (res.reasonCode === "SM-07") {
              this.setState({
                loading: false,
                isLogined: true,
                showTimer: false,
                showResendOtp: false,
              });
            } else if (res.reasonCode === "SM-12") {
              this.setState({
                loading: false,
                isLogined: false,
                showTimer: false,
                showResendOtp: false,
              });
              warning(
                "You have made 3 unsuccessful attempts. For login support, please contact support@finxpert.org.",
                warningNotification
              );
            } else if (res.reasonCode === "SM-13") {
              this.setState({
                loading: false,
                isLogined: false,
                showTimer: false,
                showResendOtp: false,
              });
              warning(
                "Your Account has been Suspended. For login support, please contact support@finxpert.org",
                warningNotification
              );
            } else {
              this.setState({
                loading: false,
                isLogined: false,
                showTimer: false,
                showResendOtp: false,
              });
              warning(res.reasonText, warningNotification);
            }
          }
          return true;
        },
        (error) => {
          //console.log(error);
        }
      );
    } else {
      if (!this.state.MobileVerified) {
        warning("Please Enter OTP", warningNotification);
      }
    }
  };

  handleCountryChange = (e) => {
    this.setState({ countryCode: e.target.value });
  };
  handleEducationDropdown = (e) => {
    if (e.target.value === 0) {
      this.setState({ flag: "true" });
    } else {
      this.setState({
        flag: "false",
      });
    }
  };

  handleClose = () => {
    this.setState({
      countryCode: "91",
      mobileNumber: "",
      OTP: "",
      firstName: "",
      lastName: "",
      instituteName: "",
      educationQualification: "",
      emailId: "",
      passedOutYear: "",
      listOfYear: [],
      pursuingYear: "",
      dateOfBirth: "",
      popupVisibility: false,
      productId: "",
      productdetails: [],
      checkout: [],
      showGenerateOtp: false,
      showResendOtp: false,
      MobileVerified: false,
      isMobileUnique: false,
      showOTPBox: false,
      isOTPGenerated: false,
      loading: false,
      flag: "false",
      isPaymentPending: false,
      isRegistered: false,
      // instituteName: [],
      instituteNameisReg: "",
      lookingFor: [],
      lookingForisReg: "",
      purposeName: "",
      purpose: [],
      user_obj: [],
    });

    window.location.reload();
    // ! After Reloading Redirect it to Root so that modal will be close
    window.location = "/";
  };

  OrderEntry = () => {
    let checkout = "";
    if (this.state.checkout?.length > 0) {
      let orderItemListArray = [];

      let productdetails =
        this.state.checkout?.productdetails === undefined
          ? this.state.checkout?.[0]?.productdetails
          : this.state.checkout?.productdetails;
      let personaldetails =
        this.state.checkout?.personaldetails === undefined
          ? this.state.checkout?.[0]?.personaldetails
          : this.state.checkout?.personaldetails;

      let orderItemList = {
        itemID: 1,
        productID: productdetails?.productId,
        couponApplied: "ZERO",
        prodctPrice: productdetails?.productPrice,
      };
      orderItemListArray.push(orderItemList);
      checkout = {
        studentPartyID: personaldetails?.partyId,
        orderPlacedOn: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"), //'15/11/2018 15:18:33',
        totalItemPrice: productdetails?.productPrice,
        totalOrderPrice: productdetails?.productPrice,
        paymentStatus: "Initiated",
        orderItemList: orderItemListArray,
      };
      LoginService.TokenUser((res) => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          OrderService.InsertOrder(
            checkout,
            (res1) => {
              if (res1.status === "success") {
                localStorage.setItem("reload", "true");
                this.setState({ loading: false });
                this.props.history.push("/checkout", {
                  props: this.state.checkout,
                  orderDetails: res1.responseObject,
                });
              }
            },
            (error) => {
              //console.log("Order Service ", error);
              this.props.history.push("/");
            }
          );
        }
      });
    }
  };

  makePayment = () => {
    if (this.state.isRegistered && this.state.isPaymentPending) {
      this.setState({ loading: true });
      let studobj = this.state.user_obj;
      var personaldetails = {
        partyId: studobj.studentPartyID,
        mobileNo: studobj.mobileNo,
        firstName: studobj.firstName,
        lastName: studobj.lastName,
        emailID: studobj.emailID,
        entryMode: studobj.entryMode === undefined ? 4 : studobj.entryMode,
        dateOfBirth: studobj.dob,
      };
      var productdetails = {
        productId: this.state.productdetails?.productID,
        productName: this.state.productdetails?.productName,
        productPrice: this.state.productdetails?.productPrice,
        partnerID: this.state.productdetails?.partnerID,
      };

      this.state.checkout.push({
        personaldetails: personaldetails,
        productdetails: productdetails,
      });

      //this.OrderEntry();
    }
  };
  // ! While Changing Tab reset the common state
  resetStateWhileTabChange = () => {
    this.setState({
      userLoginName: "",
      userPassword: "",
      userMobileNo: "",
      isMobile: false,
      isEmail: false,
      OTPTokenCount: 0,
      time: {},
      seconds: 180,
      showGenerateOtp: false,
      showResendOtp: false,
      MobileVerified: false,
      isMobileUnique: false,
      showOTPBox: false,
      showTimer: false,
      isOTPGenerated: false,
      mobileNumber: "",
      OTP: "",
      firstName: "",
      lastName: "",
      educationQualification: "",
      emailId: "",
      passedOutYear: "",
      pursuingYear: "",
      dateOfBirth: "",
      popupVisibility: false,
      productId: "",
    });
  };
  // ! Handle Tab Event
  showLoginTab = () => {
    this.resetStateWhileTabChange();
    this.setState({ isRegisterTab: false });
  };
  showRegisterTab = () => {
    this.resetStateWhileTabChange();
    this.setState({ isRegisterTab: true });
  };

  // ! LOGIN FORM
  handleUserLoginName = (e) => {
    let isMobile = this.isNumberForLogin(e.target.value);
    if (isMobile && !this.state.showResendOtp) {
      // if (e.target.value.length === 10) {
      //     this.startTimer();
      //     this.setState({ isMobile: true, showTimer: true })
      // }
    } else if (!isMobile) {
      this.setState({
        isMobile: false,
        showResendOtp: false,
        showTimer: false,
      });
      let Emailvalid = this.isEmailForLogin(e.target.value);
      if (Emailvalid) {
        //this.ValidateEmail(e.target.value);
      }
    }
    this.setState({ userLoginName: e.target.value });
  };
  handleLoginBlur = (e) => {
    if (this.state.isMobile) {
      if (e.target.value.length < 10 || e.target.value.length > 10) {
        warning("Mobile number should be of 10 digits", warningNotification);
      } else {
        if (this.state.isMobile && !this.state.showResendOtp) {
          if (e.target.value.length === 10) {
            this.startTimerForLogin();
            this.setState({ isMobile: true, showTimer: true });
          }
        }
      }
    } else if (this.state.isEmail) {
      this.validateEmailForLogin(e.target.value);
    }
  };
  validateEmailForLogin = (value) => {
    let emailvaidateParam = "emailID=" + value.toLowerCase();
    LoginService.TokenUser((res) => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        RegisterUserService.validateUserData(emailvaidateParam, (res1) => {
          if (res1.status === "success") {
            warning(
              "Your Email ID is not registered with us. Please register yourself by clicking the Register button below.",
              warningNotification
            );
          } else {
            // this.loginHandler();
          }
        });
      }
    });
  };
  handleGenerateOtpForLogin = () => {
    localStorage.setItem("TokenName", "jwt");
    LoginService.TokenUser((res) => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        let mobile = this.state.userLoginName;
        mobile = "mobileNo=" + mobile + "&productID=";
        MobileValidationService.MobileValidation(mobile, (res1) => {
          if (res1.status === "success") {
            if (res1.reasonCode === "FC-02") {
              MobileValidationService.MobileOTP(
                this.state.userLoginName,
                this.state.countryCode,
                (res2) => {
                  if (res2.status === "success") {
                    success("OTP will be generated in a few seconds...", {
                      ...successNotification,
                      dismiss: {
                        duration: 3000,
                        onScreen: false,
                        showIcon: true,
                      },
                    });
                    this.setState({loading:false})
                  }
                  return true;
                },
                (error) => {
                  error(error, errorNotification);
                  this.setState({loading:false});
                }
              );
              this.setState({ showResendOtp: false, showTimer: true });
            } else if (res1.reasonCode === "FC-01") {
              //console.log('mobilevalidaton', res1.reasonText);
              warning("Mobile number is not registered. Please try again.", {
                ...warningNotification,
                dismiss: {
                  duration: 3000,
                  onScreen: false,
                  showIcon: true,
                },
              });
              this.setState({ showResendOtp: false, showTimer: false,loading:false });
            } else {
              warning(res.reasonText, {
                ...warningNotification,
                container: "top-center",
              });
              this.setState({ showResendOtp: false, showTimer: false,loading:false });
            }
          } else {
            warning(res1.reasonText, {
              ...warningNotification,
              container: "top-center",
            });
            this.setState({ showResendOtp: false, showTimer: false,loading:false });
          }
        });
      }
    });
  };
  isEmailForLogin = (e) => {
    const re = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
    if (re.test(e)) {
      this.setState({ isEmail: true });
      return true;
    } else {
      this.setState({ isEmail: false });
      return false;
    }
  };
  isNumberForLogin = (e) => {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      this.setState({ isMobile: true });
      return true;
    } else {
      this.setState({ isMobile: false });
      return false;
    }
  };
  handleUserPasswordForLogin = (e) => {
    this.setState({ isPasswordfocused: true, userPassword: e.target.value });
  };
  _handleKeyDownForLogin = (e) => {
    //alert("outside"+ e.key);
    if (e.key === "Enter") {
      //alert("Inside "+ e.key);
      if (e.target.name === "password") {
        this.setState({
          isPasswordfocused: true,
          userPassword: e.target.value,
        });
        this.loginHandlerForLogin(e);
      }
      // else if (e.target.name === 'userLoginName') {
      //     this.handleuserLoginName(e);

      // }
    }
    if (e.getModifierState("CapsLock")) {
      this.setState({ warning: true });
    } else {
      this.setState({ warning: false });
    }
  };
  loginHandlerForLogin = (event) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (!this.state.isMaintainenceActivated) {
      if (this.state.userLoginName !== "" && this.state.userPassword !== "") {
        this.setState({ loading: true });
        const LoginDetails = {
          userLoginName: this.state.userLoginName.trim(), // 'PramilaPatil',
          emailId: this.state.userLoginName.trim(), // 'PramilaPatil',
          userPassword: this.state.userPassword.trim(), //'pramila555',
        };
        var logincon = [];
        this.setState({ loading: true });
        LoginService.SendUser(LoginDetails, this.state.isMobile, (res) => {
          if (res.status === "success") {
            logincon = res;
            this.setState({ credentials: logincon });
            localStorage.setItem("eMail", logincon.responseObject.emailId);
            localStorage.setItem(
              "userid-Token",
              logincon.responseObject.partyId
            );
            localStorage.setItem("userid", logincon.responseObject.userId);
            localStorage.setItem(
              "roleId",
              logincon.responseObject.role[0].roleId
            );
            localStorage.setItem(
              "roleName",
              logincon.responseObject.role[0].roleName
            );
            localStorage.setItem("checkedtab", false);
            localStorage.setItem("TokenName", "jti");
            localStorage.setItem("jwt-token",logincon.responseObject.jwttoken)
            localStorage.setItem('jti-token',logincon.responseObject.jwttoken)
            LoginService.TokenUser((res) => {
              this.setState((state) => {
                let oldstate = Object.assign({}, state);
                oldstate.credentials.responseObject.token = res.responseObject;
                this.state = oldstate; // setState({...oldstate})
                localStorage.setItem("jti-token", res.responseObject);
                localStorage.setItem(
                  "login",
                  JSON.stringify(oldstate.credentials.responseObject)
                );
                localStorage.setItem(
                  "login1",
                  JSON.stringify(oldstate.credentials.responseObject)
                );
              });
            }).then(()=>{
            localStorage.setItem("TokenName", "jwt");
            LoginService.TokenUser((res) => {
              localStorage.setItem("jwt-token", res.responseObject);
              this.setState({ loading: false });
              if (
                this.state.credentials.responseObject[
                  "forcePasswordChangeOnNextLoginFlag"
                ] === "1"
              ) {
                localStorage.setItem("reload", "true");
                this.props.history.push("/changepassword");
              } else {
                if (
                  this.state.credentials.responseObject.role[0].roleId === 5
                ) {
                  //! Lead Register Storing Starts
                  // let leadRegDataObj =
                  // {
                  //   partyID: logincon.responseObject.partyId ? logincon.responseObject.partyId : null,
                  //   client: this.state.paramsObj.cli ? this.state.paramsObj.cli : null,
                  //   college: this.state.paramsObj.col ? this.state.paramsObj.col : null,
                  //   product: this.state.paramsObj.pro ? this.state.paramsObj.pro : null,
                  //   location: this.state.paramsObj.loc ? this.state.paramsObj.loc : null,
                  //   reference: this.state.paramsObj.ref ? this.state.paramsObj.ref : null,
                  //   source: this.state.paramsObj.source ? this.state.paramsObj.source : null,
                  //   medium: this.state.paramsObj.medium ? this.state.paramsObj.medium : null,
                  //   term: this.state.paramsObj.term ? this.state.paramsObj.term : null,
                  //   camp: this.state.paramsObj.camp ? this.state.paramsObj.camp : null,
                  //   sourceUrl: ((this.state.pathname+this.state.searchParams) ? (this.state.pathname+this.state.searchParams) : null ),
                  //   productType: this.state.paramsObj.protype ? this.state.paramsObj.protype : null,
                  // }
                  // this.state.isParamsEncryptrd && RegisterUserService.LeadRegisterClickSave(leadRegDataObj, (res) => {
                  //   if (res.status === "success") {
                  //     this.setState({ loading: false });
                  //   } else {
                  //     this.setState({ loading: false });
                  //     warning(res.reasonText, warningNotification);
                  //   }
                  // },
                  // (err) => console.log("Error", err)
                  // );
                  //! Lead Register Storing Ends
                  $(".modal-backdrop").hide();
                  this.setState({ loading: true });
                  setTimeout(() => {  
                    if(logincon.responseObject?.emailId){
                      sessionStorage.setItem("eMail",logincon.responseObject.emailId)
                    }
                  this.setState({ loading: false });
                  this.props.history.push("/studentDashboard");
                  localStorage.setItem("reload", "true");
                }, 3000);
                } else if (
                  this.state.credentials.responseObject.role[0].roleId === 6
                ) {
                  if (localStorage.getItem("checkedtab") === "false") {
                    localStorage.setItem("checkedtab", true);
                    window.location.href = "/ops-dashboard";
                  }
                } else {
                  $(".modal-backdrop").hide();
                  this.setState({ loading: true });
                  setTimeout(() => {  
                  this.setState({ loading: false });
                  this.props.history.push("/admin-dashboard");
                  localStorage.setItem("reload", "true");
                  }, 3000);
                }
              }
            });
            });
          } else {
            this.setState({ loading: false });

            if (res.reasonCode === "DM_02") {
              let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
              this.setState({ OTPTokenCount: otpcounter });
              if (this.state.OTPTokenCount >= 4) {
                //warning("Click on Resend OTP", warningNotification);
                this.setState({
                  loading: false,
                  isLogined: false,
                  showResendOtp: true,
                  showTimer: false,
                });
                warning(
                  "You have made 3 unsuccessful attempts to Login to FinX. Click on Resend OTP.",
                  warningNotification
                );
              } else {
                warning(
                  res.reasonText + " count " + this.state.OTPTokenCount,
                  warningNotification
                );
              }
            } else if (res.reasonCode === "SM_11") {
              //warning("Token is getting refreshed", warningNotification);
              window.location.href = "/";
            } else if (res.reasonCode === "SM-03") {
              if (res.reasonText === "InActive user") {
                warning(
                  "Access Unauthorized. For login support, please contact support@finxpert.org.",
                  warningNotification
                );
              } else {
                warning(
                  "Invalid Login Credentials. Please try again",
                  warningNotification
                );
              }
              this.setState({
                loading: false,
                isLogined: false,
                showResendOtp: false,
                showTimer: false,
              });
            } else if (res.reasonCode === "SM-07") {
              this.setState({
                loading: false,
                isLogined: true,
                showResendOtp: false,
                showTimer: false,
              });
            } else if (res.reasonCode === "SM-12") {
              this.setState({
                loading: false,
                isLogined: false,
                showResendOtp: false,
                showTimer: false,
              });
              error(
                "You have made 3 unsuccessful attempts to Login to FinX, Your account has been locked. Please contact support@finxpert.org or 8767845088 for login support",
                errorNotification
              );
            } else if (res.reasonCode === "SM-13") {
              this.setState({
                loading: false,
                isLogined: false,
                showResendOtp: false,
                showTimer: false,
              });
              warning(
                "Your Account has been Suspended. For login support, please contact support@finxpert.org",
                warningNotification
              );
            } else if (res.reasonCode === "SM-04") {
              this.setState({
                loading: false,
                isLogined: false,
                showResendOtp: false,
                showTimer: false,
              });
              warning(
                "Your Account has been Suspended. For login support, please contact support@finxpert.org",
                warningNotification
              );
            } else {
              this.setState({
                loading: false,
                isLogined: false,
                showResendOtp: false,
                showTimer: false,
              });
              warning(res.reasonText, warningNotification);
            }
          }
        }).catch(this.setState({ loading: false }));
      } else {
        if (this.state.userLoginName === "") {
          error("You must specify a Username", errorNotification);
        } else if (
          this.state.userPassword === "" &&
          this.state.isPasswordfocused
        ) {
          error("You must specify Password", errorNotification);
        } else if (
          this.state.userLoginName === "" &&
          this.state.userPassword === ""
        ) {
          error("You must specify a  Username and Password", errorNotification);
        }
      }
    } else {
      error(
        "System is unavailable on 2nd ( Monday ) March 2020 for upgradation starting from 7.00 PM to 10.00 PM. Inconvenience is regretted.Reach out to Finx Core Support Team for any clarifications.",
        errorNotification
      );
    }
  };
  onCloseModal = () => {
    this.setState({ isLogined: false });
  };
  handleYesForLogin = () => {
    let isMobile = this.state.isMobile;
    const data = {
      userName: this.state.userLoginName, // 'PramilaPatil',
    };
    localStorage.setItem("TokenName", "jwt");
    LoginService.TokenUser(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          LoginService.LogoutUser(
            data,
            isMobile,
            (res) => {
              if (res.status === "success") {
                localStorage.clear();
                localStorage.setItem("windowloggedout", true);
                window.location.href = "/";
              } else {
                warning(res.reasonText, warningNotification);
              }
            },
            (error) => {
              //console.log(error);
              error(error, errorNotification);
            }
          );
        }
      },
      (error) => {
        //console.log(error);
        error(error, errorNotification);
      }
    );
  };
  loginForgotPassword = (event) => {
    localStorage.setItem("reload", "true");
    this.props.history.push("/forgotpassword", {
      isloaded: true,
    });
  };
  startTimerForLogin = () => {
    this.handleGenerateOtpForLogin();
    this.startTimer(); // same functionality for login but with handleGenerateOtpMethod
  };
  handleNumberChange = (e,setFieldValue) => {
    e.preventDefault();
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setFieldValue("mobileNumber",input.slice(0, 10))
  }
  handleOTPChange = (e,setFieldValue) => {
    e.preventDefault();
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setFieldValue("OTP",input.slice(0, 4))
  }

  render() {  
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            mobileNumber: this.state.mobileNumber
              ? this.state.mobileNumber
              : "",
            OTP: this.state.OTP ? this.state.OTP : "",
            purpose: this.state.purpose ? this.state.purpose : "",
            firstName: this.state.firstName ? this.state.firstName : "",
            purpose1: "",
            lookingFor1: "",
            instituteName1: "",
            lastName: this.state.lastName ? this.state.lastName : "",
            instituteName: this.state.instituteName
              ? this.state.instituteName
              : "",
            educationQualification: this.state.educationQualification
              ? this.state.educationQualification
              : "",
            emailId: this.state.emailId ? this.state.emailId : "",
            passedOutYear: this.state.passedOutYear
              ? this.state.passedOutYear
              : "",
            pursuingYear: this.state.pursuingYear
              ? this.state.pursuingYear
              : "",
            dateOfBirth: this.state.dateOfBirth ? this.state.dateOfBirth : "",
            countryCode: "91",
            instituteNameisReg: this.state.instituteNameisReg,
            lookingForisReg: this.state.lookingForisReg,
            purposeName: this.state.purposeName,
            otherCollegeName: "",
            showGenerateOtp: false,
            disabledbtn: "false",
            whatsapp: true,
            termsandconditions: false,
            searchParams: this.state.searchParams,
            pathname: this.state.pathname,
            kotakUrl: this.state.pathname+this.state.searchParams,
            paramsObj: this.state.paramsObj,
          }}
          validationSchema={Yup.object().shape({
            mobileNumber: Yup.string()
              .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
              .required("You must specify a Mobile Number")
              .when((value) => {
                if (value !== undefined) {
                  if (value.length === 10 && this.isNumber(value)) {
                    this.setState({
                      showGenerateOtp: true,
                      mobileNumber: value,
                    });
                  }
                  if (value.length < 10 || value.length > 10) {
                    this.setState({ showGenerateOtp: false });
                  }
                } else if (value === undefined) {
                  this.setState({ showGenerateOtp: false });
                }
              }),
            OTP: Yup.number().positive(),
            //.required('OTP is required'),
            firstName: Yup.string()
              .matches(/^[A-Za-z]*$/, "Please enter valid First Name")
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("You must specify a First Name"),
            lastName: Yup.string()
              .matches(/^[A-Za-z]*$/, "Please enter valid Last Name")
              // .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("You must specify a Last Name"),
            emailId: Yup.string()
              // .email("Invalid E-Mail")
              .matches(/^[a-zA-Z0-9.!#$&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,"Invalid E-Mail ")
              .required("You must specify a E-Mail Id"),
          })}
          onReset={(values, { resetForm }) => {
            this.setState({
              countryCode: "91",
              mobileNumber: "",
              OTP: "",
              firstName: "",
              lastName: "",
              instituteName: "",
              educationQualification: "",
              emailId: "",
              passedOutYear: "",
              listOfYear: [],
              pursuingYear: "",
              dateOfBirth: "",
              popupVisibility: false,
              productId: "",
              productdetails: [],
              checkout: [],
              showGenerateOtp: false,
              showResendOtp: false,
              MobileVerified: false,
              isMobileUnique: false,
              showOTPBox: false,
              isOTPGenerated: false,
              loading: false,
              flag: "false",
              isPaymentPending: false,
              isRegistered: false,
              // instituteName: [],
              instituteNameisReg: "",
              lookingFor: [],
              lookingForisReg: "",
              purposeName: "",
              purpose: [],
              user_obj: [],
            });

            //resetForm();
          }}
          onSubmit={(fields) => {
            // debugger // ! Commented by Akshay
            let params = `emailID=${fields["emailId"].toLowerCase()}`
            RegisterUserService.validateDomainDuringRegister(params, (res) => {
              if (res.status === "success") {
                this.setState({ disabledbtn: true });
                this.setState({ loading: true });
                this.setState({ loading1: "block" });

                if (
                  fields.termsandconditions === false ||
                  fields.termsandconditions === undefined
                ) {
                  this.setState({
                    disabledbtn: false,
                    loading: false,
                    loading1: "none",
                  });
                  warning(
                    "Please select Terms and Conditions",
                    warningNotification
                  );
                } else {
                  let mobileNumber = fields["mobileNumber"];
                  let OTP = fields["OTP"];
                  let firstName = fields["firstName"];
                  let lastName = fields["lastName"];

                  let emailId = fields["emailId"].toLowerCase();

                  let data = {
                    mobileNo: mobileNumber,
                    OTP: OTP,
                    firstName: firstName,
                    lastName: lastName,
                    emailID: emailId,
                    entryMode: 4,
                    isTermsAccepted: fields.termsandconditions === true ? 1 : 0,
                    sendWhatsapp: fields.whatsapp ===  true ? "Y" : fields.whatsapp ===  false ? "N" : "N",
                    leadRegister:
                    {
                      encryptedSourceUrl: this.state.encryptedUrl === "true" 
                      ? (fields.kotakUrl ? fields.kotakUrl : null)
                      : null,
                      sourceUrl: this.state.encryptedUrl === "true" 
                      ? (this.state.decryptedSourceUrl ? this.state.decryptedSourceUrl : null)
                      : this.state.encryptedUrl === "false"
                      ? (fields.kotakUrl ? fields.kotakUrl : null)
                      : null,
                      productID: fields.paramsObj.proId ? fields.paramsObj.proId : null,
                      client:fields.paramsObj.cli ? fields.paramsObj.cli : null,
                      college:fields.paramsObj.col ? fields.paramsObj.col : null,
                      product:fields.paramsObj.pro ? fields.paramsObj.pro : null,
                      location:fields.paramsObj.loc ? fields.paramsObj.loc : null,
                      reference:fields.paramsObj.ref ? fields.paramsObj.ref : null,
                      source:fields.paramsObj.source ? fields.paramsObj.source : null,
                      medium:fields.paramsObj.medium ? fields.paramsObj.medium : null,
                      term:fields.paramsObj.term ? fields.paramsObj.term : null,
                      camp:fields.paramsObj.camp ? fields.paramsObj.camp : null,
                      productType: fields.paramsObj.protype ? fields.paramsObj.protype : null,
                    }
                  };
                  console.log('data',data);

                  RegisterUserService.RegisterUser(
                    data,
                    (res) => {
                      if (res.status === "success") {
                        // var personaldetails = {
                        //   partyId: res.responseObject.studentPartyID,
                        //   mobileNo: res.responseObject.mobileNo,
                        //   firstName: res.responseObject.firstName,
                        //   lastName: res.responseObject.lastName,
                        //   emailID: res.responseObject.emailID,
                        //   entryMode: res.responseObject.entryMode
                        // };
                        // ! Register -> login
                        /* ! Comment Old Register Logic
                        success(
                          "Congratulations! You have completed Finx registration successfully.Credentials to access will be shared shortly over your registered email id.",
                          successNotification
                        );
                        this.setState({ loading: false });
                        this.setState({ loading1: "none" });
                        // window.location.reload();
                        setTimeout(() => {
                          window.location.reload()
                        }, 5000)
                        this.state.checkout.push({
                          personaldetails: personaldetails,
                          productdetails: productdetails,
                        });
                        //this.OrderEntry();
                        */
                        //!  Lead Register Storing Starts
                        // let leadRegDataObj =
                        // {
                        //   partyID: res.responseObject.studentPartyID ? res.responseObject.studentPartyID : null,
                        //   sourceUrl: fields.kotakUrl,
                        //   client:fields.paramsObj.cli ? fields.paramsObj.cli : null,
                        //   college:fields.paramsObj.col ? fields.paramsObj.col : null,
                        //   product:fields.paramsObj.pro ? fields.paramsObj.pro : null,
                        //   location:fields.paramsObj.loc ? fields.paramsObj.loc : null,
                        //   reference:fields.paramsObj.ref ? fields.paramsObj.ref : null,
                        //   source:fields.paramsObj.source ? fields.paramsObj.source : null,
                        //   medium:fields.paramsObj.medium ? fields.paramsObj.medium : null,
                        //   term:fields.paramsObj.term ? fields.paramsObj.term : null,
                        //   camp:fields.paramsObj.camp ? fields.paramsObj.camp : null,
                        //   productType: fields.paramsObj.protype ? fields.paramsObj.protype : null,
                        // }
                        // this.state.isParamsEncryptrd && RegisterUserService.LeadRegisterClickSave(leadRegDataObj, (res) => {
                        //   if (res.status === "success") {
                        //     this.setState({ loading: false });
                        //   } else {
                        //     this.setState({ loading: false });
                        //     warning(res.reasonText, warningNotification);
                        //   }
                        // },
                        // (err) => console.log("Error", err)
                        // );
                        if(res.responseObject?.emailId){
                          sessionStorage.setItem("eMail",res.responseObject.emailId)
                        }
                        //! Lead Register Storing Ends
                        // ! Login Handle Logic
                        localStorage.setItem(
                          "loginData",
                          JSON.stringify(res.responseObject)
                        );
                        localStorage.setItem("loginFrom", "Form");
                        setTimeout(() => {
                          window.location.replace("/registerSuccess");
                        }, 1500);
                        localStorage.setItem("leadSourceUrl", "");
                        localStorage.setItem("decryptedSourceUrl", "");
                        localStorage.setItem("encryptedUrl", "");
                        // ! End of Login Handle Logic
                      } else {
                        error(res.reasonText, errorNotification);
                        this.setState({ loading: false });
                        this.setState({ loading1: "none" });
                      }
                    },
                    (error) => {
                      console.log(error);
                      this.setState({ loading: false });
                      this.setState({ loading1: "none" });
                    }
                  );
                }
              } else {
                error(res.reasonText, errorNotification);
                return;
              }
            });            
          }}
          render={({
            touched,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            // handleReset,
            // isValid,
            values,
            setFieldValue,
          }) => (
            <React.Fragment>
              {/* {this.state.loading && <PopUpPortal HidePopup={this.state.loading}
                                    IsVisible={this.state.loading} />} */}
              <div
                className="modal fade"
                id="feat-Reg"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
                data-keyboard="false"
                data-backdrop="static"
              >
                <Form>
                  {/* <PopUpPortal HidePopup={this.hidePopup}
                                        IsVisible={this.state.popupVisibility} > */}
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      {/* ! Commented as on live close btn removed
                      <button
                        type="reset"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> */}
                      {/* <button type="button" className="close" onClick={this.hidePopup} aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button> */}

                      <div className="col-md-12"></div>
                      <div className="tab-form">
                        <ul className="tab-group" id="login-reg-tab">
                          <li
                            className={`tab ${
                              this.state.isRegisterTab ? "active" : ""
                            }`}
                          >
                            <a onClick={this.showRegisterTab} href>
                              Register
                            </a>
                          </li>
                          <li
                            className={`tab ${
                              !this.state.isRegisterTab ? "active" : ""
                            }`}
                          >
                            <a onClick={this.showLoginTab} href>
                              Log In
                            </a>
                            
                          </li>
                          {/* <li >
                          <button
                                type="reset"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.closeRegistrationModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                          </li> */}
                        </ul>
                        <div className="tab-content">
                          <section
                            className="registration"
                            style={
                              this.state.isRegisterTab
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="modal-head">
                                    <h3>FINX Registration</h3>
                                    <p>
                                      First step towards realising your
                                      professional/entrepreneurial dreams in
                                      indian BFSI sector
                                    </p>
                                  </div>
                                  <img
                                    src={featmodal}
                                    className="img-responsive"
                                    alt="#"
                                  />
                                </div>
                                <div className="col-md-8">
                                  <div className="form-group">
                                    <div className="row">
                                      {/* <div
                                        id="loading-bar-spinner"
                                        className="spinner"
                                        style={{ display: this.state.loading1 }}
                                      >
                                        <div className="spinner-icon"></div>
                                      </div> */}
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div>
                                          <label>
                                            MOBILE NUMBER
                                            <span className="validation">
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-4">
                                              {/* <select */}
                                              <div
                                                name="countryCode"
                                                id=""
                                                value={this.state.countryCode}
                                                onChange={
                                                  this.handleCountryChange
                                                }
                                                text={this.state.countryCode}
                                                className="form-control"
                                              >
                                                <option
                                                  data-countrycode="IN"
                                                  value="91"
                                                >
                                                  +91
                                                </option>
                                              </div>
                                            </div>
                                            <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                              <Field
                                                // type="Number"
                                                type="text"
                                                name="mobileNumber"
                                                pattern="[0-9]{10}"
                                                placeholder=""
                                                maxLength="10"
                                                id="mobileNumber"
                                                className={"form-control"}
                                                value={values.mobileNumber}
                                                onChange={(e)=>{handleChange(e);
                                                this.handleNumberChange(e,setFieldValue);
                                                }}
                                                onBlur={(e)=>{
                                                  setFieldValue("mobileNumber",e.target.value)
                                                  if([10,"10"].includes(e.target.value.length)){
                                                    this.startTimer()
                                                  }
                                                }}
                                                //                               onBlur={event => {

                                                //                                 if (
                                                //                                   event.target.value !== undefined
                                                //                                 ) {
                                                //                                   let value=event.target.value

                                                //                                      //     console.log(typeof value.length)
                                                //   if (value.length == 10 ) {
                                                //     value=Number(value)
                                                //     this.MobileNoValidation(value)
                                                //     setFieldValue(
                                                //       (values.mobileNumber =
                                                //         event.target.value)
                                                //     )
                                                //     setFieldValue(
                                                //       (values.showGenerateOtp =
                                                //        true)
                                                //     )
                                                //   }
                                                //   // if (value.length < 10 || value.length > 10) {
                                                //   //   this.setState({ showGenerateOtp: false });
                                                //   // }
                                                // }

                                                //                                 else {
                                                //                                 //  this.setState({ showGenerateOtp: false });
                                                //                                   setFieldValue(
                                                //                                     (values.showGenerateOtp =
                                                //                                      false)
                                                //                                   );
                                                //                                 }
                                                //                               }}
                                              />
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            name="mobileNumber"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        {/* {this.state.showGenerateOtp &&
                                          !this.state.isOTPGenerated && (
                                            <div>
                                              <a
                                                href 
                                                className="otp-resend"
                                                // onClick={this.handleGenerateOtp}
                                                onClick={this.startTimer}
                                              >
                                                Generate OTP
                                              </a>
                                            </div>
                                          )} */}
                                      </div>

                                      {this.state.showOTPBox && (
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                          <label>
                                            ENTER OTP{" "}
                                            <span className="validation">
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="OTP"
                                            id="OTP"
                                            onBlur={this.handleValidateOtp}
                                            className={"form-control enter-otp"}
                                            placeholder=""
                                            maxLength="4"
                                            value={values.OTP}
                                            onChange={(e)=>{handleChange(e);
                                            this.handleOTPChange(e,setFieldValue);
                                            }}
                                          />
                                          {this.state.showTimer ? (
                                            <div>
                                              {this.state.showResendOtp && (
                                                <React.Fragment>
                                                  <div className="otp-resend">
                                                    <a
                                                      href
                                                      onClick={this.startTimer}
                                                      // onClick={this.handleGenerateOtp}
                                                    >
                                                      Resend OTP
                                                    </a>
                                                  </div>
                                                </React.Fragment>
                                              )}
                                              <h5 className="timer">
                                                {this.state.time.m} :{" "}
                                                {this.state.time.s} Time Left
                                              </h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          <ErrorMessage
                                            name="OTP"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                      )}

                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          EMAIL ID{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="emailId"
                                          id="emailId"
                                          disabled={this.state.isRegistered}
                                          validate={this.validateEmail}
                                          className={"form-control"}
                                          placeholder=""
                                        />
                                        <ErrorMessage
                                          name="emailId"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>

                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          FIRST NAME{" "}
                                          <span className="validation"> *</span>{" "}
                                        </label>
                                        <Field
                                          type="text"
                                          name="firstName"
                                          disabled={this.state.isRegistered}
                                          id="firstName"
                                          className={"form-control"}
                                          placeholder=""
                                        />
                                        <ErrorMessage
                                          name="firstName"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          LAST NAME{" "}
                                          <span className="validation"> *</span>{" "}
                                        </label>
                                        <Field
                                          type="text"
                                          disabled={this.state.isRegistered}
                                          name="lastName"
                                          id="lastName"
                                          className={"form-control"}
                                          placeholder=""
                                        />
                                        <ErrorMessage
                                          name="lastName"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>

                                      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="termsandconditions"
                                          disabled={this.state.isRegistered}
                                          style={{
                                            marginRight: "10px",
                                            marginTop: "15px",
                                          }}
                                        />
                                        <span
                                          className="form-check-label"
                                          htmlFor="gridCheck"
                                          style={{ marginRight: "15px" }}
                                        >
                                          <a
                                            href={TC}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Terms and Conditions{" "}
                                            <span className="validation1">
                                              {" "}
                                              *
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="whatsapp"
                                            defaultChecked={true}
                                            onChange={handleChange}
                                            disabled={this.state.isRegistered}
                                            style={{
                                              marginRight: "10px",
                                              marginTop: "15px",
                                            }}
                                          />
                                          <span
                                            className="form-check-label"
                                            htmlFor="gridCheck"
                                            style={{ marginRight: "15px" }}
                                          ><a href>I agree to receive updates from FinX through WhatsApp, Email, and SMS"</a> 
                                          </span>
                                        </div>

                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        {this.state.MobileVerified ? (
                                          this.state.isPaymentPending ? (
                                            <button
                                              type="button"
                                              onClick={this.makePayment}
                                              className="btn-4 mt-30 pull-right gray"
                                            >
                                              Submit
                                            </button>
                                          ) : !this.state.isRegistered ? (
                                            <button
                                              type="submit"
                                              onClick={handleSubmit}
                                              className="btn-4 mt-30 pull-right gray"
                                            >
                                              Submit
                                            </button>
                                          ) : null
                                        ) : null}
                                      </div> */}
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="text-center mb-20 mt-30">
                                          <h3 className="text-center mt-2 or">
                                            Or Register With
                                          </h3>
                                          {/* ---------- OR Register With ---------- */}
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12 mb-10">
                                        {/* <button type="button" id="btn_google_login"
                                          className="social-btn w-100 position-relative text-center g-social-login btn_google_login">
                                          <img src="https://www.refreshyourlife.in/theme/Versatile/img/google-login.svg?v=1" alt="Facebook"
                                            className="position-absolute" />
                                          Continue with Google
                                        </button> */}
                                        {/* <div id="google-auth-btn-register"></div> */}
                                        <GoogleOAuthProvider clientId={Global_var.GOOGLE_CLIENT}>
                                        <GoogleLogin
                                            onSuccess={credentialResponse => {
                                                console.log(credentialResponse);
                                                this.signInWithGoogle(credentialResponse);
                                            }}
                                            onError={() => {
                                                console.log('Login Failed');
                                            }}
                                            type="standard"
                                            shape="rectangular"
                                            theme="outline"
                                            text="continue_with"
                                            size="large"
                                            logo_alignment="left"
                                            width="250px"
                                            />
                                        </GoogleOAuthProvider>
                                      </div>
                                      <div
                                        className="col-md-6 col-sm-6 col-xs-12 mb-10"
                                        style={{ textAlign: "center" }}
                                      >
                                        <button
                                          type="button"
                                          id="btn_facebook_login"
                                          className="social-btn w-100 position-relative text-center f-social-login btn_facebook_login"
                                          onClick={this.signInWithFacebook}
                                        >
                                          <img
                                            // src="https://www.refreshyourlife.in/theme/Versatile/img/facebook-login.svg?v=1"
                                            src={FaceBookLogo}
                                            alt="Facebook"
                                            className="position-absolute"
                                          />
                                          <span>Continue with Facebook</span>
                                        </button>
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="termsandconditions"
                                          onChange={(e)=>{
                                            setFieldValue("termsandconditions",!values.termsandconditions)
                                            this.setState({
                                              isTermsAccepted:!values.termsandconditions,
                                            })                                             
                                          }
                                          }
                                          disabled={this.state.isRegistered}
                                          style={{
                                            marginRight: "10px",
                                            marginTop: "15px",
                                          }}
                                        />
                                        <span
                                          className="form-check-label"
                                          htmlFor="gridCheck"
                                          style={{ marginRight: "15px" }}
                                        >
                                          <a
                                            href={TC}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Terms and Conditions{" "}
                                            <span className="validation1">
                                              {" "}
                                              *
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="whatsapp"
                                            defaultChecked={true}
                                            onChange={(e)=>{
                                              setFieldValue("whatsapp",!values.whatsapp)
                                              this.setState({
                                                whatsapp:!values.whatsapp
                                              })                                             
                                            }
                                            }
                                            disabled={this.state.isRegistered}
                                            style={{
                                              marginRight: "10px",
                                              marginTop: "15px",
                                            }}
                                          />
                                          <span
                                            className="form-check-label"
                                            htmlFor="gridCheck"
                                            style={{ marginRight: "15px" }}
                                          ><a href>I agree to receive updates from FinX through WhatsApp, Email, and SMS</a>
                                          </span>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        {this.state.MobileVerified ? (
                                          <div className="col-md-12 col-sm-12 col-xs-12">
                                          <hr style={{
                                              background: '#ddd',
                                              color: '#ddd',
                                              borderColor: '#ddd',
                                              height: '0px',
                                              marginBottom:'0%'
                                            }}/>
                                        </div>
                                        ): null}
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                        {this.state.MobileVerified ? (
                                          this.state.isPaymentPending ? (
                                            <button
                                              type="button"
                                              onClick={this.makePayment}
                                              className="btn-4 mt-30 pull-right gray"
                                            >
                                              Submit
                                            </button>
                                          ) : !this.state.isRegistered ? (
                                            <button
                                              type="submit"
                                              onClick={handleSubmit}
                                              className="btn-4 mt-30 pull-right gray"
                                            >
                                              Submit
                                            </button>
                                          ) : null
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="registration"
                            style={
                              !this.state.isRegisterTab
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-4 col-sm-5 col-xs-12">
                                  <div className="modal-head">
                                    <h3>FINX Login</h3>
                                    <p>
                                      First step towards realising your
                                      professional/entrepreneurial dreams in
                                      indian BFSI sector
                                    </p>
                                  </div>
                                  <img
                                    src={featmodal}
                                    className="img-responsive"
                                    alt="img"
                                  />
                                </div>
                                <div className="col-md-8 col-sm-7 col-xs-12">
                                  <div className="form-group">
                                    <div className="row">
                                      {/* <div
                                        id="loading-bar-spinner"
                                        className="spinner"
                                        style={{ display: this.state.loading1 }}
                                      >
                                        <div className="spinner-icon"></div>
                                      </div> */}
                                      <div className="col-md-6 col-sm-12 col-xs-12">
                                        <label>
                                          ENTER Email ID / Mobile No.{" "}
                                          <span className="validation">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          name="userLoginName"
                                          className="form-control"
                                          placeholder="Enter Email ID/Mobile No."
                                          onChange={this.handleUserLoginName}
                                          value={this.state.userLoginName}
                                          onKeyDown={
                                            this._handleKeyDownForLogin
                                          }
                                          onBlur={this.handleLoginBlur}
                                          autoComplete="off"
                                          disabled={
                                            this.state.isMaintainenceActivated
                                          }
                                        />
                                        {this.state.warning ? (
                                          <span style={{ color: "#085dab" }}>
                                            Caps Lock On!
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        <span className="file-size-limit">
                                          Login with your Username/Mobile No.
                                          &amp; Current Password/OTP
                                        </span>
                                      </div>
                                      <div className="col-md-6 col-sm-12 col-xs-12">
                                        <label>
                                          ENTER PASSWORD/OTP{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <input
                                          type="password"
                                          name="password"
                                          className="form-control"
                                          placeholder={
                                            this.state.isMobile
                                              ? "OTP"
                                              : this.state.isEmail
                                              ? "Password"
                                              : "Password/OTP"
                                          }
                                          // placeholder={
                                          //   this.state.isMobile
                                          //   ? "OTP"
                                          //   : this.state.isEmail
                                          //   ? "Password"
                                          //   : "Password/OTP"
                                          // }
                                          onChange={
                                            this.handleUserPasswordForLogin
                                          }
                                          value={this.state.userPassword}
                                          onKeyDown={
                                            this._handleKeyDownForLogin
                                          }
                                          autoComplete="off"
                                          disabled={
                                            this.state.isMaintainenceActivated
                                          }
                                        />
                                      </div>
                                      <span>
                                        <div className="mt-10 pull-right">
                                          <span className="sidebartogglexyz">
                                            {/* OTP */}
                                            {this.state.showResendOtp ? (
                                              <React.Fragment>
                                                <span className="otp-resend">
                                                  <a
                                                    onClick={
                                                      this.startTimerForLogin
                                                    }
                                                    href
                                                  >
                                                    Resend OTP
                                                  </a>
                                                </span>
                                              </React.Fragment>
                                            ) : this.state.showTimer ? (
                                              <div>
                                                <h5 className="timer">
                                                  {this.state.time.m} :{" "}
                                                  {this.state.time.s} Time Left
                                                </h5>
                                              </div>
                                            ) : !this.state
                                                .isMaintainenceActivated ? (
                                              <span>
                                                <a
                                                  onClick={
                                                    this.loginForgotPassword
                                                  }
                                                  className="sidebartogglexyz otp-resend resend-otp-in-modal"
                                                  href
                                                >
                                                  Forgot Password?
                                                </a>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </span>
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                        {/* LOGIN BTN */}
                                        {!this.state.isMaintainenceActivated ? (
                                          this.state.userLoginName.trim() !==
                                            "" &&
                                          this.state.userPassword.trim() !==
                                            "" ? (
                                            <button
                                              onClick={
                                                this.loginHandlerForLogin
                                              }
                                              type="button"
                                              className="btn-1 pull-right"
                                              value="Sign in"
                                            >
                                              Sign in
                                            </button>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="text-center mb-20 mt-30">
                                      <h3 className="text-center mt-2 or">
                                        Or Login With
                                      </h3>
                                      {/* ---------- OR Login With ---------- */}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-6 col-sm-12 col-xs-12 mb-10"
                                    style={{ paddingLeft: "7px" }}
                                  >
                                    {/* <button type="button" id="btn_google_login"
                                      className="social-btn w-100 position-relative text-center g-social-login btn_google_login">
                                      <img src="https://www.refreshyourlife.in/theme/Versatile/img/google-login.svg?v=1" alt="Facebook"
                                        className="position-absolute" />
                                      Continue with Google
                                    </button> */}
                                    {/* <div id="google-auth-btn-login"></div> */}
                                    <GoogleOAuthProvider clientId={Global_var.GOOGLE_CLIENT}>
                                        <GoogleLogin
                                            onSuccess={credentialResponse => {
                                                console.log(credentialResponse);
                                                this.signInWithGoogle(credentialResponse);
                                            }}
                                            onError={() => {
                                                console.log('Login Failed');
                                            }}
                                            type="standard"
                                            shape="rectangular"
                                            theme="outline"
                                            text="continue_with"
                                            size="large"
                                            logo_alignment="left"
                                            width="250px"
                                            />
                                        </GoogleOAuthProvider>
                                  </div>
                                  <div
                                    className="col-md-6 col-sm-12 col-xs-12 mb-10"
                                    style={{
                                      textAlign: "center",
                                      paddingLeft: "7px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      id="btn_facebook_login"
                                      onClick={this.signInWithFacebook}
                                      className="social-btn w-100 position-relative text-center f-social-login btn_facebook_login"
                                    >
                                      <img
                                        // src="https://www.refreshyourlife.in/theme/Versatile/img/facebook-login.svg?v=1"
                                        src={FaceBookLogo}
                                        alt="Facebook"
                                        className="position-absolute"
                                      />
                                      <span>Continue with Facebook</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </PopUpPortal> */}
                </Form>
              </div>
              {/* {this.state.loading1 && (
                <div id="loading-bar-spinner" className="spinner">
                  <div className="spinner-icon"></div>
                </div>
                // <PopUpPortal
                //   HidePopup={this.state.loading1}
                //   IsVisible={this.state.loading1}
                // />
              )} */}
               <Formik
              enableReinitialize={true}
              initialValues={{
                emailid:this.state.email,
                partyid:this.state.partyid,
                feedbackMsg:this.state.feedbackMsg,
                rank:this.state.rank,
                typeOfConcernList:this.state.typeOfConcernList1,
                typeOfConcern:""
            }}
            validationSchema={Yup.object().shape({
                //static validations
                rank:Yup.number()
                    .min(1, 'You must specify your reaction')
                    .max(5, 'rating range should be between 1-5'),
                feedbackMsg: Yup.string()
                    .matches(/^[a-zA-Z0-9\s,\-&./()]+$/, "Please enter valid Feedback Message. Allowed characters : alphnumeric,Space,comma,hyphen,ampersand,dot,forward Slash, Round Brackets"),
                typeOfConcern:Yup.string().when("rank",{
                    is:(rank)=>rank===1||rank===2?true:false,
                    then:Yup.string().required("You Must Select Type of Concern"),
                    otherwise:Yup.string()
                })
                }
            )}
              onSubmit={(values)=>{
                // if(values.rank === 0){
                //     warning("You Must Specify your Reaction",warningNotification);
                //     return;
                // }
                if(values.emailid === "")
                {
                    warning("EmailId can't be null",warningNotification);
                    return;
                }
                else if(values.partyid === "")
                {
                    warning("partyId can't be null",warningNotification);
                    return;
                }
                let data = {
                    partyid : values.partyid,
                    emailid: values.emailid,
                    rank: values.rank,
                    feedback_msg: values.feedbackMsg,
                    concernID:values.typeOfConcern
                }
                console.log('cd',data)
                this.setState({ loading: true, feedbackBtnDisabled: true })
                FeedbackService.PostFeedback(
                    data,
                    (cb) => {        
                        if (cb.status === "success") {
                          this.setState({ loading: false,feedbackBtnDisabled: false, rank : 0,feedbackMsg:"" });
                          success("Thank you for your valuable feedback. We are a young startup and this will help us to improve.", successNotification);
                          window
                          .$("#feedback-modal")
                          .modal("hide");
                          const child = document.querySelectorAll('.emoji');
                          child.forEach(element => {
                            element.classList.remove('active');
                          });
                          setTimeout(() => {
                            window.location.reload();
                          }, 1500);

                        } else {        
                          this.setState({ loading: false,feedbackBtnDisabled: false, rank : 0,feedbackMsg:"" });
                          error(cb.status,errorNotification);
                          window
                          .$("#feedback-modal")
                          .modal("hide");
                          const child = document.querySelectorAll('.emoji');
                          child.forEach(element => {
                            element.classList.remove('active');
                          });
                          setTimeout(() => {
                            window.location.reload();
                          }, 1500);
                        }
                      },
                      (err) => console.log("Error", err)
                  );
                
            }}
              render={({
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                handleReset,
                values,
                handleChange
              }) => (
                  <React.Fragment>
                    <PopUpPortal
                      HidePopup={this.state.loading}
                      IsVisible={this.state.loading}
                    />
                    <div
                      className="modal fade"
                      id="feedback-modal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={()=>{
                                setTimeout(() => {
                                  window.location.reload();
                                }, 500);
                              }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <strong><h4 className="modal-title text-center" style={{fontWeight:"bold",marginBottom:20}} id="myModalLabel">
                                How was your overall experience with FinX?
                            </h4>
                            </strong>
                          </div>
                          <div className="modal-body">
                            <Form className="form-group">
                              <div className="form-group">
                                <div className="row">
                                   <div id="myDIV" className="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 1})}}>&#128544;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 2})}}>&#128530;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 3})}}>&#128529;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 4})}}>&#128512;</span>
                                    <span name="rank" role="img" aria-label="Emoji" className="emoji" onClick={()=>{this.setState({rank: 5})}}>&#129321;</span>
                                   </div>
                                </div>
                                {["1",1,"2",2].includes(this.state.rank)&&
                                <div className="row">

                                <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <label>
                                        Type Of Concern
                                        <span className="validation">
                                            {" "}*{" "}
                                        </span>
                                    </label>
                                        <Field
                                          as="select"
                                          name="typeOfConcern"
                                          id="typeOfConcern"
                                          style={{ border: "1px solid #fbc583",width:"90%", margin:"0px 5px 5px 3rem"  }}
                                        //   onChange={(e)=>setFieldValue("typeOfConcern",e.target.value)}
                                        onChange={handleChange}
                                          className={
                                            "form-control " +
                                            (errors.typeOfConcern &&
                                            touched.typeOfConcern
                                              ? " is-invalid"
                                              : "")
                                          }
                                      
                                        >
                                            <option value="">--Select--</option>
                                          {(
                                            values.typeOfConcernList || []
                                          ).map((item) => (
                                            <option
                                              key={item.concernID}
                                              value={item.concernID}
                                            >
                                              {item.concernName}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="typeOfConcern"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>


                                </div>
              }
                                <div className="row">
                                   <div className="col-md-12 col-sm-12 col-xs-12">
                                  
                                    <Field
                                      rows="4"
                                      component="textarea"
                                      maxLength={255}
                                      name="feedbackMsg"
                                      id="feedbackMsg"
                                      placeholder="What can we do to improve?"
                                      autoComplete="feedbackMsg"
                                     onChange={handleChange}
                                      className={
                                        "form-control"
                                      }
                                      style={{ border: "1px solid #fbc583",width:"90%", margin:"0px 5px 5px 3rem"  }}
                                  
                                    />
                                     <ErrorMessage
                                          name="feedbackMsg"
                                          className="validation"
                                          component="div"
                                          style={{marginLeft:"42px"}}
                                        />
                                    <ErrorMessage
                                          name="rank"
                                          className="validation"
                                          component="div"
                                          style={{marginLeft:"42px"}}
                                        />
                                   </div>     
                                </div>
                              </div>
                            </Form>
                          </div>
                          <div className="modal-footer text-center">
                                                      
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={this.state.feedbackBtnDisabled}
                            >
                              Send your Feedback
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
            />    
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default finxregistration;
