import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import "react-table/react-table.css";
import $ from "jquery";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import LandingPage from "../containers/landingPage/landingPage";
import Changepassword from "../components/mainpage/forgotpassword/changepassword";
import Forgotpassword from "../components/mainpage/forgotpassword/forgotpassword";
import ForgotpasswordNext from "../components/mainpage/forgotpassword/forgotpasswordmobile";
import CheckoutContainer from "../containers/checkout/checkout-container";
import CheckoutPaytm from "./checkout/checkout-paytm";
import ErrorPage from "./paymentstatus/Error";
import ThankYouPage from "./paymentstatus/ThankYou";
import Finxprivacypolicy from "../containers/finxfooter/finxprivacypolicy";
import Finxdisclaimer from "../containers/finxfooter/finxdisclaimer";
import Finxcookiespolicy from "../containers/finxfooter/finxcookiespolicy";
import Privacydetails from "../containers/finxfooter/privacydetails";
import Disclaimer from "../containers/finxfooter/disclaimer";
import Cookies from "../containers/finxfooter/cookies";
import Roadmap from "../components/roadmap";
import MyTour from "./student-dashboard/mytour";
import MyPrequisite from "./student-dashboard/myprequesite";
import Finxfaq from "../containers/finxfooter/finxfaq";
import FinxHeader from "../containers/finxheader/finxheader";
import FinxFooterLinks from "../containers/finxfooter/finxfooterlinks";
import CfxAssessmentReport from "../components/report/cfxAssessmentReport";
import MyCfxAssessmentReport from "../components/student-dashboard/mycfxAssessmentReport";
import EmailVerification from "../components/mainpage/finxregistration/emailVerification";
import RegisterSuccess from "../components/mainpage/finxregistration/registerSuccess";
import UploadPayment from "../components/upload-payment/uploadPayment";
import StudentSection from "../components/studentSection/studentSection";
import PathChangeDetectorWithRouter from "../components/popup/PathChangeDetector";
import loadable from "@loadable/component";
import LoadingFallback from "../components/loadingFallBack";
import GlobalClickComponent from "../components/GlobalClickComponent";
import {
  getIsMaintenance,
  getTimeRemaining,
  getIsNotified,
  getTimeNotiRemaining,
  getIsClicked,
  getPending,
  getError,
} from "../reducer/maintenanceReducer";
import ForcedPopup from "../components/popup/ForcedPopup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDeploymentFlagAPI } from "../services/fetchStudentDashboard.service";
import { LoginService } from "../services/login.service";
import { getRoleID } from "./getRoleId";
const MyAlerts = loadable(
  () => import("../components/student-dashboard/myalerts"),
  {
    fallback: <LoadingFallback />,
  }
);
const Notification = loadable(
  () => import("../components/mainpage/notification"),
  {
    fallback: <LoadingFallback />,
  }
);

//! User Side Components Starts
const Studentdashboardlanding = loadable(
  () => import("../containers/finxdashboardlanding/studentdashboardlanding"),
  {
    fallback: <LoadingFallback />,
  }
);
const Videos = loadable(() => import("./student-dashboard/Videos"), {
  fallback: <LoadingFallback />,
});
const Courses = loadable(() => import("./student-dashboard/Courses"), {
  fallback: <LoadingFallback />,
});
const Jobs = loadable(() => import("./student-dashboard/Jobs"), {
  fallback: <LoadingFallback />,
});
const MyWishlist = loadable(() => import("./student-dashboard/MyWishlist"), {
  fallback: <LoadingFallback />,
});
const MyCart = loadable(() => import("./student-dashboard/MyCart"), {
  fallback: <LoadingFallback />,
});
const Finxdashboardlanding = loadable(
  () => import("../containers/finxdashboardlanding/finxdashboardlanding"),
  {
    fallback: <LoadingFallback />,
  }
);
const Dashboardheader = loadable(
  () => import("../components/dashboardheader/dashboardheader"),
  {
    fallback: <LoadingFallback />,
  }
);
//! User Side Components Ends

//! Corporate Landing Components Starts
const MorningStar = loadable(
  () => import("../containers/morningStar/morningStar"),
  { fallback: <LoadingFallback /> }
);
const Kotak = loadable(() => import("../containers/kotak/kotak"), {
  fallback: <LoadingFallback />,
});
const CorporateLandingPage = loadable(
  () => import("../containers/CorporateLandingPage/CorporateLandingPage"),
  { fallback: <LoadingFallback /> }
);
const CorporateEdLandingPage = loadable(
  () => import("../containers/CorporateEdLandingPage/CorporateLandingPage"),
  { fallback: <LoadingFallback /> }
);
//! Corporate Landing Components Ends

//! Admin Master & User Side Common Components Starts
const OrderReportLanding = loadable(
  () => import("../containers/student-report/orderReportLanding"),
  { fallback: <LoadingFallback /> }
);
//! Admin Master & User Side Common Components Ends

//! Admin Master Components Starts
const Admindashboardlanding = loadable(
  () => import("../containers/finxdashboardlanding/admindashboardlanding"),
  { fallback: <LoadingFallback /> }
);
const AdminHeader = loadable(
  () => import("../components/dashboardheader/adminheader"),
  { fallback: <LoadingFallback /> }
);
const AdminProfile = loadable(() => import("../containers/admin/admin-user"), {
  fallback: <LoadingFallback />,
});
const AdminProfileEdit = loadable(
  () => import("./admin-dashboard/adminEditProfile"),
  { fallback: <LoadingFallback /> }
);
const LearningPath = loadable(
  () => import("../containers/learningPath/learningPathContainers"),
  { fallback: <LoadingFallback /> }
);
const GdpiScore = loadable(() => import("../containers/gdpi/gdpi-score"), {
  fallback: <LoadingFallback />,
});
const AdminBulkUploadgdpi = loadable(
  () => import("../components/admin/admin-bulk-upload-gdpi"),
  { fallback: <LoadingFallback /> }
);
const AdminAddUserCourse = loadable(
  () => import("../components/admin/adminAddUserCourse"),
  {
    fallback: <LoadingFallback />,
  }
);
const AdminBulkUploadUser = loadable(
  () => import("../components/admin/admin-bulk-upload"),
  {
    fallback: <LoadingFallback />,
  }
);
const Roles = loadable(() => import("../containers/roles/roles"), {
  fallback: <LoadingFallback />,
});
const EnrollmentLanding = loadable(
  () => import("../containers/report/enrollmentLanding"),
  {
    fallback: <LoadingFallback />,
  }
);
const UserActivityLanding = loadable(
  () => import("../containers/report/useractivityLanding"),
  {
    fallback: <LoadingFallback />,
  }
);
const SentEmailList = loadable(
  () => import("../containers/sentemail/sentemail"),
  {
    fallback: <LoadingFallback />,
  }
);
const AssessmentReportLanding = loadable(
  () => import("../containers/report/assessmentLanding"),
  {
    fallback: <LoadingFallback />,
  }
);
const UnassignedUsersReportLanding = loadable(
  () => import("../containers/report/unassignedUsersLanding"),
  {
    fallback: <LoadingFallback />,
  }
);
const Newadmin = loadable(() => import("../components/admin-dashboard/admin"), {
  fallback: <LoadingFallback />,
});
const NewAdminProfile = loadable(
  () => import("../components/admin-dashboard/newAdminProfile"),
  {
    fallback: <LoadingFallback />,
  }
);
const ModulesList = loadable(
  () => import("../containers/modules/modulesListContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const UserActivity = loadable(
  () => import("../components/report/usermanagement"),
  {
    fallback: <LoadingFallback />,
  }
);
const EducationalLoanList = loadable(
  () => import("../components/cfx-educationalLoan/cfx-educationLoan"),
  {
    fallback: <LoadingFallback />,
  }
);
const SentEmail = loadable(
  () => import("../components/sentemail/senteEmailList"),
  {
    fallback: <LoadingFallback />,
  }
);
//! Admin Master Components Ends

//! OPS Master Components Starts
const Opsdashboardlanding = loadable(
  () => import("../containers/finxdashboardlanding/opsdashboardlanding"),
  { fallback: <LoadingFallback /> }
);
const Opsheader = loadable(
  () => import("../components/dashboardheader/opsheader"),
  { fallback: <LoadingFallback /> }
);
const JobDetails = loadable(() => import("../components/job/jobDetails"), {
  fallback: <LoadingFallback />,
});
const BulkGCube = loadable(() => import("../components/bulkGCube/bulkGCube"), {
  fallback: <LoadingFallback />,
});
//! OPS Master Components Ends
//! Admin & OPS Master Common Components Starts
const AdminAlert = loadable(
  () => import("../components/admin-dashboard/adminalerts"),
  { fallback: <LoadingFallback /> }
);
const AdminNotification = loadable(
  () => import("../components/admin-dashboard/adminnotification"),
  { fallback: <LoadingFallback /> }
);
const AdminEdit = loadable(
  () => import("../containers/admin/admin-user-edit"),
  { fallback: <LoadingFallback /> }
);
const UnSubscribePage = loadable(
  () => import("../components/UnSubscribePage/UnSubscribePage"),
  {
    fallback: <LoadingFallback />,
  }
);
const BulkUnsubscribeUpload = loadable(
  () => import("../components/bulkUnsubscribeUpload/bulkUnsubscribeUpload"),
  {
    fallback: <LoadingFallback />,
  }
);
const ProductList = loadable(
  () => import("../containers/product-list/productListContainers"),
  { fallback: <LoadingFallback /> }
);
const ProductAddList = loadable(
  () => import("../containers/product-list/productAddContainers"),
  { fallback: <LoadingFallback /> }
);
const ProductEdit = loadable(
  () => import("../containers/product-list/productEditContainers"),
  { fallback: <LoadingFallback /> }
);
const AddProductUser = loadable(
  () => import("../components/product/addProductUsers"),
  { fallback: <LoadingFallback /> }
);
const CorporateListLandingPage = loadable(
  () => import("../containers/corporate-list/corporateListContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const AddCorporatePage = loadable(
  () => import("../containers/corporate-list/corporateAddContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const EditCorporatePage = loadable(
  () => import("../containers/corporate-list/corporateEditContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const VideoList = loadable(
  () => import("../containers/video-list/videoListContainers"),
  { fallback: <LoadingFallback /> }
);
const VideoAdd = loadable(
  () => import("../containers/video-list/videoAddContainers"),
  { fallback: <LoadingFallback /> }
);
const VideoEdit = loadable(
  () => import("../containers/video-list/videoEditContainers"),
  { fallback: <LoadingFallback /> }
);
const DiscountList = loadable(
  () => import("../containers/discount-list/discountListContainers"),
  { fallback: <LoadingFallback /> }
);
const DiscountAdd = loadable(
  () => import("../containers/discount-list/discountAddContainers"),
  { fallback: <LoadingFallback /> }
);
const DiscountEdit = loadable(
  () => import("../containers/discount-list/discountEditContainers"),
  { fallback: <LoadingFallback /> }
);
const DiscountClone = loadable(
  () => import("../containers/discount-list/discountCloneContainers"),
  { fallback: <LoadingFallback /> }
);
const EventList = loadable(
  () => import("../containers/event-list/eventListContainers"),
  { fallback: <LoadingFallback /> }
);
const EventAdd = loadable(
  () => import("../containers/event-list/eventAddContainers"),
  { fallback: <LoadingFallback /> }
);
const EventEdit = loadable(
  () => import("../containers/event-list/eventEditContainers"),
  { fallback: <LoadingFallback /> }
);
const ProductValidityList = loadable(
  () =>
    import("../containers/product-validity-list/productValidityListContainers"),
  { fallback: <LoadingFallback /> }
);
const ProductValidityAdd = loadable(
  () =>
    import("../containers/product-validity-list/productValidityAddContainers"),
  { fallback: <LoadingFallback /> }
);
// const ProductValidityEdit = loadable(
//   () =>
//     import("../containers/product-validity-list/productValidityEditContainers"),
//   { fallback: <LoadingFallback /> }
// );
const AdminList = loadable(() => import("../containers/admin/admin-list"), {
  fallback: <LoadingFallback />,
});
const JobListMaster = loadable(
  () => import("../components/jobMasterModule/jobListMaster"),
  {
    fallback: <LoadingFallback />,
  }
);
const AddJobMaster = loadable(
  () => import("../components/jobMasterModule/addJobMaster"),
  {
    fallback: <LoadingFallback />,
  }
);
const EditJobMaster = loadable(
  () => import("../components/jobMasterModule/editJobMaster"),
  {
    fallback: <LoadingFallback />,
  }
);
// const BulkJobUpload = loadable(
//   () => import("./jobMasterModule/bulkJobUpload"),
//   {
//     fallback: <LoadingFallback />,
//   }
// );
const CandidateDetails = loadable(
  () => import("../components/candidate/candidateDetails"),
  {
    fallback: <LoadingFallback />,
  }
);
const DownloadDocuments = loadable(
  () => import("../components/download-documents/downloadDocuments"),
  {
    fallback: <LoadingFallback />,
  }
);
const DataDumpReport = loadable(
  () => import("../components/report/dataDumpReport"),
  {
    fallback: <LoadingFallback />,
  }
);
//! Admin & OPS Master Common Components Ends
//! Access Denied Components Starts
const AccessDeniedPage = loadable(
  () => import("../components/AccessDeniedPage"),
  { fallback: <LoadingFallback /> }
);
//! Access Denied Components Ends
//! NoMatch Page Components Starts
const NoMatchPage = loadable(() => import("../components/NoMatchPage"), {
  fallback: <LoadingFallback />,
});
//! NoMatch Page Components Ends
const crypto = require("crypto");

const mapStateToProps = (state) => ({
  isMaintenance: getIsMaintenance(state.maintenanceReducer),
  timeRemaining: getTimeRemaining(state.maintenanceReducer),
  isClicked: getIsClicked(state.maintenanceReducer),
  isNotified: getIsNotified(state.maintenanceReducer),
  timeNotiRemaining: getTimeNotiRemaining(state.maintenanceReducer),
  pending: getPending(state.maintenanceReducer),
  error: getError(state.maintenanceReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDeploymentFlagAPI1: fetchDeploymentFlagAPI,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const routeFunction = connectFunction(
  class Routes extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isMaintenance: this.props.isMaintenance,
        timeRemaining: this.props.timeRemaining,
        timeNotiRemaining: this.props.timeNotiRemaining,
        isNotified: this.props.isNotified,
        isClicked: this.props.isClicked,
        isRouterendered: false,
        currentPath: props.location.pathname,
        roleId: getRoleID(),
      };
    }
    protectedRoute = (userType) => {
      const jtiToken = localStorage.getItem("jti-token");
      const jwtToken = localStorage.getItem("jwt-token");
      if (
        ["", null, undefined, "null", "undefined"].includes(jtiToken) ||
        ["", null, undefined, "null", "undefined"].includes(jwtToken)
      ) {
        return false;
      } else {
        const tokenData =
          jwtToken && jwt_decode(localStorage.getItem("jwt-token"));
        // Get Login RoleId from jwt-token
        const loginRoleId = tokenData?.roleId; // 2-Admin, 6-Ops, 5-Student

        // Store the comparisons loginRoleId with user static id's
        if (loginRoleId) {
          const ADMIN = [2, "2"].includes(loginRoleId);
          const OPS = [6, "6"].includes(loginRoleId);
          const ADMINOPS = [2, "2", 6, "6"].includes(loginRoleId);
          const STUDENT = [5, "5"].includes(loginRoleId);

          const rules = { ADMIN, OPS, ADMINOPS, STUDENT };
          return rules[userType] || false;
        } else {
          return false;
        }
      }
    };

    fetchDeploymentFlag = () => {
      const { fetchDeploymentFlagAPI1 } = this.props;
      LoginService.TokenUser((res) => {
        localStorage.setItem("jwt-token", res.responseObject);
        localStorage.setItem("jti-token", res.responseObject);
        fetchDeploymentFlagAPI1((res) => {
          let isRouterendered1 = this.state.isRouterendered;
          this.setState({
            isRouterendered: !isRouterendered1,
          });
          setTimeout(() => {
            this.setState({
              isRouterendered: true,
            });
          }, 1200);
        });
      });
    };
    handleToken = () => {
      const jtiToken = localStorage.getItem("jti-token");
      const jwtToken = localStorage.getItem("jwt-token");

      if (
        ["", null, undefined, "null", "undefined"].includes(jtiToken) ||
        ["", null, undefined, "null", "undefined"].includes(jwtToken)
      ) {
        const currentPath = window.location.pathname;
        // const params = new URLSearchParams(window.location.search);
        // const sessionTimeout = params.get("sessiontimeout");

        if (currentPath === "/") {
          LoginService.TokenUser((res) => {
            localStorage.setItem("jwt-token", res.responseObject);
            localStorage.setItem("jti-token", res.responseObject);
          });
        }
      }
    };
    componentDidMount() {
      this.handleToken();
      this.fetchDeploymentFlag();
      const url = window?.location?.href;
      const indexOfQuestionMark = url?.indexOf("?");
      let encryptedValue,
        // isEncrypted, isEncrypted1,
        s11,
        s2;
      let s3 = {};
      let obj = {};

      if (indexOfQuestionMark !== -1) {
        encryptedValue = url?.substring(indexOfQuestionMark + 1);
        // isEncrypted = encryptedValue && encryptedValue.endsWith('=');
        // isEncrypted1 = encryptedValue && encryptedValue.length % 16 === 0;
        // if(isEncrypted || isEncrypted1){
        //   s11 = this.decryptPassword(encryptedValue)
        //   s2 = s11 && s11.split("&")
        //   localStorage.setItem("leadSourceUrl",`/?${encryptedValue}`);
        // }
        if (encryptedValue) {
          // let paramsCheck = encryptedValue ? encryptedValue.slice(0, 3) : "";
          const isDataEncrypted = this.isEncrypted(encryptedValue);
          // Parse the query string into an object
          const urlParams = new URLSearchParams(encryptedValue);
          // List of fields to check
          const fields = [
            "cli",
            "col",
            "pro",
            "loc",
            "ref",
            "source",
            "medium",
            "term",
            "camp",
            "protype",
            "proId",
          ];
          // Check if any field exists in the query string
          const anyFieldFound = fields.some((field) => urlParams.has(field));
          //! Old Working for normal url with params & encrypted url
          // if (paramsCheck === "cli") {
          if (anyFieldFound) {
            s2 = encryptedValue.includes("&")
              ? encryptedValue.split("&")
              : [encryptedValue];
            localStorage.setItem("leadSourceUrl", `/?${encryptedValue}`);
            localStorage.setItem("decryptedSourceUrl", "");
            localStorage.setItem("encryptedUrl", "false");
          } else if (isDataEncrypted) {
            const currentPath = window.location.pathname;
            if (currentPath === "/unsubscribe-page") {
              s11 = this.decryptData1(encryptedValue);
            } else {
              s11 = this.decryptData(encryptedValue);
            }
            s2 = s11 && s11.split("&");
            localStorage.setItem("leadSourceUrl", `/?${encryptedValue}`);
            localStorage.setItem("decryptedSourceUrl", `/?${s11}`);
            localStorage.setItem("encryptedUrl", "true");
          }
          //! New Optmised Code for normal url with params & encrypted url
          // if (paramsCheck === "cli" || isDataEncrypted) {
          //   const decryptedValue = isDataEncrypted ? this.decryptData(encryptedValue) : encryptedValue;
          //   s2 = decryptedValue.split("&");

          //   localStorage.setItem("leadSourceUrl", `/?${encryptedValue}`);
          //   localStorage.setItem("encryptedUrl", isDataEncrypted ? "true" : "false");
          //   localStorage.setItem("decryptedSourceUrl", decryptedValue ? `/?${decryptedValue}` : null);
          // }
        }
        //! Old Alternate to set obj using array index
        for (let i = 0; i < s2?.length; i++) {
          s3 = s2[i].split("=");
          obj[s3[0]] = s3[1] ? s3[1] : null;
        }
        //! New Alternate to set obj using key value pair
        // for (let i = 0; i < s2?.length; i++) {
        //   const [key, value] = s2[i].split("=");
        //   obj[key] = value || null;
        // }
        //! New Alternate to set obj using forEach
        // s2 && s2.forEach(entry => {
        //   const [key, value] = entry.split("=");
        //   obj[key] = value || null;
        // });
        localStorage.setItem("leadObj", JSON.stringify(obj));
        if (
          localStorage.getItem("userid-Token") === null &&
          (localStorage.getItem("login") === null ||
            localStorage.getItem("login1") === null)
        ) {
          // window.location.href = '/';
        } else {
          if ([5, "5"].includes(this.state.roleId)) {
            const currentPath1 = window.location.pathname;
            if (currentPath1 !== "/unsubscribe-page") {
              window.location.href = "/studentDashboard";
            }
          }
        }
      }
    }
    isEncrypted = (data) => {
      // Check if the data is base64 encoded
      return /^[a-zA-Z0-9+/]+[=]{0,2}$/.test(data);
    };
    decryptData = (encryptedString) => {
      const decryptionKey = "YourSecretKey";
      const decipher = crypto.createDecipher("aes-256-cbc", decryptionKey);
      let decryptedString = decipher.update(encryptedString, "hex", "utf-8");
      decryptedString += decipher.final("utf-8");
      return decryptedString;
    };

    decryptData1 = (encryptedString) => {
      const SECRET_KEY = "ThisIsASecretKey1234567890123456";
      const IV = "ThisIsAnIV123456";
      const decipher = crypto.createDecipheriv(
        "aes-256-cbc",
        Buffer.from(SECRET_KEY),
        Buffer.from(IV, "utf8")
      );
      let decryptedString = decipher.update(encryptedString, "base64", "utf8");
      decryptedString += decipher.final("utf8");
      return decryptedString;
    };

    decryptPassword = (encryptedPassword) => {
      const decrypted = CryptoJS.AES.decrypt(
        encryptedPassword,
        "secretKey"
      ).toString(CryptoJS.enc.Utf8);
      return decrypted;
    };
    render() {
      const { isMaintenance, timeRemaining } = this.props;
      const { isRouterendered } = this.state;
      if ([0, "0"].includes(isMaintenance)) {
        window.$("#maintainance-modal").modal("hide");
        $(".modal-backdrop").hide();
      }
      return (
        <>
          {isRouterendered &&
            [1, "1"].includes(isMaintenance) &&
            !["", null, undefined].includes(timeRemaining) && (
              <ForcedPopup
                isRouterendered={this.state.isRouterendered}
                isMaintenance={isMaintenance}
                timeRemaining={timeRemaining}
                fetchDeploymentFlag={this.fetchDeploymentFlag}
              />
            )}
          <Router>
            <div>
              <PathChangeDetectorWithRouter
                fetchDeploymentFlag={this.fetchDeploymentFlag}
              />
              <GlobalClickComponent />
              <Link to="/"></Link>
              <Link to="/login"></Link>
              <Link to="/register"></Link>
              <Link to="/studentDashboard"></Link>
              <Link to="/userprofile"></Link>
              <Link to="/changepassword"></Link>
              <Link to="/forgotpassword"></Link>
              <Link to="/forgotpasswordnext"></Link>
              <Link to="/checkout"></Link>
              <Link to="/checkout-paytm"></Link>
              <Link to="/admin-list"></Link>
              <Link to="/admin-dashboard"></Link>
              <Link to="/ops-dashboard"></Link>
              <Link to="/roles"></Link>
              <Link to="/gdpi"></Link>
              <Link to="/error"></Link>
              <Link to="/thankyou"></Link>
              <Link to="/finx-privacy-policy"></Link>
              <Link to="/finxdisclaimer"></Link>
              <Link to="/finx-cookies-policy"></Link>
              <Link to="/privacy-details"></Link>
              <Link to="/disclaimer"></Link>
              <Link to="/cookies"></Link>
              <Link to="/new-admin"></Link>
              <Link to="/admin-profile"></Link>
              <Link to="/AdminProfile"></Link>
              <Link to="/admin-edit-user-profile"></Link>
              <Link to="/course-list"></Link>
              <Link to="/add-course"></Link>
              <Link to="/roadmap"></Link>
              <Link to="/dashboardheader"></Link>
              <Link to="/edit-course"></Link>
              <Link to="/my-tour"></Link>
              <Link to="/myalerts"></Link>
              <Link to="/modules-list"></Link>
              <Link to="/learning-path"></Link>
              <Link to="/adminalert"></Link>
              <Link to="/adminnotification"></Link>
              <Link to="/report-enrollment"></Link>
              <Link to="/my-Subcription-list"></Link>
              <Link to="/faq"></Link>
              <Link to="/notification"></Link>
              <Link to="/adminheader"></Link>
              <Link to="/opsheader"></Link>
              <Link to="/finxheader"></Link>
              <Link to="/send-mail"></Link>
              <Link to="/send-mail-list"></Link>
              <Link to="/add-student-course"></Link>
              <Link to="/add-course-student"></Link>
              <Link to="/user-activity-report"></Link>
              <Link to="/user-activity"></Link>
              <Link to="/assessment-report"></Link>
              <Link to="/finixfooter-links"></Link>
              <Link to="/educationalLoanList"></Link>
              <Link to="/EmailVerification"></Link>
              <Link to="/RegisterSuccess"></Link>
              <Link to="/uploadPayment"></Link>
              <Link to="/studentSection"></Link>
              <Link to="/jobDetails"></Link>
              <Link to="/morningstarindia-jobs-research"></Link>
              <Link to="/kotakbank-career-jobs"></Link>
              <Link to="/BFSI-Hiring"></Link>
              <Link to="/unsubscribe-page"></Link>
              <Link to="/bulk-unsubscribe-upload"></Link>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => {
                    sessionStorage.clear();
                    const searchParams = props.location.search;
                    const params = new URLSearchParams(searchParams);
                    if (params.get("sessiontimeout") === "true") {
                      setTimeout(() => {
                        window.location.replace("/");
                      }, 3500);
                      return <LandingPage {...props} />;
                    }
                    return <LandingPage {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/login"
                  render={(props) => {
                    return <LandingPage {...props} throughLogin={true} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/register"
                  render={(props) => {
                    return <LandingPage {...props} throughRegister={true} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/CfxAssessmentReport/:id/:prodid"
                  render={(props) => {
                    return <CfxAssessmentReport {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/MyCfxAssessmentReport/:id/:prodid"
                  render={(props) => {
                    return <MyCfxAssessmentReport {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/changepassword"
                  render={(props) => {
                    return <Changepassword {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/forgotpassword"
                  render={(props) => {
                    return <Forgotpassword {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/emailVerification/:eid"
                  render={(props) => {
                    return <EmailVerification {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/registerSuccess"
                  render={(props) => {
                    return <RegisterSuccess {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/forgotpasswordnext"
                  render={(props) => {
                    return <ForgotpasswordNext {...props} />;
                  }}
                ></Route>
                <Route
                  path="/checkout"
                  render={(props) => {
                    return <CheckoutContainer {...props} />;
                  }}
                ></Route>
                <Route
                  path="/checkout-paytm"
                  render={(props) => {
                    return <CheckoutPaytm {...props} />;
                  }}
                ></Route>
                <Route
                  path="/error"
                  render={(props) => {
                    return <ErrorPage {...props} />;
                  }}
                ></Route>
                <Route
                  path="/thankyou"
                  render={(props) => {
                    return <ThankYouPage {...props} />;
                  }}
                ></Route>
                <Route
                  path="/finx-privacy-policy"
                  render={(props) => {
                    return <Finxprivacypolicy {...props} />;
                  }}
                ></Route>
                <Route
                  path="/finx-disclaimer"
                  render={(props) => {
                    return <Finxdisclaimer {...props} />;
                  }}
                ></Route>
                <Route
                  path="/finx-cookies-policy"
                  render={(props) => {
                    return <Finxcookiespolicy {...props} />;
                  }}
                ></Route>
                <Route
                  path="/privacy-details"
                  render={(props) => {
                    return <Privacydetails {...props} />;
                  }}
                ></Route>
                <Route
                  path="/disclaimer"
                  render={(props) => {
                    return <Disclaimer {...props} />;
                  }}
                ></Route>
                <Route
                  path="/cookies"
                  render={(props) => {
                    return <Cookies {...props} />;
                  }}
                ></Route>
                <Route
                  path="/roadmap"
                  render={(props) => {
                    return <Roadmap {...props} />;
                  }}
                ></Route>
                <Route
                  path="/myalerts"
                  render={(props) => {
                    return <MyAlerts {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/my-tour"
                  render={(props) => {
                    return <MyTour {...props} />;
                  }}
                ></Route>
                <Route
                  path="/my-Subcription-list"
                  render={(props) => {
                    return <MyPrequisite {...props} />;
                  }}
                ></Route>
                <Route
                  path="/notification"
                  render={(props) => {
                    return <Notification {...props} />;
                  }}
                ></Route>
                <Route
                  path="/faq"
                  render={(props) => {
                    return <Finxfaq {...props} />;
                  }}
                ></Route>
                <Route
                  path="/finxheader"
                  render={(props) => {
                    return <FinxHeader {...props} />;
                  }}
                ></Route>
                <Route
                  path="/finixfooter-links"
                  render={(props) => {
                    return <FinxFooterLinks {...props} />;
                  }}
                ></Route>
                <Route
                  path="/uploadPayment"
                  render={(props) => {
                    return <UploadPayment {...props} />;
                  }}
                ></Route>
                <Route
                  path="/studentSection"
                  render={(props) => {
                    return <StudentSection {...props} />;
                  }}
                ></Route>
                {/* User Side routes starts */}
                <Route
                  exact
                  path="/studentDashboard"
                  render={(props) => {
                    const searchParams = props.location.search; // Get query string
                    const params = new URLSearchParams(searchParams);

                    return this.protectedRoute("STUDENT") ? (
                      <Studentdashboardlanding {...props} />
                    ) : (
                      <Redirect
                        to={`/access-denied${
                          params ? `?${params.toString()}` : ""
                        }`}
                      />
                    );
                  }}
                ></Route>
                <Route
                  exact
                  path="/videos"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <Videos {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  exact
                  path="/courses"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <Courses {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  exact
                  path="/jobs"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <Jobs {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  exact
                  path="/mywishlist"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <MyWishlist {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  exact
                  path="/mycart"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <MyCart {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/userprofile"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <Finxdashboardlanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/dashboardheader"
                  render={(props) => {
                    return this.protectedRoute("STUDENT") ? (
                      <Dashboardheader {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                {/* User Side routes ends */}

                {/* Corporate Landing routes starts */}
                <Route
                  exact
                  path="/morningstarindia-jobs-research"
                  render={(props) => {
                    return <MorningStar {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/kotakbank-career-jobs"
                  render={(props) => {
                    return <Kotak {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/BFSI-Hiring"
                  render={(props) => {
                    return <CorporateLandingPage {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/higher-education-courses"
                  render={(props) => {
                    return <CorporateEdLandingPage {...props} />;
                  }}
                ></Route>
                {/* Corporate Landing routes ends */}

                {/* admin master & user side common routes starts */}
                <Route
                  path="/my-orders"
                  render={(props) => {
                    return <OrderReportLanding {...props} />;
                  }}
                ></Route>
                {/* admin master & user side common routes ends */}

                {/* admin master routes starts */}
                <Route
                  path="/admin-dashboard"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <Admindashboardlanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/adminheader"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AdminHeader {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/admin-user-profile"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AdminProfile {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/admin-profile-edit"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AdminProfileEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/learning-path"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <LearningPath {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/gdpi"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <GdpiScore {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/adminbulkuploadgdpi"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AdminBulkUploadgdpi {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-student-course"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AdminAddUserCourse {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/userbulkupload"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AdminBulkUploadUser {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/roles"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <Roles {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/report-enrollment"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <EnrollmentLanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/user-activity"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <UserActivityLanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/send-mail"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <SentEmailList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/assessment-report"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <AssessmentReportLanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/unassigned-users-report"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <UnassignedUsersReportLanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/new-admin"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <Newadmin {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/admin-profile"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <NewAdminProfile {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/modules-list"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <ModulesList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/user-activity-report"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <UserActivity {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/educationalLoanList"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <EducationalLoanList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/send-mail-list"
                  render={(props) => {
                    return this.protectedRoute("ADMIN") ? (
                      <SentEmail {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                {/* admin master routes ends */}

                {/* ops master routes starts */}
                <Route
                  path="/ops-dashboard"
                  render={(props) => {
                    return this.protectedRoute("OPS") ? (
                      <Opsdashboardlanding {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/opsheader"
                  render={(props) => {
                    return this.protectedRoute("OPS") ? (
                      <Opsheader {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/jobDetails"
                  render={(props) => {
                    return this.protectedRoute("OPS") ? (
                      <JobDetails {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                {/* <Route
                  path="/bulkGCube"
                  render={(props) => {
                    return this.protectedRoute("OPS") ? (
                      <BulkGCube {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route> */}
                {/* ops master routes ends */}

                {/* admin & ops master common routes starts */}
                <Route
                  path="/adminalert"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AdminAlert {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/adminnotification"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AdminNotification {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/admin-edit-user-profile"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AdminEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  exact
                  path="/unsubscribe-page"
                  render={(props) => {
                    // localStorage.clear();
                    // sessionStorage.clear();
                    return <UnSubscribePage {...props} />;
                  }}
                ></Route>
                <Route
                  exact
                  path="/bulk-unsubscribe-upload"
                  render={(props) => {
                    sessionStorage.clear();
                    return this.protectedRoute("ADMINOPS") ? (
                      <BulkUnsubscribeUpload {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/course-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <ProductList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-course"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <ProductAddList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/edit-course"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <ProductEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-course-student"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AddProductUser {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/corporate-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <CorporateListLandingPage {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-corporate"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AddCorporatePage {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/edit-corporate"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <EditCorporatePage {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/video-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <VideoList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-video"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <VideoAdd {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/edit-video"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <VideoEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/discount-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <DiscountList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-discount"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <DiscountAdd {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/edit-discount"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <DiscountEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/clone-discount"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <DiscountClone {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/event-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <EventList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-event"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <EventAdd {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/edit-event"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <EventEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/product-validity-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <ProductValidityList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-product-validity"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <ProductValidityAdd {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                {/* <Route
                  path="/edit-product-validity"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <ProductValidityEdit {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route> */}
                <Route
                  path="/user-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AdminList {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/job-list"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <JobListMaster {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/add-job"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <AddJobMaster {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/edit-job"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <EditJobMaster {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                {/* <Route
                  path="/bulk-job-upload"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <BulkJobUpload {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route> */}
                <Route
                  path="/candidate-details"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <CandidateDetails {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/download-documents"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <DownloadDocuments {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                <Route
                  path="/data-dump-report"
                  render={(props) => {
                    return this.protectedRoute("ADMINOPS") ? (
                      <DataDumpReport {...props} />
                    ) : (
                      <Redirect to="/access-denied" />
                    );
                  }}
                ></Route>
                {/* admin & ops master common routes ends */}
                {/* Access Denied for route security */}
                <Route path="/access-denied" component={AccessDeniedPage} />
                {/* NoMatchPage route should be the last */}
                <Route component={NoMatchPage} />
              </Switch>
            </div>
          </Router>
        </>
      );
    }
  }
);
export default withRouter(routeFunction);
