// Library Imports
import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import $ from "jquery";
import * as Yup from "yup";
// Service Imports
import { LoginService } from "../../services/login.service";
import { ProductService } from "../../services/product.service";
import { FeedbackService } from "../../services/feedbackservice.service";
import { FetchAlertService } from "../../services/fetchalerts";
import { FetchNotificationService } from "../../services/fetchnotification";
// Other Imports
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
  info2,
  infoNotification2,
  info3,
  infoNotification3,
} from "../../components/notification/notifications";
import {
  FirstMessage,
  SecondMessage,
} from "../dashboardheader/NotificationMessage";
import { getRoleID } from "../getRoleId";
// Asset Imports
const Finxlogo = require("../../assets/images/finxlogo1.png");

class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertcount: this.props.alertcount || "",
      notificationcount: this.props.notificationcount || "",
      alertNotificationEvents: JSON.parse(
        localStorage.getItem("AdminAlertNotification")
      ),
      notificationEvents: JSON.parse(localStorage.getItem("AdminNotification")),
      emailid: "",
      partyid: "",
      rank: 0,
      feedbackMsg: "",
      latestList: [],
      latestListMsg: [],
      loading: false,
      feedbackBtnDisabled: false,
      roleId: getRoleID(),
      typeOfConcernList1: [
        {
          concernID: 1,
          concernName: "Unable to generate OTP",
        },
        {
          concernID: 2,
          concernName: "Site is very slow",
        },
        {
          concernID: 3,
          concernName: "Site is not User Friendly",
        },
        {
          concernID: 4,
          concernName: "Unable to make the payment",
        },
        {
          concernID: 5,
          concernName: "Could not find the Right Job",
        },
        {
          concernID: 6,
          concernName: "Unable to Apply for Jobs",
        },
        {
          concernID: 7,
          concernName: "Could not find the Right Course",
        },
        {
          concernID: 8,
          concernName: "Others",
        },
      ],
    };
  }

  componentDidMount() {
    if (
      this.props.personnelData &&
      this.props.personnelData.responseObject !== undefined
    ) {
      this.setState({
        email:
          this.props.personnelData.responseObject.emailID === undefined
            ? ""
            : this.props.personnelData.responseObject.emailID,
        partyid:
          this.props.personnelData.responseObject.studentPartyID === undefined
            ? ""
            : this.props.personnelData.responseObject.studentPartyID,
      });
    }

    var header1 = document.getElementById("myDIV");
    var btns1 = header1.getElementsByClassName("emoji");

    for (var i = 0; i < btns1.length; i++) {
      btns1[i].addEventListener("click", function () {
        var current = header1.getElementsByClassName("active");

        for (var j = 0; j < current.length; j++) {
          current[j].className = current[j].className.replace(" active", "");
        }

        this.className += " active";
      });
    }

    var closeButton = document.querySelector(".modal-header .close");
    closeButton.addEventListener("click", function () {
      var current = header1.getElementsByClassName("active");
      for (var j = 0; j < current.length; j++) {
        current[j].className = current[j].className.replace(" active", "");
      }
    });

    $(window).blur(function () {
      window.addEventListener("storage", (e) => {
        if (
          localStorage.getItem("userid-Token") === null &&
          (localStorage.getItem("login") === null ||
            localStorage.getItem("login1") === null)
        ) {
          if (localStorage.getItem("windowloggedout") === true) {
            window.location.href = "/";
          }
        }
        e.currentTarget.removeEventListener("storage", e);
      });
    });
    if (
      localStorage.getItem("userid-Token") !== null &&
      (localStorage.getItem("login") !== null ||
        localStorage.getItem("login1") !== null)
    ) {
      if ([5, "5"].includes(this.state.roleId)) {
        if (localStorage.getItem("checkedtab") === false) {
          localStorage.setItem("checkedtab", true);
          window.location.replace("/studentDashboard");
        }
      } else if ([6, "6"].includes(this.state.roleId)) {
        if (localStorage.getItem("checkedtab") === false) {
          localStorage.setItem("checkedtab", true);
          window.location.replace("/ops-dashboard");
        }
      } else {
        if (localStorage.getItem("checkedtab") === false) {
          localStorage.setItem("checkedtab", true);
          window.location.replace("/admin-dashboard");
        }
      }
    } else {
      if (localStorage.getItem("checkedtab") === "false") {
        localStorage.setItem("checkedtab", false);
        window.location.href = "/";
      }
    }

    $(".popup10").click(function () {
      $("#myPopup10").toggleClass("show");
      setTimeout(function () {
        $("#myPopup10").toggleClass("show");
      }, 2000);
    });

    $(".popup11").click(function () {
      $("#myPopup11").toggleClass("show");
      setTimeout(function () {
        $("#myPopup11").toggleClass("show");
      }, 2000);
    });

    $(document).ready(function () {
      $("#myBtn").click(function () {
        $("#mySidenav").toggleClass("opensidenav");
        $(".dropdown-container").hide();
      });
    });

    $("#return-to-top").click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        500
      );
    });

    var dropdown = document.getElementsByClassName("dropdown-btn");
    for (var k = 0; k < dropdown.length; k++) {
      dropdown[k].addEventListener("click", function () {
        $(".dropdown-container").hide("active");

        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "none") {
          dropdownContent.style.display = "block";
        } else {
          dropdownContent.style.display = "none";
        }
      });
    }

    const partyID = localStorage.getItem("userid-Token");

    if (partyID) {
      let PARAM = `partyID=${partyID}`;
      let PARAM1 = `partyId=${partyID}&viewAllFlag=0`;
      ProductService.fetchPartyCartCount(PARAM, (res1) => {
        $("#cartcounter").html(res1.responseObject ? res1.responseObject : 0);
      });
      ProductService.fetchPartyWishlistCount(PARAM, (res1) => {
        $("#wishlistcounter").html(
          res1.responseObject ? res1.responseObject : 0
        );
      });
      FetchAlertService.fetchPartyAlertCount(`partyId=${partyID}`, (res1) => {
        $("#alertsCounter").html(res1.totalRecords ? res1.totalRecords : 0);
      });

      FetchNotificationService.fetchNotificationCount(
        `partyId=${partyID}`,
        (res1) => {
          $("#messagesCounter").html(res1.totalRecords ? res1.totalRecords : 0);
        }
      );

      this.setState({ loading: true });
      FetchAlertService.fetchLatestThreeAlerts(PARAM1, (res) => {
        if (res.status === "success") {
          this.setState({
            latestList: res.responseListObject,
            loading: false,
          });
          localStorage.setItem(
            "AdminAlertNotification",
            JSON.stringify(res.responseListObject)
          );
        } else {
          console.error("Failed to fetch latest alert list:", res.error);
          this.setState({ loading: false });
        }
      });

      this.setState({ loading: true });
      FetchNotificationService.fetchLatestThreeNotification(PARAM1, (res) => {
        if (res.status === "success") {
          this.setState({
            latestListMsg: res.responseListObject,
            loading: false,
          });
          localStorage.setItem(
            "AdminNotification",
            JSON.stringify(res.responseListObject)
          );
        } else {
          console.error("Failed to fetch latest notification list:", res.error);
          this.setState({ loading: false });
        }
      });
    } else {
      if (window?.location?.pathname !== "/register") {
        console.log("sessiontimeout true dashboardheader");
        window.location.href = "/?sessiontimeout=true";
      }
      this.setState({ loading: false });
    }
  }
  goToVideosShop = () => {
    info2(
      "This is a great way to enhance your knowledge in less than 5 minutes.",
      infoNotification2
    );
    this.props.history.push("/videos");
  };
  goToCoursesShop = () => {
    info3(<FirstMessage />, infoNotification3);
    this.props.history.push("/courses");
  };
  goToJobs = () => {
    info3(<SecondMessage />, infoNotification3);
    this.props.history.push("/jobs");
  };
  goToMyWishlist = () => {
    this.props.history.push("/mywishlist");
  };
  goToMyCart = () => {
    this.props.history.push("/mycart");
  };

  signout = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (
      localStorage.getItem("roleName") === "Student" &&
      (localStorage.getItem("login") !== null ||
        localStorage.getItem("login1") !== null)
    ) {
      var data1 = localStorage.getItem("PersonnelData");
      var data = {
        userName: JSON.parse(data1).responseObject.emailID,
        userId:
          JSON.parse(localStorage.getItem("login"))["userId"] ||
          JSON.parse(localStorage.getItem("login1"))["userId"],
      };

      LoginService.LogoutUser(data, false, (res) => {
        if (res.status === "success") {
          localStorage.clear();
          localStorage.setItem("windowloggedout", true);
          localStorage.setItem("showFeedback", true);
          let data = {
            email: this.props.personnelData.responseObject.emailID,
            partyid: this.props.personnelData.responseObject.studentPartyID,
          };
          this.setState({ loading: false });
          this.props.history.push("/", data);
        } else {
          this.setState({ loading: false });
          warning(res.reasonText, warningNotification);
        }
      });
    } else if (
      localStorage.getItem("roleName") === "Administrator" &&
      (localStorage.getItem("login") !== null ||
        localStorage.getItem("login1") !== null)
    ) {
      let data = {
        userName:
          JSON.parse(localStorage.getItem("login"))["userName"] ||
          JSON.parse(localStorage.getItem("login1"))["userName"],
        userId:
          JSON.parse(localStorage.getItem("login"))["userId"] ||
          JSON.parse(localStorage.getItem("login1"))["userId"],
      };

      LoginService.LogoutUser(data, false, (res) => {
        if (res.status === "success") {
          localStorage.clear();
          localStorage.setItem("windowloggedout", true);
          this.setState({ loading: false });
          window.location.href = "/";
        } else {
          this.setState({ loading: false });
          warning(res.reasonText, warningNotification);
        }
      });
    } else {
      this.setState({ loading: false });
      window.location.href = "/";
    }
  };

  alerts = (key, alertdata) => {
    if (alertdata.length > 0) {
      this.props.history.push("/myalerts", {
        alertid: key,
        alerts: alertdata,
        isSelect: true,
        latestList: this.state.latestList,
      });
    }
    if (this.props.isAlert) {
      const partyID = localStorage.getItem("userid-Token");
      this.props.onPanelClick(partyID, key);
    }
  };
  alert = (alertdata) => {
    if (alertdata.length > 0) {
      this.props.history.push("/myalerts", {
        alertid: 0,
        alerts: alertdata,
        isViewAll: true,
        latestList: this.state.latestList,
      });
    } else {
      this.props.history.push("/myalerts", {
        alertid: 0,
        alerts: "",
        isViewAll: true,
        latestList: this.state.latestList,
      });
    }
    if (this.props.isView) {
      const partyID = localStorage.getItem("userid-Token");
      this.props.onViewAll(partyID);
    }
  };
  notifications = (notealertdata) => {
    if (notealertdata.length > 0) {
      this.props.history.push("/notification", {
        alertid: 0,
        notification: notealertdata,
        isViewAll: true,
        latestListMsg: this.state.latestListMsg,
      });
    } else {
      this.props.history.push("/notification", {
        alertid: 0,
        notification: "",
        isViewAll: true,
        latestListMsg: this.state.latestListMsg,
      });
    }

    if (this.props.isViewMsg) {
      const partyID = localStorage.getItem("userid-Token");
      this.props.onViewAllMsg(partyID);
    }
  };

  notification = (key, notealertdata) => {
    if (notealertdata.length > 0) {
      this.props.history.push("/notification", {
        alertid: key,
        notification: notealertdata,
        isSelect: true,
        latestListMsg: this.state.latestListMsg,
      });
    }
    if (this.props.isNotification) {
      const partyID = localStorage.getItem("userid-Token");
      this.props.onPanelClick(partyID, key);
    }
  };

  order = () => {
    this.props.history.push("/my-orders", {
      alerts: this.props.alertNotificationEvents,
      notification: this.props.notificationEvents,
    });
  };
  handleClose = () => {
    this.setState({ rank: 0, feedbackMsg: "" });
    const child = document.querySelectorAll(".emoji");
    child.forEach((element) => {
      element.classList.remove("active");
    });
  };
  render() {
    var alertdata = this.props.latestList
      ? this.props.latestList
      : this.state.latestList;
    var notealertdata = this.props.latestListMsg
      ? this.props.latestListMsg
      : this.state.latestListMsg;
    return (
      <div>
        <React.Fragment>
          {/* <!------ header section ----------> */}
          <section className="header">
            <nav className="navbar navbar navbar-fixed-top">
              <span id="myBtn" className="sidenva-icon sidebartogglexyz">
                &#9776;
              </span>

              <div className="container-fluid">
                {/* <!-- Brand/logo --> */}
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-expanded="false"
                    aria-controls="navbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/studentdashboard">
                    <img src={Finxlogo} alt="img" />
                  </a>
                </div>
                <div id="navbar" className="navbar-collapse collapse">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="row">
                      <div className="right-search"></div>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        onClick={this.goToVideosShop}
                        className="nav-link text-light"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="Videos"
                        href
                      >
                        <i className="fa fa-play" aria-hidden="true"></i> Videos
                      </a>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        href
                        onClick={this.goToCoursesShop}
                        className="nav-link text-light"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="Courses  "
                      >
                        <i
                          className="fa fa-graduation-cap"
                          aria-hidden="true"
                        ></i>{" "}
                        Courses
                      </a>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        href
                        onClick={this.goToJobs}
                        className="nav-link text-light"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="Jobs"
                      >
                        <i className="fa fa-search" aria-hidden="true"></i> Jobs
                      </a>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        href
                        onClick={this.goToMyWishlist}
                        className="nav-link text-light"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="My Wishlist"
                      >
                        <span type="button" className="badge-counter-container">
                          <i className="fa fa-heart" aria-hidden="true"></i>
                          <span className="badge-counter" id="wishlistcounter">
                            0
                          </span>
                        </span>
                        &nbsp;Wishlist
                      </a>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        href
                        onClick={this.goToMyCart}
                        className="nav-link text-light"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="My Cart"
                      >
                        <span type="button" className="badge-counter-container">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                          <span className="badge-counter" id="cartcounter">
                            0
                          </span>
                        </span>
                        &nbsp;Cart
                      </a>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        href="https://expertmfd.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link text-light"
                        title="Entrepreneurship"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <p className="financial"> Entrepreneurship</p>
                      </a>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        className="nav-link text-light notification"
                        href
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="Alerts"
                      >
                        <span type="button" className="badge-counter-container">
                          <i className="fa fa-bell-o" aria-hidden="true"></i>
                          <span className="badge-counter" id="alertsCounter">
                            {this.props.alertcount ? this.props.alertcount : 0}
                          </span>
                        </span>
                        &nbsp;Alerts
                      </a>
                      <ul className="dropdown-menu" id="notification-alert">
                        <li className="head text-light bg-dark">
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12"></div>
                          </div>
                        </li>
                        <li className="notification-box">
                          <div className="notification-content">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                {alertdata && alertdata.length > 0 ? (
                                  alertdata.slice(0, 3).map((item, key) => (
                                    <div
                                      className="notification-content"
                                      id={item.alertId}
                                    >
                                      <a
                                        href
                                        onClick={() =>
                                          this.alerts(
                                            item.alertId,
                                            item.alertStartDateTime,
                                            alertdata
                                          )
                                        }
                                      >
                                        <div
                                          className="notification-body"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <p
                                            className="text-info"
                                            style={{
                                              flex: "1",
                                              maxWidth: "70%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {item.alertName}
                                          </p>
                                          <small
                                            className="text-warning"
                                            style={{
                                              flex: "0 0 auto",
                                              minWidth: "30%",
                                              textAlign: "right",
                                            }}
                                          >
                                            {item.alertStartDateTime}
                                          </small>
                                        </div>
                                      </a>
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    className="notification-content"
                                    style={{ textAlign: "center" }}
                                  >
                                    No unread alert found.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>

                        {(alertdata || []).length > 0 ? (
                          <li className="footer bg-dark text-center">
                            <a
                              onClick={() => this.alert(alertdata)}
                              className="text-light"
                              href
                            >
                              View All
                            </a>
                          </li>
                        ) : (
                          <li className="footer bg-dark text-center">
                            <a
                              onClick={() => this.alert("")}
                              className="text-light"
                              href
                            >
                              View All
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>

                    <li className="nav-item dropdown notification">
                      <a
                        className="nav-link text-light notification"
                        href
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="Messages"
                      >
                        <span type="button" className="badge-counter-container">
                          <i
                            className="fa fa-envelope-o"
                            aria-hidden="true"
                          ></i>
                          <span className="badge-counter" id="messagesCounter">
                            {this.props.notificationcount
                              ? this.props.notificationcount
                              : 0}
                          </span>
                        </span>
                        &nbsp;Messages
                      </a>
                      <ul className="dropdown-menu" id="notification-alert">
                        <li className="head text-light bg-dark">
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12">
                              <a href className="text-light pull-right">
                                {" "}
                              </a>
                            </div>
                          </div>
                        </li>

                        <li className="notification-box">
                          <div className="row">
                            <div className="panel-body">
                              <div className="col-lg-12 col-sm-12 col-12">
                                {notealertdata && notealertdata.length > 0 ? (
                                  notealertdata.slice(0, 3).map((item, key) => (
                                    <div
                                      className="notification-content"
                                      id={item.notifyHistoryId}
                                    >
                                      <a
                                        href
                                        onClick={() =>
                                          this.notification(
                                            item.notifyHistoryId,
                                            notealertdata
                                          )
                                        }
                                      >
                                        <div
                                          className="notification-body"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <p
                                            className="text-info"
                                            style={{
                                              flex: "1",
                                              maxWidth: "70%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {item.emailSubjectText}
                                          </p>
                                          <small
                                            className="text-warning"
                                            style={{
                                              flex: "0 0 auto",
                                              minWidth: "30%",
                                              textAlign: "right",
                                            }}
                                          >
                                            {item.deliveryDate}
                                          </small>
                                        </div>
                                      </a>
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    className="notification-content"
                                    style={{ textAlign: "center" }}
                                  >
                                    No unread message found.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                        {(notealertdata || []).length > 0 ? (
                          <li className="footer bg-dark text-center">
                            <a
                              onClick={() => this.notifications(notealertdata)}
                              className="text-light"
                              href
                            >
                              View All
                            </a>
                          </li>
                        ) : (
                          <li className="footer bg-dark text-center">
                            <a
                              onClick={() => this.notifications("")}
                              className="text-light"
                              href
                            >
                              View All
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li className="nav-item dropdown notification">
                      <a
                        onClick={this.signout}
                        className="nav-link text-light notification"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title="Sign-Out"
                        href
                      >
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        Sign Out
                      </a>
                    </li>
                    {localStorage.getItem("roleName") === "Student" &&
                      (localStorage.getItem("login") !== null ||
                        localStorage.getItem("login1") !== null) && (
                        <li className="nav-item dropdown notification">
                          <a
                            href
                            className="nav-link text-light notification"
                            id="navbarDropdown"
                            role="button"
                            onClick={(e) => {
                              window.$("#feedback-modal").modal("show");
                            }}
                            title="Feedback"
                          >
                            <i
                              className="fa fa-regular fa-comments"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      )}
                  </ul>
                </div>
                {/* <!--/.nav-collapse --> */}
              </div>
            </nav>
          </section>

          {/* <!--------------- Left Side navbar -------------> */}
          <div id="mySidenav" className="sidenav sidebartogglexyz">
            <a href="/studentDashboard" className="sidebartogglexyz">
              <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
            </a>
            <a href="/userprofile" className="sidebartogglexyz">
              <i className="fa fa-user" aria-hidden="true"></i> My Profile
            </a>
            <button className="dropdown-btn sidebartogglexyz" type="button">
              <i
                className="fa fa-leanpub sidebartogglexyz"
                aria-hidden="true"
              ></i>{" "}
              Reports
              <i className="fa fa-caret-down sidebartogglexyz"></i>
            </button>
            <div className="dropdown-container sidebartogglexyz">
              <a href onClick={this.order} className="sidebartogglexyz">
                My Payment
              </a>
            </div>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={{
              emailid: this.state.email || localStorage.getItem("eMail"),
              partyid:
                this.state.partyid || localStorage.getItem("userid-Token"),
              feedbackMsg: this.state.feedbackMsg,
              rank: this.state.rank,
              typeOfConcernList: this.state.typeOfConcernList1,
              typeOfConcern: "",
            }}
            validationSchema={Yup.object().shape({
              rank: Yup.number()
                .min(1, "You must specify your reaction")
                .max(5, "rating range should be between 1-5"),
              feedbackMsg: Yup.string().matches(
                /^[a-zA-Z0-9\s,\-&./()]+$/,
                "Please enter valid Feedback Message. Allowed characters : alphnumeric,Space,comma,hyphen,ampersand,dot,forward Slash, Round Brackets"
              ),
              typeOfConcern: Yup.string().when("rank", {
                is: (rank) => (rank === 1 || rank === 2 ? true : false),
                then: Yup.string().required("You Must Select Type of Concern"),
                otherwise: Yup.string(),
              }),
            })}
            onSubmit={(values) => {
              if (values.emailid === "") {
                warning("EmailId can't be null", warningNotification);
                return;
              } else if (values.partyid === "") {
                warning("partyId can't be null", warningNotification);
                return;
              }
              let data = {
                partyid: values.partyid,
                emailid: values.emailid,
                rank: values.rank,
                feedback_msg: values.feedbackMsg,
                concernID: values.typeOfConcern,
              };
              this.setState({ loading: true, feedbackBtnDisabled: true });
              FeedbackService.PostFeedback(
                data,
                (cb) => {
                  if (cb.status === "success") {
                    this.setState({
                      loading: false,
                      feedbackBtnDisabled: false,
                      rank: 0,
                      feedbackMsg: "",
                    });
                    success(
                      "Thank you for your valuable feedback. We are a young startup and this will help us to improve.",
                      successNotification
                    );
                    window.$("#feedback-modal").modal("hide");
                    const child = document.querySelectorAll(".emoji");
                    child.forEach((element) => {
                      element.classList.remove("active");
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                  } else {
                    this.setState({
                      loading: false,
                      feedbackBtnDisabled: false,
                      rank: 0,
                      feedbackMsg: "",
                    });
                    error(cb.status, errorNotification);
                    window.$("#feedback-modal").modal("hide");
                    const child = document.querySelectorAll(".emoji");
                    child.forEach((element) => {
                      element.classList.remove("active");
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                  }
                },
                (err) => console.log("Error", err)
              );
            }}
            render={({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleReset,
              values,
              handleChange,
            }) => (
              <React.Fragment>
                <div
                  className="modal fade"
                  id="feedback-modal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.handleClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>
                          <h4
                            className="modal-title text-center"
                            style={{ fontWeight: "bold", marginBottom: 20 }}
                            id="myModalLabel"
                          >
                            How was your overall experience with FinX?
                          </h4>
                        </strong>
                      </div>
                      <div className="modal-body">
                        <Form className="form-group">
                          <div className="form-group">
                            <div className="row">
                              <div
                                id="myDIV"
                                className="col-md-12 col-sm-12 col-xs-12 text-center"
                              >
                                <span
                                  name="rank"
                                  className="emoji"
                                  role="img"
                                  aria-label="Emoji"
                                  onClick={() => {
                                    this.setState({ rank: 1 });
                                  }}
                                >
                                  &#128544;
                                </span>
                                <span
                                  name="rank"
                                  className="emoji"
                                  role="img"
                                  aria-label="Emoji"
                                  onClick={() => {
                                    this.setState({ rank: 2 });
                                  }}
                                >
                                  &#128530;
                                </span>
                                <span
                                  name="rank"
                                  className="emoji"
                                  role="img"
                                  aria-label="Emoji"
                                  onClick={() => {
                                    this.setState({ rank: 3 });
                                  }}
                                >
                                  &#128529;
                                </span>
                                <span
                                  name="rank"
                                  className="emoji"
                                  role="img"
                                  aria-label="Emoji"
                                  onClick={() => {
                                    this.setState({ rank: 4 });
                                  }}
                                >
                                  &#128512;
                                </span>
                                <span
                                  name="rank"
                                  className="emoji"
                                  role="img"
                                  aria-label="Emoji"
                                  onClick={() => {
                                    this.setState({ rank: 5 });
                                  }}
                                >
                                  &#129321;
                                </span>
                              </div>
                            </div>
                            {["1", 1, "2", 2].includes(this.state.rank) && (
                              <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                  <label>
                                    Type Of Concern
                                    <span className="validation"> * </span>
                                  </label>
                                  <Field
                                    as="select"
                                    name="typeOfConcern"
                                    id="typeOfConcern"
                                    style={{
                                      border: "1px solid #fbc583",
                                      width: "90%",
                                      margin: "0px 5px 5px 3rem",
                                    }}
                                    onChange={handleChange}
                                    className={
                                      "form-control " +
                                      (errors.typeOfConcern &&
                                      touched.typeOfConcern
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option value="">--Select--</option>
                                    {(values.typeOfConcernList || []).map(
                                      (item) => (
                                        <option
                                          key={item.concernID}
                                          value={item.concernID}
                                        >
                                          {item.concernName}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="typeOfConcern"
                                    component="div"
                                    className="validation"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <Field
                                  rows="4"
                                  component="textarea"
                                  maxLength={255}
                                  name="feedbackMsg"
                                  id="feedbackMsg"
                                  placeholder="What can we do to improve?"
                                  autoComplete="feedbackMsg"
                                  onChange={handleChange}
                                  className={"form-control"}
                                  style={{
                                    border: "1px solid #fbc583",
                                    width: "90%",
                                    margin: "0px 5px 5px 3rem",
                                  }}
                                />
                                <ErrorMessage
                                  name="feedbackMsg"
                                  className="validation"
                                  component="div"
                                  style={{ marginLeft: "42px" }}
                                />
                                <ErrorMessage
                                  name="rank"
                                  className="validation"
                                  component="div"
                                  style={{ marginLeft: "42px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                      <div className="modal-footer text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={this.state.feedbackBtnDisabled}
                        >
                          Send your Feedback
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default DashboardHeader;
