// Library Imports
import jwt_decode from "jwt-decode";

export const getRoleID = () => {
  let jwtToken = localStorage.getItem("jwt-token") || "";
  let roleId = localStorage.getItem("roleId");
  if (!["", null, undefined, "null", "undefined"].includes(jwtToken)) {
    const tokenData = jwtToken && jwt_decode(jwtToken);
    return tokenData?.roleId ? tokenData?.roleId : roleId; // RoleIds = 2-Admin, 6-Ops, 5-Student
  } else {
    return roleId;
  }
};
export const getEmailID = () => {
  let jwtToken = localStorage.getItem("jwt-token") || "";
  let emailId = localStorage.getItem("eMail");
  if (!["", null, undefined, "null", "undefined"].includes(jwtToken)) {
    const tokenData = jwtToken && jwt_decode(jwtToken);
    return tokenData?.sub; // RoleIds = 2-Admin, 6-Ops, 5-Student
  } else {
    return emailId;
  }
};
