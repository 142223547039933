// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
// import ReactTable from "react-table";
// import "react-table/react-table.css";
// import DashboardHeader from "../../components/dashboardheader/dashboardheader";
// import Finxfooter from "../../containers/finxfooter/finxfooter";
// import Axios from "axios";
import { PaymentService } from "../../services/payment.service";
// import * as moment from "moment";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personaldetails: this.props.location.state.props.personaldetails === undefined ? this.props.location.state.props[0].personaldetails : this.props.location.state.props.personaldetails,
      productdetails: this.props.location.state.props.productdetails === undefined ? this.props.location.state.props[0].productdetails : this.props.location.state.props.productdetails,
      orderdetails: this.props.location.state.orderDetails,
      load: true
    };
  }
  componentDidMount() {

    if (localStorage.getItem('reload') === 'true') {
      localStorage.setItem('reload', 'false');
      window.location.reload();
    }

    // setTimeout(() => {
    //     document.paytm_form.submit();
    // }, 2000);
  }

  handleBackClick = () => {
    window.location.replace('/studentDashboard');
  }

  handleClick = () => {
    // var productList = JSON.parse(localStorage.getItem("activeProduct"))
    // console.log('productList:::: ' + productList)
    var CheckoutObj = []
    var CheckoutObj1=[]

    // ! Testing
    // for (var i = 0; i < productList.length; i++) {
    //     let data1 = {}
    //     data1['studentPartyID'] = this.state.orderdetails.studentPartyID
    //     data1['productID'] = productList[i].productID
    //     data1['productName'] = productList[i].productName
    //     data1['paymentID'] = this.state.orderdetails.orderID
    //     data1['firstName'] = this.state.personaldetails.firstName
    //     data1['middleName'] = this.state.personaldetails.middleName
    //     data1['lastName'] = this.state.personaldetails.lastName
    //     data1['gender'] = 1
    //     data1['emailID'] = this.state.personaldetails.emailID
    //     data1['mobileNo'] = this.state.personaldetails.mobileNo
    //     data1['contactNo'] = ""
    //     data1['dob'] = this.state.personaldetails.dateOfBirth

    //     CheckoutObj.push(data1)
    // }
    // ! End Testing

    let data2 = {}
    data2['studentPartyID'] = this.state.orderdetails.studentPartyID
    data2['productID'] = this.state.productdetails.productId
    data2['productName'] = this.state.productdetails.productName
    data2['paymentID'] = this.state.orderdetails.orderID
    data2['firstName'] = this.state.personaldetails.firstName
    data2['middleName'] = this.state.personaldetails.middleName
    data2['lastName'] = this.state.personaldetails.lastName
    data2['gender'] = 1
    data2['emailID'] = this.state.personaldetails.emailID
    data2['mobileNo'] = this.state.personaldetails.mobileNo
    data2['contactNo'] = ""
    data2['dob'] = this.state.personaldetails.dateOfBirth

    CheckoutObj.push(data2)
    let data22 = {}
    data22['studentPartyID'] = this.state.orderdetails.studentPartyID
    data22['productID'] = this.state.productdetails.productId
    data22['paymentID'] = this.state.orderdetails.orderID
    data22['productType'] = (this.state.productdetails?.type ?? this.state.productdetails?.productType) === "COURSES" ? "COURSE" : "VIDEO"
    CheckoutObj1.push(data22)
    console.log("CheckoutObj:::: ", JSON.stringify(CheckoutObj));
    localStorage.setItem("PaymentDet", JSON.stringify(CheckoutObj));
    localStorage.setItem("PaymentDet1", JSON.stringify(CheckoutObj1))

    var PayObj = {
      orderID: this.state.orderdetails.orderID,
      studentPartyID: this.state.orderdetails.studentPartyID,
      totalOrderPrice: this.state.orderdetails.totalOrderPrice
    };

    PaymentService.Makepayment(PayObj, res => {
      this.props.history.push("/checkout-paytm", { paytm: res.data });
    });
  };

  // handleClick = () => {
  //     var productList = JSON.parse(localStorage.getItem("activeProduct"))
  //     console.log('productList:::: ' + productList)
  //     for (var i = 0; i < productList.length; i++) {
  //         var CheckoutObj = [{
  //             studentPartyID: this.state.orderdetails.studentPartyID,
  //             productID: this.state.productdetails.productId,
  //             productName: this.state.productdetails.productName,
  //             paymentID: this.state.orderdetails.orderID,
  //             firstName: this.state.personaldetails.firstName,
  //             middleName: this.state.personaldetails.middleName,
  //             lastName: this.state.personaldetails.lastName,
  //             gender: 1,
  //             emailID: this.state.personaldetails.emailID,
  //             mobileNo: this.state.personaldetails.mobileNo,
  //             contactNo: "",
  //             dob: this.state.personaldetails.dateOfBirth
  //         }, {
  //             studentPartyID: this.state.orderdetails.studentPartyID,
  //             productID: productList[0].productID,
  //             productName: productList[0].productName,
  //             paymentID: this.state.orderdetails.orderID,
  //             firstName: this.state.personaldetails.firstName,
  //             middleName: this.state.personaldetails.middleName,
  //             lastName: this.state.personaldetails.lastName,
  //             gender: 1,
  //             emailID: this.state.personaldetails.emailID,
  //             mobileNo: this.state.personaldetails.mobileNo,
  //             contactNo: "",
  //             dob: this.state.personaldetails.dateOfBirth
  //         }, {
  //             studentPartyID: this.state.orderdetails.studentPartyID,
  //             productID: productList[1].productID,
  //             productName: productList[1].productName,
  //             paymentID: this.state.orderdetails.orderID,
  //             firstName: this.state.personaldetails.firstName,
  //             middleName: this.state.personaldetails.middleName,
  //             lastName: this.state.personaldetails.lastName,
  //             gender: 1,
  //             emailID: this.state.personaldetails.emailID,
  //             mobileNo: this.state.personaldetails.mobileNo,
  //             contactNo: "",
  //             dob: this.state.personaldetails.dateOfBirth
  //         }];
  //     }

  //     console.log("CheckoutObj:::: ", JSON.stringify(CheckoutObj));
  //     localStorage.setItem("PaymentDet", JSON.stringify(CheckoutObj));

  //     var PayObj = {
  //         orderID: this.state.orderdetails.orderID,
  //         studentPartyID: this.state.orderdetails.studentPartyID,
  //         totalOrderPrice: this.state.orderdetails.totalOrderPrice
  //     };
  //     PaymentService.Makepayment(PayObj, res => {
  //         this.props.history.push("/checkout-paytm", { paytm: res.data });
  //     });
  // };

  // handleClick = () => {

  //     var CheckoutObj = {
  //         studentPartyID: this.state.orderdetails.studentPartyID,
  //         productID: this.state.productdetails.productId,
  //         productName: this.state.productdetails.productName,
  //         paymentID: this.state.orderdetails.orderID,
  //         firstName: this.state.personaldetails.firstName,
  //         middleName: this.state.personaldetails.middleName,
  //         lastName: this.state.personaldetails.lastName,
  //         gender: 1,
  //         emailID: this.state.personaldetails.emailID,
  //         mobileNo: this.state.personaldetails.mobileNo,
  //         contactNo: "",
  //         dob: this.state.personaldetails.dateOfBirth
  //     };
  //     //console.log(JSON.stringify(CheckoutObj));
  //     localStorage.setItem("PaymentDet", JSON.stringify(CheckoutObj));
  //     var PayObj = {
  //         orderID: this.state.orderdetails.orderID,
  //         studentPartyID: this.state.orderdetails.studentPartyID,
  //         totalOrderPrice: this.state.orderdetails.totalOrderPrice
  //     };
  //     PaymentService.Makepayment(PayObj, res => {
  //         this.props.history.push("/checkout-paytm", { paytm: res.data });
  //     });
  // };

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="checkout">
                <div className="col-md-2 col-lg-2 ml-30"></div>
                <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12 mt-50">
                  <div className="bg-box">
                    <div className="payment-detail">
                      <h2 className="sub-header">Personal Details</h2>
                      <div className="table-responsive">
                        <table className="paydetail-table">
                          <tbody>
                            <tr>
                              <td>Order Number</td>
                              <td>:</td>
                              <td>
                                <b>{this.state.orderdetails.orderID}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Mobile No</td>
                              <td>:</td>
                              <td>
                                <b>{this.state.personaldetails.mobileNo}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>:</td>
                              <td>
                                <b>{this.state.personaldetails.emailID}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>First Name</td>
                              <td>:</td>
                              <td>
                                <b>{this.state.personaldetails.firstName}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Last Name</td>
                              <td>:</td>
                              <td>
                                <b>{this.state.personaldetails.lastName}</b>
                              </td>
                            </tr>
                            {/* <tr>
                                                    <td>Address</td>
                                                    <td>:</td>
                                                    <td><b>C-3 Lorem Ipsum Dollar Amet Maharshtra India 411101</b></td>
                                                </tr> */}
                          </tbody>
                        </table>
                        <h2 className="sub-header mt-30">Coupon Code</h2>
                        <div className="coupon-code">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Coupon Code?"
                          />
                          <div className="apply-coupon">
                            <button type="button" className="btn-5">
                              Apply
                            </button>
                          </div>
                        </div>
                        <h2 className="sub-header mt-30">Order Summary</h2>
                        <table className="paydetail-table">
                          <thead>
                            <tr>
                              <th>
                                <b>Product</b>
                              </th>
                              <th>
                                <b>Amount</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.productdetails.productName}</td>
                              <td>
                                <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                {this.state.productdetails.productPrice}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div classname="row">
                        <div col-md-4 col-lg-4 ml-30>

                          <button
                            type="button"
                            className="btn-4 mt-45 mb-8 center-block inlinepayment"
                            onClick={this.handleClick} >
                            Make Payment </button>

                          <button
                            type="button"
                            className="btn-4 mt-40 mb-8 center-block inlinepayment"
                            onClick={this.handleBackClick} >
                            Back </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Checkout;
